
    import { Component, Vue, Prop, Watch } from "vue-property-decorator";
    import formatters from '@/utils/formatters';
    import Common from '@/utils/common';
    import * as TransactionsService from '@/services/DAL/transactionsService';
    import * as DebtService from '@/services/DAL/debtService';
    import NewConsideration from '@/views/InvestmentTransaction/Consideration/NewConsideration.vue';
    import ConsiderationEdit from '@/views/InvestmentTransaction/Consideration/ConsiderationEdit.vue';


    declare function getStoredSecurityLevel(Id: number): number;
    declare function tryParseInt(input: any, defaultValue: number): number;
    type ConsiderationsType = DebtService.InstrumentTransactions | TransactionsService.Equity | TransactionsService.Cash | TransactionsService.Capital | TransactionsService.CreditNote | TransactionsService.Expense;

    @Component({
        components: {
            NewConsideration,
            ConsiderationEdit
        }
    })
    export default class ConsiderationList extends Vue {
    //#region Private declarations for Services
    private _transactionsService: TransactionsService.TransactionsService;
    private _debtService: DebtService.DebtService;
    public formatters: formatters;
    public common: Common;
    //#endregion Private declarations for Services

    //#region Props
    @Prop( {required: true, type: Object }) readonly header: TransactionsService.Header;
    @Prop( {type: Object }) readonly transactionType: TransactionsService.Types;
    @Prop( {required: true, type: String }) readonly direction: string;
    @Prop( {required: false, type: String }) readonly singleConsiderationType: string; //used to force the consideration type to be a single type, such as 'BankAccount'(cash) or 'Capital'
    @Prop( {type: Array }) readonly ownerIdList: Array<number>;
    @Prop( {type: Number }) readonly newTransactionInvestmentId: number;
    //#endregion Props

    //#region Data
    loading = false;
    securityLevel_: number = null;
    selectedConsideration = {} as ConsiderationsType;
    showConsiderationEdit = false;
    showNewConsideration = true;

    //effectively, the store:
    activeStep: number = 0;
    // consideration = [] as TransactionsService.Consideration[];
    consideration: Array<ConsiderationsType > = [];

    //#endregion Data

    //#region Lifecycle
    created() {
        this.formatters = new formatters();
        this.common = new Common();
        this._transactionsService = new TransactionsService.TransactionsService();
        this._debtService = new DebtService.DebtService();
        this.securityLevel_ = tryParseInt(
            getStoredSecurityLevel(this.$namedKey.SecurityView.ManageAssets),
            0
        );
        this.activeStep = 1;
        this.getExistingConsiderations();
    }
    //#endregion Lifecycle

    //#region Watches
    @Watch('header')
    async headerChanged(val: TransactionsService.Header, oldVal: TransactionsService.Header) {
        if (oldVal){
            this.getExistingConsiderations();
        }
    }
    //#endregion Watches

    //#region Computed
    get totalConsideration(): number {
        if (this.consideration && this.consideration.length){
            return this.consideration.reduce(function(a, b) {
                return a + b.ConsiderationValue;
            }, 0)
        }
        else return 0;
    }
    get considerationBalanced(): Object {
        const balanced: boolean = (Math.abs(this.totalConsideration) == this.header.NetAmount);
        return {
            status: balanced
            , message: balanced ? 'Consideration is in balance' : 'Considerations must equal Net Amount'
        }

    }
    //#endregion Computed


    //#region Methods
    getSummary(param) {
        let adjuster: number = 0;
        const columnList = [];
        const totalLabel = 'Total';
        const totalLabelIndex = 1;
        const formats = new Map();
        if (this.common.hideIfColumnEmpty(this.consideration, 'Quantity')){
            columnList.push(2);
            formats.set(2, 'number3');
        }
        else {adjuster--;}

        columnList.push(3+adjuster);
        formats.set(3+adjuster, 'currency');
        
        if (this.common.hideIfColumnEmpty(this.consideration, 'AcquisitionCost')){
            columnList.push(4+adjuster);
            formats.set(4+adjuster, 'currency');
        }
        else {adjuster--;}
        
        const result = this.common.getSummaryArray(param, columnList, totalLabel, totalLabelIndex, formats);
        return result;
    }

    considerationSavedOnly(consideration: ConsiderationsType ) {
        const existing: ConsiderationsType = this.common.getSelectedArrayItem(
            this.consideration,
            consideration.Id.toString(),
            'Id'
        );
        if (existing && Object.keys(existing).length){
            const index = this.consideration.indexOf(existing);
            this.consideration.splice(index, 1, consideration);
        }
        else if (this.direction) {
            // if this list's direction is specified, only push new items that are of the same direction.  Some types can create both directions, but we shouldn't show that the other side in a directional listing.
            if (this.direction == consideration.ConsiderationDirection){
                this.consideration.push(consideration);
            }
        }
        this.$emit('saved', consideration);
    }
    considerationClosed(){
        this.selectedConsideration = {} as ConsiderationsType;
        this.showConsiderationEdit = false;
    }

    considerationSaved(consideration: ConsiderationsType ) {
        this.considerationSavedOnly(consideration);
        this.considerationClosed();
    }

    async editConsiderationItem(
        consideration: ConsiderationsType ,
        event,
        column
    ) {
        this.selectedConsideration = consideration;
        this.showConsiderationEdit = true;
    }
    async considerationDeleted(considerationId: number){
        const existing: ConsiderationsType = this.common.getSelectedArrayItem(
            this.consideration,
            considerationId.toString(),
            'Id'
        );
        if (existing && Object.keys(existing).length){
            const index = this.consideration.indexOf(existing);
            this.consideration.splice(index, 1);
        }
        this.selectedConsideration = {} as ConsiderationsType;
        this.$emit('delete', considerationId);
        this.showConsiderationEdit = false;
        if (this.consideration.length == 0){
            this.showNewConsideration = false; // when there are no more considerations, reset the New Consideration so that it will show like it is new again
            await this.$nextTick();
            this.showNewConsideration = true;
        }
    }
    async getExistingConsiderations(){
        if (this.header && this.header.Id){
            const cashParams = {} as TransactionsService.GetCashParameters;
            cashParams.TransactionHeaderId = this.header.Id;
            cashParams.Direction = this.direction;
            
            const capitalParams = {} as TransactionsService.GetCapitalParameters;
            capitalParams.TransactionHeaderId = this.header.Id;
            capitalParams.Direction = this.direction;
            
            const equityParams = {} as TransactionsService.GetEquityParameters;
            equityParams.TransactionHeaderId = this.header.Id;
            equityParams.Direction = this.direction;

            const expenseParams = {} as TransactionsService.GetExpenseParameters;
            expenseParams.TransactionHeaderId = this.header.Id;
            expenseParams.Direction = this.direction;

            const creditNotesParams = {} as TransactionsService.GetCreditNoteParameters;
            creditNotesParams.TransactionHeaderId = this.header.Id;
            creditNotesParams.Direction = this.direction;

            const debtInstrumentParams = {} as DebtService.GetInstrumentTransactionsParameters;
            debtInstrumentParams.TransactionHeaderId = this.header.Id;
            debtInstrumentParams.Direction = this.direction;

            let cash = [] as TransactionsService.Cash[];
            let capital = [] as TransactionsService.Capital[];
            let equity = [] as TransactionsService.Equity[];
            let expense = [] as TransactionsService.Expense[];
            let creditNotes = [] as TransactionsService.CreditNote[];
            let debtInstruments = [] as DebtService.InstrumentTransactions[];

            const promises = [];
            if ((this.singleConsiderationType && this.singleConsiderationType == 'Cash') || !this.singleConsiderationType){ promises.push(cash = await this._transactionsService.GetCash(cashParams));}
            if ((this.singleConsiderationType && this.singleConsiderationType == 'Capital') || !this.singleConsiderationType){ promises.push(capital = await this._transactionsService.GetCapital(capitalParams));}
            if ((this.singleConsiderationType && this.singleConsiderationType == 'Equity') || !this.singleConsiderationType){ promises.push(equity = await this._transactionsService.GetEquity(equityParams));}
            if ((this.singleConsiderationType && this.singleConsiderationType == 'Expense') || !this.singleConsiderationType){ promises.push(expense = await this._transactionsService.GetExpense(expenseParams));}
            if ((this.singleConsiderationType && this.singleConsiderationType == 'Note') || !this.singleConsiderationType){ promises.push(creditNotes = await this._transactionsService.GetCreditNote(creditNotesParams));}
            if ((this.singleConsiderationType && this.singleConsiderationType == 'Debt') || !this.singleConsiderationType){ promises.push(debtInstruments = await this._debtService.GetInstrumentTransactions(debtInstrumentParams));}
            Promise.all(promises);
            this.consideration = [];
            this.consideration = this.consideration.concat(cash);
            this.consideration = this.consideration.concat(capital.filter(consideration => consideration.ConsiderationTypeId != null)); //don't include non-Consideration Capital or Equity (which would be the Cap and Equity created by Stake Transactions)
            this.consideration = this.consideration.concat(equity.filter(consideration => consideration.ConsiderationTypeId != null));
            this.consideration = this.consideration.concat(expense);
            this.consideration = this.consideration.concat(creditNotes);
            this.consideration = this.consideration.concat(debtInstruments);
            this.$emit('consideration-list', this.consideration);
        }
    }
    //#endregion Methods

    }
