
import Vue from "vue";
import Component from "vue-class-component";
import { Watch, Prop, Model } from "vue-property-decorator";
import * as LiqService from "../../services/DAL/liq_Service";
import Common from '@/utils/common';
import formatters from '@/utils/formatters';
import BankAccountSelector from '@/components/form/BankAccountSelector.vue'


declare function tryParseInt(input: any, defaultValue: number): number;

@Component({
    inheritAttrs: false
    , components: {
        BankAccountSelector
    }
})
export default class BankTransactionSelector extends Vue {
    //#region Private declarations for Services
    private _liqService: LiqService.LiqService;
    private common: Common;
    private formatters: formatters;

    //#endregion Private declarations for Services

    //#region Props
    @Prop() readonly value: number;
    @Prop({
        required: true, 
        type: Number
    }) readonly bankAccountId: number;
    @Prop({
        required: true, 
        type: Date
    }) readonly fromDate: any;
    @Prop({
        required: true, 
        type: Date
    }) readonly toDate: any;
    @Prop({
        type: Number
    }) readonly amountToMatch: number
    @Prop({
        type: Boolean,
        default: false
    }) readonly allowSiblingAccountSelection: boolean
    @Prop({
        type: Boolean,
        default: false
    }) readonly highlightTransactionDateOutOfInputRange: boolean
    @Prop({
        type: Number
    }) readonly ownerId: number
    //#endregion Props

    //#region Data
    transactions = [] as LiqService.BankAccountTransactionsForInvestmentMatch[];
    selectedValue: number = null;
    previouslySelectedTransaction = {} as LiqService.BankAccountTransactionsForInvestmentMatch;
    filterBankAccountTransactionsForInvestmentMatch: string = ''
    showDialog = false;
    bankAccountId_: number = null
    fromDate_: any = null;
    toDate_: any = null;
    matchingBankTransactionsCollection = new Map();

    //#endregion Data

    //#region Lifecycle
    async created() {
        this._liqService = new LiqService.LiqService();
        this.common = new Common();
        this.formatters = new formatters();
        this.fromDate_ = this.fromDate;
        this.toDate_ = this.toDate;
        this.bankAccountId_ = this.bankAccountId;
    }

    async mounted() {
        this.selectedValue = this.value;
        if (this.value){
             this.getSelected();
        }
        else {
            this.fetchTransactions();
        }
    }
    //#endregion Lifecycle

    get dateRange() {
        return [this.fromDate_, this.toDate_];
    }
    set dateRange(value) {
        this.fromDate_ = this.$moment(value[0]).format('MM/DD/YYYY');
        this.toDate_ = this.$moment(value[1]).format('MM/DD/YYYY');
        this.fetchTransactions();
    }

    get selectedTransaction(): LiqService.BankAccountTransactionsForInvestmentMatch {
        if (this.transactions && this.transactions.length && this.value) {
            return this.common.getSelectedArrayItem(
                this.transactions,
                this.value.toString(),
                'TransactionId'
                );
        }
    }

	get transactionsFiltered(): LiqService.BankAccountTransactionsForInvestmentMatch[] {
		const filter_ = (this.filterBankAccountTransactionsForInvestmentMatch || '').toLowerCase();
        return this.transactions.filter(entity => {
            return (
				(entity.Amount || '').toString().toLowerCase().indexOf(filter_) > -1 ||
				(entity.Description || '').toString().toLowerCase().indexOf(filter_) > -1
            );
        });
    }

    get doHighlightTransactionDateOutOfInputRange(): boolean{
        return (this.highlightTransactionDateOutOfInputRange
        && this.selectedTransaction 
            && !!this.selectedTransaction.PostedDate
            && !!this.fromDate_
            && !!this.toDate_
            && !this.$moment(this.selectedTransaction.PostedDate).isBetween(this.$moment(this.fromDate_), this.$moment(this.toDate_), undefined, '[]') // '['= inclusive of the range
        )
    }

    //#region Watches
    @Watch("value")
    onChange_value(val: number, oldVal: number) {
        this.selectedValue = tryParseInt(val, null);
    }
    @Watch("bankAccountId")
    onChange_bankAccountId(val: number, oldVal: number) {
        this.bankAccountId_ = val;
        this.fetchTransactions();
    }
    @Watch("bankAccountId_")
    onChange_bankAccountId_(val: number, oldVal: number) {
        this.fetchTransactions();
    }
    //#endregion Watches

    //#region Methods
    changeSelection(){
        const needToRefetch = !!this.selectedValue;
        this.previouslySelectedTransaction = this.selectedTransaction;
        this.selectedValue = null;
        this.showDialog=true
        if (needToRefetch) this.fetchTransactions();
    }
    async fetchTransactions() {
        this.$setSystemStatus('Getting Transactions');
        this.transactions = [];
        if (this.bankAccountId_ && !this.selectedValue) { 
            const params = {} as LiqService.GetBankAccountTransactionsForInvestmentMatchParameters;
            params.BankAccountId = this.bankAccountId_;
            params.DateRangeStart = this.fromDate_;
            params.DateRangeEnd = this.toDate_;
            this.transactions = await this._liqService.GetBankAccountTransactionsForInvestmentMatch(params);
            this.$emit('options-fetched', this.transactions);
            this.$setSystemStatus('');
        }
    }
    async getSelected() {
        if (this.value){
            this.$setSystemStatus('Getting Selected Transaction');
            const params = {} as LiqService.GetBankAccountTransactionsForInvestmentMatchParameters;
            params.TransactionId = this.value;
            this.transactions = await this._liqService.GetBankAccountTransactionsForInvestmentMatch(params);
            this.$emit('options-fetched', this.transactions);
            this.$emit('initial-value', this.transactions[0]);
            this.$setSystemStatus('');
        }
    }
    onClear() {
        this.selectedValue = null;
        this.$emit('input', null);
    }

    async editTransactionsItem (selectedRow: LiqService.BankAccountTransactionsForInvestmentMatch, event, column) {
        if (selectedRow && selectedRow != null) {
            if (selectedRow.DisableMatches && !(!!this.previouslySelectedTransaction && this.previouslySelectedTransaction.TransactionId == selectedRow.TransactionId)){ // ok to select a depleted row it was the row already selected (i.e. it was depleted by this operation already)
                this.$alert(`Item cannot be selected because ${selectedRow.DisabledMatchReason}.`);
            }
            else {
                this.$emit('input', selectedRow.TransactionId);
                this.$emit('option-selected', selectedRow);
                this.showDialog = false;
            }
        }
    }
    dialogClosed(){
        if (!!this.previouslySelectedTransaction && this.previouslySelectedTransaction.TransactionId && !this.selectedValue){
            this.selectedValue = this.previouslySelectedTransaction.TransactionId;
            if (!this.selectedTransaction){ // put the existing transaction into the array so that it can be filtered by the computed prop selectedTransaction
                this.transactions.push(this.previouslySelectedTransaction); 
            }
            this.$emit('input', this.previouslySelectedTransaction.TransactionId); // on close, if there was a previous value, and no current value, reset to previous value
            this.$emit('option-selected', this.previouslySelectedTransaction);
        }
        this.showDialog = false;
    }
    rowStyle(row, rowIndex){
        if (row.row.DisableMatches){
                return {'background-color': 'darkgrey'};
            }
        if (0-row.row.Amount == this.amountToMatch){
                return {'background-color': 'yellow'};
            }
    } 
    async getMatchData(transaction: LiqService.BankAccountTransactionsForInvestmentMatch){
        let data = this.matchingBankTransactionsCollection.get(transaction.TransactionId);
        if (!data){
            const params = {} as LiqService.GetMatchesForTransactionParameters;
            params.TransactionId = transaction.TransactionId;
            params.UseNewInvestmentTransactions = true;
            data = await this._liqService.GetMatchesForTransaction(params);
            //this.matchingBankTransactionsCollection.set(transaction.Id, data); //The method below is required to make Map act reactive. https://stackoverflow.com/questions/37130105/does-vue-support-reactivity-on-map-and-set-data-types
            this.matchingBankTransactionsCollection = new Map(this.matchingBankTransactionsCollection.set(transaction.TransactionId, data));
        }
    }
    getCachedMatchData(transaction: LiqService.BankAccountTransactionsForInvestmentMatch){
        return this.matchingBankTransactionsCollection.get(transaction.TransactionId)
    }

    
    //#endregion Methods
}
