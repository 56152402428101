// TS API for Schema valuation
// Auto-generated 07/06/22 09:02


declare var smartData: any;
// output classes:
export class FlashEntry {
    Name: string=undefined;
    EntityId: number=undefined;
    EntityType: string=undefined;
    AsOf: string=undefined;
    PercentValue: number=undefined;
    Value: number=undefined;
    LastChange: string=undefined;
    ValuationId: number = undefined;
}
export class NeededExchangeRates {
    Currency: string=undefined;
    PriceDate: string=undefined;
    PriceDateString: string = undefined;
}
export class TickersToUpdateManually {
    Ticker: string=undefined;
    Currency: string=undefined;
    CloseDate: string=undefined;
    ClosePrice: number=undefined;
    ShortDateUser: string=undefined;
    RequiresAttention: boolean=undefined;
    OriginalCurrency: string=undefined;
    ExchangeRateDate: string=undefined;
    Trailing7DayAverageRate: number=undefined;
    ClosePriceLocalCurrency: number = undefined;
}
export class TypeForEntity {
    Type: string = undefined;
}
export class Valuations {
    Id: number=undefined;
    Type: string=undefined;
    EntityId: number=undefined;
    EntityType: string=undefined;
    AsOf: string=undefined;
    Value: number=undefined;
    Source: string=undefined;
    CreateUser: string=undefined;
    CreateDate: string=undefined;
    ModifyUser: string=undefined;
    ModifyDate: string=undefined;
    Notes: string=undefined;
    NotesDisplay: string=undefined;
    ImportLogId: number=undefined;
    ImportId: number=undefined;
    ShortDateUserLocal: string=undefined;
    ModifyShortDateUserLocal: string = undefined;
}
export class QueueProcessOHLC {
    QueueId: number = undefined;
}
export class ValidateImport {
    EntityId: string=undefined;
    Name: string=undefined;
    AsOf: string=undefined;
    Type: string=undefined;
    Value: string=undefined;
    Source: string=undefined;
    Notes: string=undefined;
    Status: string=undefined;
    Message: string=undefined;
    Discard: boolean = undefined;
}

// Parameter interfaces:

export interface GetFlashEntryParameters {
    AsOf: string;
}

export interface GetTypeForEntityParameters {
    EntityType: string;
    EntityId: number;
}

export interface GetValuationsParameters {
    EntityId: number;
    EntityType: string;
    ValuationId: number;
}


export interface QueueProcessOHLCParameters {
    SourceLogId: number;
    Silent: boolean;
}

export interface ValidateImportParameters {
    LogId: number;
}

export class ValuationService {

  GetFlashEntry (params: GetFlashEntryParameters): Promise<FlashEntry[]> {
    return smartData({
        storedProcName: 'valuation.GetFlashEntry',
        params: params
      });
    }

  GetNeededExchangeRates (): Promise<NeededExchangeRates[]> {
    return smartData({
        storedProcName: 'valuation.GetNeededExchangeRates',
        params: {}
      });
    }

  GetTickersToUpdateManually (): Promise<TickersToUpdateManually[]> {
    return smartData({
        storedProcName: 'valuation.GetTickersToUpdateManually',
        params: {}
      });
    }

  GetTypeForEntity (params: GetTypeForEntityParameters): Promise<TypeForEntity[]> {
    return smartData({
        storedProcName: 'valuation.GetTypeForEntity',
        params: params
      });
    }

  GetValuations (params: GetValuationsParameters): Promise<Valuations[]> {
    return smartData({
        storedProcName: 'valuation.GetValuations',
        params: params
      });
    }

  QueueProcessOHLC (params: QueueProcessOHLCParameters): Promise<QueueProcessOHLC[]> {
    return smartData({
        storedProcName: 'valuation.QueueProcessOHLC',
        params: params
      });
    }

  ValidateImport (params: ValidateImportParameters): Promise<ValidateImport[]> {
    return smartData({
        storedProcName: 'valuation.ValidateImport',
        params: params
      });
    }
}
