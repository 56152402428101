
import { setValue, getValue } from "vue-currency-input";
import {evaluate } from 'mathjs';

export default {
  name: "ELCurrencyInput",
  inheritAttrs: false,
  props: {
    value: {
      type: Number,
      default: null,
    },
    options: {
      type: Object,
      default: () => {},
    },
    targetClass: {
      type: String,
    },
    hideExpressionEntry: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      formattedValue: null,
      expression: null
    };
  },
  watch: {
    value: "setValue",
  },
  mounted() {
    this.setValue(this.value);
  },
  methods: {
    handleInput(value) {
      this.$emit("input", getValue(this.$refs.input));
      this.formattedValue = value;
    },
    handleChange(value) {
      this.$emit("change", getValue(this.$refs.input));
      this.formattedValue = value;
    },
    setValue(value) {
      setValue(this.$refs.input, value);
    },
    focus(){
      console.log(this.$refs.input);
        this.$refs.input.focus();
    },
    async enterExpression(){
      try{
        const conf = await this.$prompt(
          'Please enter Expression',
          'Expression',
          {
            confirmButtonText: "OK",
            showCancelButton: true,
            inputValue: this.expression || getValue(this.$refs.input),
          }
        );
        this.expression = conf.value;
        const result = evaluate(conf.value);
        if (result && typeof result == 'number'){
          this.$emit("input", result);
          this.setValue(result);
        }
        else {
          this.$message.warning('Unable to evaluate expression');
          this.$emit("input", null);
          this.setValue(null);
          this.enterExpression();
        }
      }
      catch{} // cancelled prompt
    }
  },
};
