
import { Component, Vue, Prop, Watch, Ref } from "vue-property-decorator";
import * as TransactionsService from '@/services/DAL/transactionsService';
import Common from '@/utils/common';
import CashEdit from '@/views/InvestmentTransaction/Cash/CashEdit.vue';

declare var SmartObject: any;
declare function getStoredSecurityLevel(Id: number): number;
declare function tryParseInt(input: any, defaultValue: number): number;

@Component({
    components: {
        CashEdit
    }
})
export default class CashTransferEdit extends Vue {
    @Ref() fromCashEdit!: CashEdit
    @Ref() toCashEdit!: CashEdit
    
    //#region Private declarations for Services
    private _transactionsService: TransactionsService.TransactionsService;
    private common: Common;
    
    //#endregion Private declarations for Services

    //#region Props
    @Prop() header: TransactionsService.Header;
    @Prop( {type: Object }) transactionType: TransactionsService.Types;
    @Prop() cash: TransactionsService.Cash;
    
    @Prop({
        type: Number
    }) readonly ownerId: number
    //#endregion Props

    //#region Data
    securityLevel_: number = null;
    fromCash_ = {} as TransactionsService.Cash;
    toCash_ = {} as TransactionsService.Cash;
    loading = false;
    isNew = false;
    transactionType_ = {} as TransactionsService.Types;
    allowManualValue_ = false;
    selectedTransactionAmount: number = null;
    overrideSignRequirement = false;
    showCashEdit = false;
   
    //#endregion Data

    //#region Lifecycle
    async created() {
        this._transactionsService = new TransactionsService.TransactionsService();
        this.common = new Common();
        this.securityLevel_ = tryParseInt(
        getStoredSecurityLevel(this.$namedKey.SecurityView.ManageAssets),
            0
        );

        if (this.cash && Object.keys(this.cash).length){
            if (this.cash.SourceCashId && !this.cash.TargetCashId){
                this.toCash_ = this.cash;
                this.fromCash_ = await this.getCash(this.cash.SourceCashId);
            }
            else if (!this.cash.SourceCashId && this.cash.TargetCashId){
                this.fromCash_ = this.cash;
                this.toCash_ = await this.getCash(this.cash.TargetCashId);
            }
            this.showCashEdit = true;
        }
        else {
            this.toCash_ = new TransactionsService.Cash();
            this.fromCash_ = new TransactionsService.Cash();
            if (this.header && Object.keys(this.header).length){
                this.toCash_.EntityId = this.header.Id;
                this.toCash_.EntityType = 'InvestmentTransactionHeader';
                this.fromCash_.EntityId = this.header.Id;
                this.fromCash_.EntityType = 'InvestmentTransactionHeader';
            }
            this.toCash_.ConsiderationDirection = 'Result';
            this.fromCash_.ConsiderationDirection = 'Source';
            this.isNew = true;
            this.showCashEdit = true;
        }
        this.handleTransactionType();
    }
    async mounted() {
    }
    //#endregion Lifecycle
    
    //#region Watches

    //#endregion Watches

    //#region Methods

    async saveItem() {
        this.fromCash_ = await this.fromCashEdit.saveItem();
        this.toCash_ = await this.toCashEdit.saveItem()
        this.$emit('saved', this.toCash_);
        this.$emit('saved', this.fromCash_);
        this.$emit('close');
    }
    async getCash(cashId: number): Promise<TransactionsService.Cash>{
        const params = {} as TransactionsService.GetCashParameters;
        params.CashId = cashId;
        const cashArray = await this._transactionsService.GetCash(params);
        if (cashArray && cashArray.length ==1){
            return cashArray[0];
        }        
    }

    async deleteItem() {
        if (!this.isNew) {
            try {
                await this.$confirm(
                    'This will permanently delete this Cash Transfer item. Continue?',
                    'Warning',
                    {
                        confirmButtonText: 'OK',
                        cancelButtonText: 'Cancel',
                        type: 'warning'
                    }
                );

                await this.doDeleteTransactionCash();
            } catch {}
        }
    }

    async doDeleteTransactionCash() {
        try {
            if (this.fromCash_ && this.fromCash_.CashId){
                await new SmartObject(
                    'InvestmentTransactionCash',
                    this.fromCash_.CashId
                ).deleteObject();

                this.$notify.success('From Cash item deleted.');
            }
            if (this.toCash_ && this.toCash_.CashId){
            await new SmartObject(
                'InvestmentTransactionCash',
                this.toCash_.CashId
            ).deleteObject();

            this.$notify.success('To Cash item deleted.');
            }

            this.$emit('deleted', this.fromCash_.CashId);
            this.$emit('deleted', this.toCash_.CashId);
            this.fromCash_ = {} as TransactionsService.Cash;
            this.toCash_ = {} as TransactionsService.Cash;
            this.$emit('close');
        } catch {
            this.$notify.error('Something went wrong processing your request, please try again.');
        }
    }
    async handleTransactionType(){
            this.transactionType_ = {} as TransactionsService.Types;
        if (this.transactionType && Object.keys(this.transactionType).length && this.transactionType.Id > 0){
            this.transactionType_ = this.transactionType;
        }
        else if (this.header && Object.keys(this.header).length && this.header.TypeId > 0){ 
            const params = {} as TransactionsService.GetTypesParameters;
            const types = await this._transactionsService.GetTypes(params);
            if (types && types.length){
                this.transactionType_ = this.common.getSelectedArrayItem(
                    types,
                    this.header.TypeId.toString(),
                    'Id'
                    );
            }
        }
    }
    //#endregion Methods
}
