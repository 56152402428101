
import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch, Prop } from 'vue-property-decorator';
import FormattedInput from '@/components/form/FormattedInput.vue';
import BankAccountSelector from '@/components/form/BankAccountSelector.vue';
import NoteSelector from '@/components/form/NoteSelector.vue';
import TgPublicEquitySelector from '@/components/form/TgPublicEquitySelector.vue';
import TransactionSelector from '@/components/form/TransactionSelector.vue';
import * as AssetService from '../../services/DAL/assetService';
import * as TransactionsService from '../../services/DAL/transactionsService';
import Common from '../../utils/common';
import cloneDeep from 'lodash/cloneDeep';
import ElementUI from 'element-ui';

declare var SmartObject: any;

@Component({
  components: {
    FormattedInput,
    BankAccountSelector,
    NoteSelector,
    TgPublicEquitySelector,
    TransactionSelector
  }
})
export default class FundingSourceEdit extends Vue {
  $refs: {
    frmFundingSourceEdit: ElementUI.Form
  }
  //#region Data
  private _transactionsService: TransactionsService.TransactionsService;
  private _assetService: AssetService.AssetService;
  private _common: Common;

  @Prop() fundingSource: TransactionsService.FundingSource;
  @Prop() clientId: number;
  @Prop() ownerId: number;
  @Prop() transactionId: number;
  @Prop() transactionTypeId: number;
  @Prop() transactionDate: string;
  @Prop() holdingEntityId: number;
  @Prop() investmentId: number;
  @Prop() isConsideration: boolean;
  @Prop() viewType: string;

  fundingSource_: TransactionsService.FundingSource = new TransactionsService.FundingSource();
  fundingSourceTypes = [];
  selectedFundingSourceType: AssetService.FundingSourceTypes = new AssetService.FundingSourceTypes();
  automaticallyCreateTransaction: boolean = false;
  //#endregion Data

  //#region Computed
  get isNew(): boolean {
    return (
        !this.fundingSource || 
        !Object.keys(this.fundingSource).length ||
        (this.fundingSource_.Id || 0) <= 0
    );
  }
  get isFormDirty(): boolean {
    return Object.keys((this as any).veeFields).some(
      key => (this as any).veeFields[key].dirty
    );
  }
  //#endregion Computed

  //#region Lifecycle
  async created() {
    if (this.fundingSource && Object.keys(this.fundingSource).length) {
      this.fundingSource_ = this.fundingSource;
    }
    this._assetService = new AssetService.AssetService();
    this._transactionsService = new TransactionsService.TransactionsService();
    this._common = new Common();

    await this.fetchFundingSourceTypes();
    this.setSelectedFundingSourceType();
  }
  //#endregion Lifecycle

  //#region Watches
  @Watch('fundingSource_.FundingSourceTypeId')
  onChange_FundingSourceTypeId(val?: any, oldVal?: any) {
      if (this.fundingSourceTypes.length) {
        this.setSelectedFundingSourceType();
        this.fundingSource_.FundingSourceType = this.selectedFundingSourceType.Display;

        this.$refs.frmFundingSourceEdit.clearValidate('EntityId');
        
        this.fundingSource_.SourceInvestmentId = null;
        this.fundingSource_.EntityId = null;
      }
  }

  @Watch('automaticallyCreateTransaction')
  onChange_automaticallyCreateTransaction(val?: any, oldVal?: any) {
      this.fundingSource_.EntityId = null;
      this.$refs.frmFundingSourceEdit.clearValidate('EntityId');
  }
  //#endregion Watches

  //#region Methods
  async fetchFundingSourceTypes() {
    this.fundingSourceTypes = [] as AssetService.FundingSourceTypes[];
    const parameters = {} as AssetService.GetFundingSourceTypesParameters;
    parameters.TransactionTypeId = this.transactionTypeId;
    parameters.NewInvestmentId = this.investmentId;
    this.fundingSourceTypes = await this._assetService.GetFundingSourceTypes(parameters);
  }

  setSelectedFundingSourceType() {
    let selectedFundingSourceType = null;

    if (this.fundingSource_.FundingSourceTypeId > 0) {
        selectedFundingSourceType = this._common.getSelectedArrayItem(
            this.fundingSourceTypes,
            this.fundingSource_.FundingSourceTypeId.toString(),
            'FundingSourceTypeId'
        );
    }

    if (selectedFundingSourceType) {
        this.selectedFundingSourceType = selectedFundingSourceType;
    }
    else {
        this.selectedFundingSourceType = {} as AssetService.FundingSourceTypes;
    }
  }
  
  async saveForm() {
    this.$refs.frmFundingSourceEdit.validate(async valid => {
      if (valid) {
        let message: string;

        try {
            if (this.selectedFundingSourceType.EntityType === 'InvestmentTransaction' && this.automaticallyCreateTransaction) {
                this.fundingSource_.EntityId = await new SmartObject('Transaction').createObject({
                    InvestmentId: this.fundingSource_.SourceInvestmentId,
                    TransactionTypeId: 6,
                    TransactionDate: this.transactionDate,
                    Amount: this.fundingSource_.Amount,
                    ReturnIncomeType: 1005, // redemption
                    Structure: 'LP',
                    SourceStructureType: 'LP',
                    EntityId: this.holdingEntityId,
                    SourceTransactionId: this.transactionId
                });
            }

            if (this.isNew) {
                this.fundingSource_.TransactionId = this.transactionId;
                this.fundingSource_.EntityType = this.selectedFundingSourceType.EntityType;
                this.fundingSource_.Id = await new SmartObject('FundingSource').createObject(this.fundingSource_);

                message = `${this.isConsideration ? 'Consideration' : 'Funding source'} added.`;
            }
            else {
                await new SmartObject('FundingSource', this.fundingSource_.Id).updateObject(this.fundingSource_);
                message = 'Changes saved.';
            }

            this.$notify.success(message);

            this.$emit('saved', this.fundingSource_);
            this.$emit('close');
        }
        catch (err) {
            this.$notify.error('Something went wrong processing your request, please try again.');
            return;
        }
      }
    });
  }

  async deleteFundingSource() {
    if (!this.isNew) {
      try {
        await this.$confirm(
          `This will permanently delete this ${this.isConsideration ? 'consideration' : 'funding source'}. Continue?`,
          'Warning',
          {
            confirmButtonText: 'OK',
            cancelButtonText: 'Cancel',
            type: 'warning'
          }
        );

        try {
            await new SmartObject('FundingSource', this.fundingSource_.Id).deleteObject();

            this.$notify.success(`${this.isConsideration ? 'Consideration' : 'Funding source'} deleted.`);

            this.$emit('deleted', this.fundingSource_.Id);
            this.$emit('close');
        } catch {
            this.$notify.error('Something went wrong processing your request, please try again.');
        }
      } catch {}
    }
  }
  //#endregion Methods
}
