
import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch, Prop } from 'vue-property-decorator';
import * as ValuationService from './../../services/DAL/valuationService';
import * as UtilService from './../../services/DAL/utilService';

import FormattedInput from '@/components/form/FormattedInput.vue';
import formatters from '../../utils/formatters';
import Common from '../../utils/common';

declare var SmartObject: any;

@Component({
    components: {
        FormattedInput
    }
})
export default class ManualTickerList extends Vue {
    //#region Data
    private _valuationService: ValuationService.ValuationService;
    private _utilService: UtilService.UtilService;
    private formatters: formatters = new formatters();
    private _common: Common;

    tickersToUpdateManually: ValuationService.TickersToUpdateManually[] = [];
    closeDate: Date = this.getPreviousBusinessDay();
    isLoading = false;
    //#endregion Data

    //#region Computed
    get tickersToSave(): ValuationService.TickersToUpdateManually[] {
        const tickers = this.tickersToUpdateManually.filter(ticker => {
            return (ticker as any).Price > 0;
        });
        return tickers;
    }
    
    get invalidTickers(): ValuationService.TickersToUpdateManually[] {
        const tickers = this.tickersToUpdateManually.filter(ticker => {
            return ((ticker as any).Price <= 0 && (ticker as any).Price !== null);
        });
        return tickers;
    }
    //#endregion Computed

    //#region Lifecycle
    async created() {
        this._valuationService = new ValuationService.ValuationService();
        this._utilService = new UtilService.UtilService();
        this.formatters = new formatters();
        this._common = new Common();
    }

    async mounted() {
        this.fetchTickersToUpdateManually();
    }
    //#endregion Lifecycle

    //#region Methods
    async fetchTickersToUpdateManually() {
        this.isLoading = true;
        this.tickersToUpdateManually = [];
        this.tickersToUpdateManually = await this._valuationService.GetTickersToUpdateManually();
        this.isLoading = false;
    }

    getPreviousBusinessDay() {
        switch(this.$moment().day())
        {
            // If it is Monday (1),Saturday(6), or Sunday (0), Get the previous Friday (5)
            // and ensure we are on the previous week
            case 0:
            case 1:
            case 6:
                return this.$moment().subtract(6,'days').day(5).toDate();
            // If it any other weekend, just return the previous day
            default:
                return this.$moment().day(this.$moment().day() - 1).toDate();
        }
    }

    async saveTickerPrices(row) {
        try {
            if (!this.tickersToSave.length) {
                return;
            }

            this.isLoading = true;
            let log: UtilService.LogEventSelect;
            const eventParms = {} as UtilService.LogEventSelectParameters;
            eventParms.Database = 'VisiumDB';
            eventParms.Schema = 'valuation';
            eventParms.Caller = 'OHLC';
            eventParms.Event = 'Insert';
            const logs = await this._utilService.LogEventSelect(eventParms);
            
            if (logs && logs.length){
                log = logs[0];
            }
            else {
                console.log('could not create log');
                 this.$notify.error('Could not save because Log Id was not created.');
                 this.isLoading = false;
                 return;
            }

            const promises = [];

            for (let i = 0; i < this.tickersToSave.length; i++) {
                const ticker = this.tickersToSave[i];

                const promise = new SmartObject('OHLC').updateFromCompositeKey({
                    keys: {
                        Ticker: ticker.Ticker,
                        CloseDate: this.$moment(this.closeDate).format('MM/DD/YYYY')
                    },
                    values: {
                        ClosePrice: (ticker as any).Price
                        , ImportLogId: log.LogId
                    }
                });

                promises.push(promise);
            }

            await Promise.all(promises);

            const completeParms = {} as UtilService.CompleteLogEventParameters;
            completeParms.LogId = log.LogId;
            completeParms.Success = true;
            await this._utilService.CompleteLogEvent(completeParms);

            this.$notify.success('Ticker prices saved.');
            const parms = {} as ValuationService.QueueProcessOHLCParameters;
            parms.SourceLogId = log.LogId;
            await this._valuationService.QueueProcessOHLC(parms);
            this.$notify.success('Accounting for Manual Prices is QUEUED.  You will receive an email notification when complete.');
            this.fetchTickersToUpdateManually();
        } catch {
            this.$notify.error('Something went wrong processing your request, please try again.');
        }
    }
    //#endregion Methods
}
