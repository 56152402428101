
    import mixinUtility from './../VueCommon/Utility.js'
    import mixinSchema_liq from './../DAL/mixinSchema_liq'
    import mixinSchema_visium from './../DAL/mixinSchema_visium'
    import mixinSchema_vam from './../DAL/mixinSchema_vam'
    import mixinSchema_av from './../DAL/mixinSchema_av'
    import mixinSchema_asset from './../DAL/mixinSchema_asset'
    import mixinSchema_reports from './../DAL/mixinSchema_reports'
    import mixinSchema_util from './../DAL/mixinSchema_util'
    import KeyPersonSelector from '@/components/form/KeyPersonSelector.vue';
    import ClientSelector from '@/components/form/ClientSelector.vue';

    import Vue from 'vue'
    import * as LiqService from '@/services/DAL/liq_Service.js'

    declare class iParameterObject{
                    Prompt: string;
                    Placeholder: string;
                    Hidden: boolean;
                    Required: boolean;
                    Explanation: string;
                }

    export default Vue.extend({
    name: "ReportList"
    , components: {
        KeyPersonSelector
        , ClientSelector
        }
    ,
    mixins: [mixinUtility, mixinSchema_liq, mixinSchema_visium, mixinSchema_reports, mixinSchema_vam, mixinSchema_av, mixinSchema_asset, mixinSchema_util],
    props: [
        "reportId"
    ],
    data: function () {
        const el = this;
        return {
            parameters: [],
            reportParameters: [],
            report: null,
            showReport: false,
            reports: [],
            reportGroups: [],
            reportGroupId: null,
            openInNewTab: true,
            selectedReportRow: {},
            activeNames: ["reports"],
            owners: [],
            vendors: [],
            fundHoldingNames: [],
            fundIds: [],
            activitySourcesOptions: [],
            reportConfigurations: [],
            ownerEntityGroupTypes: [],
            agreementGroups: [],
            aircrafts: [],
            bankAccounts: [],
            liquidityLogIds: [],
            balanceSheetLogIds: [],
            clientId: null,
            investmentTypeIdList: [],
            valuablesCategoryId: null,
            ownerId: null,
            ownerIdList: [],
            accountId: null,
            activitySources: [],
            vendorId: null,
            vendorIdList: [],
            fundIdList: [],
            fundHoldingName: null,
            fundId: null,
            dateRange: null,
            dateRange1: null,
            dateRange2: null,
            dateRange3: null,
            date1: null,
            date2: null,
            date3: null,
            date1DefaultSet: false,
            date2DefaultSet: false,
            date3DefaultSet: false,
            monthPicker: el.$dayjs().format("MM/DD/YYYY"),
            bit1: null,
            bit2: null,
            bit3: null,
            bit1DefaultSet: false,
            bit2DefaultSet: false,
            bit3DefaultSet: false,
            bit3Way1: "",
            bit3Way2: "",
            bit3Way3: "",
            bit3Way1DefaultSet: false,
            bit3Way2DefaultSet: false,
            bit3Way3DefaultSet: false,
            number1: null,
            number2: null,
            number3: null,
            number1DefaultSet: false,
            number2DefaultSet: false,
            number3DefaultSet: false,
            text1: null,
            text2: null,
            text3: null,
            text1DefaultSet: false,
            text2DefaultSet: false,
            text3DefaultSet: false,
            liquidityDate: el.$dayjs().format("MM/DD/YYYY"),
            liquidityLogId: null,
            balanceSheetAsOf: el.$dayjs().format("MM/DD/YYYY"),
            balanceSheetLogId: null,
            reportConfigurationId: null,
            reportConfigurationId2: null,
            ownerEntityGroupTypeId: null,
            agreementGroupId: null,
            agreementGroupIdList: [],
            coAList: [],
            cOAIdList: [],
            invoiceStatusForPaymentDashboardList: [] as LiqService.InvoiceStatusForPaymentDashboard[],
            invoiceStatusForPaymentDashboard: [] as LiqService.InvoiceStatusForPaymentDashboard[],
            departmentList: [],
            departmentIdList: [],
            layoutGroupList: [],
            layoutGroupIdList: [],
            aircraftId: null,
            keyPersonId: null as number,
            accountTypeId: null,
            accountTypes: [],
            gettingAccountTypes: false,
            noAccountTypesFound: false,
            investmentParentId: null,
            investmentParents: [],
            gettingBalanceSheetLogIds: false,
            noBalanceSheetsLogIdsFound: false,
            noLiquidityLogIdsFound: false,
            loadingParameters: false,
            gettingLiquidityLogIds: false,
            gettingVendors: false,
            gettingActivitySources: false,
            gettingInvestmentTypes: false,
            investmentTypes: [],
            valuablesCategories: [],
            clientIsSingleOption: false,
            datePickerOptions: {
                shortcuts: [
                    {
                        text: "This Year",
                        onClick(picker) {
                            const start = el.$dayjs().startOf("year");
                            const end = el.$dayjs().endOf("year");
                            picker.$emit("pick", [start.toDate(), end.toDate()]);
                        }
                    },
                    {
                        text: "YTD",
                        onClick(picker) {
                            const start = el.$dayjs().startOf("year");
                            const end = el.$dayjs();
                            picker.$emit("pick", [start.toDate(), end.toDate()]);
                        }
                    },
                    {
                        text: "This Month",
                        onClick(picker) {
                            const start = el.$dayjs().startOf("month");
                            const end = el.$dayjs().endOf("month");
                            picker.$emit("pick", [start.toDate(), end.toDate()]);
                        }
                    },
                    {
                        text: "MTD",
                        onClick(picker) {
                            const start = el.$dayjs().startOf("month");
                            const end = el.$dayjs();
                            picker.$emit("pick", [start.toDate(), end.toDate()]);
                        }
                    },
                    {
                        text: "LTM",
                        onClick(picker) {
                            const start = el.$dayjs().subtract(1, "year").add(1, "day");
                            const end = el.$dayjs();
                            picker.$emit("pick", [start.toDate(), end.toDate()]);
                        }
                    },
                    {
                        text: "Last Year",
                        onClick(picker) {
                            const start = el.$dayjs().subtract(1, "year").startOf("year");
                            const end = el.$dayjs().subtract(1, "year").endOf("year");
                            picker.$emit("pick", [start.toDate(), end.toDate()]);
                        }
                    },
                    {
                        text: "Last Month",
                        onClick(picker) {
                            const start = el.$dayjs().subtract(1, "month").startOf("month");
                            const end = el.$dayjs().subtract(1, "month").endOf("month");
                            picker.$emit("pick", [start.toDate(), end.toDate()]);
                        }
                    },
                    {
                        text: "Last Week",
                        onClick(picker) {
                            const start = el.$dayjs().subtract(1, "week").startOf("week");
                            const end = el.$dayjs().subtract(1, "week").endOf("week");
                            picker.$emit("pick", [start.toDate(), end.toDate()]);
                        }
                    },
                    {
                        text: "This Week",
                        onClick(picker) {
                            const start = el.$dayjs().startOf("week");
                            const end = el.$dayjs().endOf("week");
                            picker.$emit("pick", [start.toDate(), end.toDate()]);
                        }
                    }
                ]
            }
        };
    },
    created: async function () {
        this.reportGroups = await this.reports_GetGroups({});
        await this.fetchReports();
        if (this.reportId > 0) {
            var row = this.reports.find(report => {
                return report.ReportId == this.reportId;
            });
            if (row) {
                this.selectReport(row);
            }
        }
    },
    computed: {
        displayReportName: function () {
            return (Object.keys(this.selectedReportRow).length > 0) ? this.selectedReportRow.DisplayName : "";
        }
    },
    watch: {
        "selectedReportRow": async function (val, oldVal) {
            if ((Object.keys(val).length > 0)) {
                await this.fetchReportParameters();
                this.activeNames = ["parameters"];
                this.resetParameters();
            }
            else {
                this.activeNames = ["reports"];
            }
        },
        "clientId": function (val, oldVal) {
            this.clientIdUpdated(val);
        },
        "ownerId": function (val, oldVal) {
            if (!(this.getParameterObject("AccountId").Hidden) || !(this.getParameterObject("AccountIdList").Hidden)) {
                this.fetchBankAccounts();
            }
        },
        "liquidityDate": function (val, oldVal) {
            this.fetchLiquidityLogIds();
        },
        "balanceSheetAsOf": function (val, oldVal) {
            this.fetchBalanceSheetLogIds();
        },
        "reportGroupId": function (val, oldVal) {
            this.fetchReports();
        },
        "reportConfigurationId": function (val, oldVal) {
            this.fetchOwnerIdList();
        } //note that reportConfigurationId2 does not have a watch because it would be redundant.  But if some need for it comes up, it can be added.
    },
    methods: {
        async resetParameters() {
            this.reportConfigurations = [];
            this.ownerEntityGroupTypes = [];
            this.agreementConfigurations = [];
            this.aircrafts = [];
            this.bankAccounts = [];
            this.investmentTypeIdList = [];
            this.valuablesCategoryId = null;
            this.ownerId = null;
            this.ownerIdList = [];
            this.accountId = null;
            this.activitySources = [];
            this.vendorId = null;
            this.vendorIdList = [];
            this.fundIdList = [];
            this.dateRange = null;
            this.dateRange1 = null;
            this.dateRange2 = null;
            this.dateRange3 = null;
            this.date1 = null;
            this.date2 = null;
            this.date3 = null;
            this.date1DefaultSet = false;
            this.date2DefaultSet = false;
            this.date3DefaultSet = false;
            this.monthPicker = null;
            this.bit1 = null;
            this.bit2 = null;
            this.bit3 = null;
            this.bit1DefaultSet = false;
            this.bit2DefaultSet = false;
            this.bit3DefaultSet = false;
            this.bit3Way1 = "";
            this.bit3Way2 = "";
            this.bit3Way3 = "";
            this.bit3Way1DefaultSet = false;
            this.bit3Way2DefaultSet = false;
            this.bit3Way3DefaultSet = false;
            this.number1 = null;
            this.number2 = null;
            this.number3 = null;
            this.number1DefaultSet = false;
            this.number2DefaultSet = false;
            this.number3DefaultSet = false;
            this.text1 = null;
            this.text2 = null;
            this.text3 = null;
            this.text1DefaultSet = false;
            this.text2DefaultSet = false;
            this.text3DefaultSet = false;
            this.reportConfigurationId = null;
            this.reportConfigurationId2 = null;
            this.ownerEntityGroupTypeId = null;
            this.agreementGroupId = null;
            this.agreementGroupIdList = [];
            this.coAList = [];
            this.cOAIdList = [];
            this.invoiceStatusForPaymentDashboardList = [] as LiqService.InvoiceStatusForPaymentDashboard[];
            this.invoiceStatusForPaymentDashboard = [] as LiqService.InvoiceStatusForPaymentDashboard[];
            this.departmentList = [];
            this.departmentIdList = [];
            this.layoutGroupList = [];
            this.layoutGroupIdList = [];
            this.keyPersonId = null as number;
            this.aircraftId = null;
            this.accountTypeId = null;
            this.investmentParentId = null;
            await this.$nextTick();
            this.clientIdUpdated(this.clientId); // since we haven't reset the ClientId, need to re-call all the things that would happen when the user changes the client
        },
        clientIdUpdated: async function (updatedClientId) {
            if (!(this.getParameterObject("OwnerId").Hidden) || !(this.getParameterObject("OwnerIdList").Hidden)) {
                this.loadingParameters = true;
                this.$setSystemStatus("Getting Owners");
                this.owners = await this.vam_GetOwnersV2({
                    ClientId: this.clientId
                });
                this.loadingParameters = false;
                this.$setSystemStatus("");
                if (!(this.getParameterObject("OwnerIdList").Hidden)) {
                    this.fetchOwnerIdList();
                }
            }
            if (!(this.getParameterObject("ReportConfigurationId").Hidden)) {
                this.reportConfigurationId = null;
                this.fetchReportConfigurations();
            }
            if (!(this.getParameterObject("ReportConfigurationId2").Hidden)) {
                this.reportConfigurationId2 = null;
                //this.fetchReportConfigurations(); //don't do this again.  Assumes that it was already done by ReportConfigurationId.  But if the need arises, its fine to add it back.
            }
            if (!(this.getParameterObject("OwnerEntityGroupTypeId").Hidden)) {
                this.ownerEntityGroupTypeId = null;
                this.fetchOwnerEntityGroupTypes();
            }
            if (!(this.getParameterObject("AgreementGroupId").Hidden) || !(this.getParameterObject("AgreementGroupIdList").Hidden)) {
                this.agreementGroupId = null;
                this.agreementGroupIdList = '';
                this.fetchAgreementGroups();
            }
            if (!(this.getParameterObject("AircraftId").Hidden)) {
                this.aircraftId = null;
                this.fetchAircrafts();
            }
            if (!(this.getParameterObject("KeyPersonId").Hidden)) {
                this.keyPersonId = null;
            }
            if (!(this.getParameterObject("VendorId").Hidden) || !(this.getParameterObject("VendorIdList").Hidden)) {
                this.fetchVendors();
            }
            if (!(this.getParameterObject("FundHoldingName").Hidden)) {
                this.fundHoldingNames = await this.asset_GetFundHoldingNames({
                    ClientId: this.clientId
                });
            }
            if (!(this.getParameterObject("FundId").Hidden) || !(this.getParameterObject("FundIdList").Hidden)) {
                this.fundIds = await this.asset_GetFundList({
                    ClientId: this.clientId
                    , IncludeDirect: true
                });
            }
            if (!(this.getParameterObject("AccountId").Hidden) || !(this.getParameterObject("AccountIdList").Hidden)) {
                await this.fetchBankAccounts();
            }
            if (!(this.getParameterObject("COAIdList").Hidden)) {
                this.fetchChartOfAccounts();
            }
            if (!(this.getParameterObject("DepartmentIdList").Hidden)) {
                this.fetchDepartmentList();
            }
            if (!(this.getParameterObject("LayoutGroupIdList").Hidden)) {
                this.fetchLayoutGroupList();
            }
            if (!(this.getParameterObject("LayoutGroupIdList").Hidden)) {
                this.fetchLayoutGroupList();
            }
            if (!(this.getParameterObject("AccountTypeId").Hidden)) {
                this.fetchAccountTypes();
            }
            if (!(this.getParameterObject("InvestmentParentId").Hidden)) {
                this.fetchInvestmentParents();
            }
            if (!(this.getParameterObject("InvoiceStatusForPaymentDashboard").Hidden)) {
                this.fetchInvoiceStatusForPaymentDashboard();
            }
        },
        fetchOwnerIdList: async function () {
            this.ownerIdList = [];
            this.loadingParameters = true;
            this.$setSystemStatus("Getting Owner List");
            if (this.reportConfigurationId != null) {
                const defaultOwners = await this.reports_GetDefaultOwners({
                    ConfigurationId: this.reportConfigurationId
                });
                this.ownerIdList = defaultOwners.map(function (item) {
                    return item.Id;
                });
            }
            else {
                this.ownerIdList = [];
            }
            this.loadingParameters = false;
            this.$setSystemStatus("");
        },
        fetchReports: async function () {
            this.$setSystemStatus("Getting Reports");
            this.reports = await this.reports_GetReports({
                GroupId: this.reportGroupId == "" ? null : this.reportGroupId
            }, true // async (optional)
            );
            this.$setSystemStatus(`Got ${this.reports.length} Reports`);
        },
        fetchReportParameters: async function () {
            this.loadingParameters = true;
            this.$setSystemStatus("Getting Parameters");
            this.parameters = await this.reports_GetReportParameters({
                ReportId: this.selectedReportRow.ReportId
            });
            this.loadingParameters = false;
            this.$setSystemStatus("");
        },
        fetchLiquidityLogIds: async function () {
            this.liquidityLogId = null;
            this.loadingParameters = true;
            this.gettingLiquidityLogIds = true;
            this.$setSystemStatus("Getting Liquidity Runs");
            const mdt = this.$dayjs(this.liquidityDate, "MM/DD/YYYY");
            const dt = mdt.format("MM/DD/YYYY");
            if (!mdt || !(mdt.isValid())) {
                this.$alert("Invalid Date", "Error");
                this.loadingParameters = false;
                this.gettingLiquidityLogIds = false;
                return;
            }
            const ds = await this.util_GetLogIds({
                Database: "visiumdb",
                Schema: "liq",
                Caller: "ProcessHistory",
                Event: "Exec",
                KeyName: "LiquidityDate",
                KeyDate: dt,
                CompletedOnly: true,
                MinMilliseconds: 5000,
                SuccessOnly: true
            });
            this.gettingLiquidityLogIds = false;
            this.liquidityLogIds = ds;
            if (ds.length == 0) {
                this.liquidityLogId = null;
                this.noLiquidityLogIdsFound = true;
            }
            else if (ds.length > 0) {
                this.noLiquidityLogIdsFound = false;
                this.liquidityLogId = ds[0].LogId;
            }
            this.gettingLiquidityLogIds = false;
            this.loadingParameters = false;
            this.$setSystemStatus("");
        },
        fetchBalanceSheetLogIds: async function () {
            this.balanceSheetLogId = null;
            this.gettingBalanceSheetLogIds = true;
            this.loadingParameters = true;
            this.$setSystemStatus("Getting Balance Sheet Runs");
            const mdt = this.$dayjs(this.balanceSheetAsOf, "MM/DD/YYYY");
            const dt = mdt.format("MM/DD/YYYY");
            if (!mdt || !(mdt.isValid())) {
                this.$alert("Invalid Date", "Error");
                this.loadingParameters = false;
                this.gettingBalanceSheetLogIds = false;
                return;
            }
            const ds = await this.util_GetLogIds({
                Database: "visium",
                Schema: "BalanceSheet",
                Caller: "ProcessHistory",
                Event: "Exec",
                KeyName: "AsOf",
                KeyDate: dt,
                CompletedOnly: true,
                MinMilliseconds: 5000,
                SuccessOnly: true
            });
            this.balanceSheetLogIds = ds;
            if (ds.length == 0 || ds.filter(logs => !logs.Proforma).length == 0) {
                this.balanceSheetLogId = null;
                this.noBalanceSheetsLogIdsFound = true;
            }
            if (ds.length > 0) {
                //this.balanceSheetLogId = ds[0].LogId;
                this.balanceSheetLogId = ds.filter(logs => !logs.Proforma)[0].LogId;
                this.noBalanceSheetsLogIdsFound = false;
            }
            this.gettingBalanceSheetLogIds = true;
            this.$setSystemStatus("");
            this.loadingParameters = false;
        },
        selectReport: function (selectedRow) {
            this.selectedReportRow = {};
            if (selectedRow && selectedRow != null && !(selectedRow.Disabled && !selectedRow.IsMaintainer)) {
                this.selectedReportRow = selectedRow;
            }
        },
        fetchBankAccounts: async function () {
            this.loadingParameters = true;
            this.$setSystemStatus("Getting Bank Accounts");
            this.bankAccounts = await this.liq_GetBankAccountList({
                ClientId: this.clientId
                //, OrderBy: orderBy
                //, AccountType: accountType
                //, AccountId: accountId
                ,
                OwnerId: this.ownerId
                //, COAId: cOAId
            });
            this.loadingParameters = false;
            this.$setSystemStatus("");
        },
        fetchChartOfAccounts: async function () {
            this.loadingParameters = true;
            this.$setSystemStatus("Getting Chart of Accounts");
            await this.$nextTick();
            if (this.clientId != null) {
                // this.coAList = this.vam_GetChartOfAccountsForTree(this.clientId, null, null);
                this.coAList = await this.vam_GetChartOfAccountsForTree({
                    ClientId: this.clientId
                }, true // async (optional)
                );
            }
            else {
                this.coAList = [];
            }
            this.loadingParameters = false;
            this.$setSystemStatus("");
        },
        fetchDepartmentList: async function () {
            if (this.departmentList != null) {
                this.departmentList = await this.vam_GetDepartments({
                    ClientId: this.clientId
                });
            }
            else {
                this.departmentList = [];
            }
        },
        fetchAccountTypes: async function () {
            this.gettingAccountTypes = true;
            this.$setSystemStatus("Getting Account Types");
            this.accountTypes = await this.asset_GetAccountTypes({
                ClientId: this.clientId
            });
            this.noAccountTypesFound = !this.accountTypes.length;
            this.gettingAccountTypes = false;
            this.$setSystemStatus("");
        },
        fetchInvestmentParents: async function () {
            this.gettingInvestmentParents = true;
            this.$setSystemStatus("Getting Investment Parents");
            this.investmentParents = await this.asset_GetInvestmentParentList({
                ClientIdList: this.clientId
            });
            this.gettingInvestmentParents = false;
            this.$setSystemStatus("");
        },
        fetchReportConfigurations: async function () {
            if (!!this.selectedReportRow && !!this.clientId && !!this.getParameterObject("ConfigurationTypeId").DefaultValue) {
                this.loadingParameters = true;
                this.$setSystemStatus("Getting Configurations");
                this.reportConfigurationId = null;
                // this.reportConfigurations = this.reports_GetConfigurations(this.clientId, await this.getParameterObject('ConfigurationTypeId').DefaultValue);
                this.reportConfigurations = await this.reports_GetConfigurations({
                    ClientId: this.clientId,
                    ConfigurationTypeId: await this.getParameterObject("ConfigurationTypeId").DefaultValue
                });
                if (this.reportConfigurations.length > 0) { //select first automatically
                    this.reportConfigurationId = this.reportConfigurations[0].Id;
                }
                this.$setSystemStatus("");
                this.loadingParameters = false;
            }
        },
        fetchOwnerEntityGroupTypes: async function () {
            if (this.clientId != null) {
                this.loadingParameters = true;
                this.$setSystemStatus("Getting Owner Entity Group Types");
                this.ownerEntityGroupTypes = await this.reports_GetOwnerEntityGroupTypes({
                    ClientId: this.clientId
                });
                this.loadingParameters = false;
                this.$setSystemStatus("");
            }
        },
        fetchLayoutGroupList: async function () {
            this.loadingParameters = true;
            this.$setSystemStatus("Getting Layout Hierarchy");
            this.layoutGroupList = await this.liq_GetLayoutHierarchy({
                LayoutId: await this.getParameterObject("LayoutId").DefaultValue,
                OutermostLeafOnly: true
            });
            this.$setSystemStatus("");
            this.loadingParameters = false;
        },
        fetchAgreementGroups: async function () {
            this.loadingParameters = true;
            this.$setSystemStatus("Getting Agreement Groups");
            this.agreementGroupId = null;
            this.agreementGroupIdList = [];
            this.agreementGroups = await this.liq_GetAgreementGroups({
                ClientId: this.clientId,
                AggregateUnitsOnly: await this.getParameterObject("AggregateUnitsOnly").DefaultValue
            });
            if (this.agreementGroups.length > 0) { //select first automatically
                this.agreementGroupId = this.agreementGroups[0].AgreementGroupId;
            }
            this.$setSystemStatus("");
            this.loadingParameters = false;
        },
        fetchAircrafts: async function () {
            this.loadingParameters = true;
            this.$setSystemStatus("Getting Aircraft");
            this.aircraftId = null;
            this.aircrafts = await this.av_GetAircraft({
                ClientId: this.clientId,
                FullyOwnedOnly: await this.getParameterObject("FullyOwnedOnly").DefaultValue || false
            });
            if (this.aircrafts.length == 1) {
                this.aircraftId = this.aircrafts[0].AircraftId;
            }
            this.loadingParameters = false;
            this.$setSystemStatus("");
        },
        RunReport: function () {
            this.showReport = false;
            var parameters = {};
            this.$setSystemStatus("Building Parameters");
            for (var i = 0, len = this.parameters.length; i < len; i++) {
                var newParm = this.parameters[i].Field;
                if (this.parameters[i].Type == "DateRange") {
                    parameters[newParm.replace("Range", "") + "RangeStart"] = (this.$data[this.parameters[i].Parameter] == null) ? null : this.$dayjs(this.$data[this.parameters[i].Parameter][0]).format("MM/DD/YYYY"); //use moment .format to strip away TZ
                    parameters[newParm.replace("Range", "") + "RangeEnd"] = (this.$data[this.parameters[i].Parameter] == null) ? null : this.$dayjs(this.$data[this.parameters[i].Parameter][1]).format("MM/DD/YYYY");
                }
                else if (this.parameters[i].Type == "MonthPicker") {
                    parameters["Year"] = this.$dayjs(this.monthPicker).year();
                    parameters["Month"] = this.$dayjs(this.monthPicker).month() + 1;
                }
                else if (this.parameters[i].Type == "SelectMultiple" || this.parameters[i].Type == "Transfer") {
                    parameters[newParm] = (this.$data[this.parameters[i].Parameter] == null) ? null : this.$data[this.parameters[i].Parameter].join(",");
                }
                else if (this.parameters[i].Type == "3Button") {
                    parameters[newParm] = (this.$data[this.parameters[i].Parameter] == "" && typeof this.$data[this.parameters[i].Parameter] == "string") ? null : this.$data[this.parameters[i].Parameter];
                }
                else if (this.parameters[i].Type == "Switch" && this.parameters[i].Required && this.$data[this.parameters[i].Parameter] == null) {
                    parameters[newParm] = false;
                }
                else if (this.parameters[i].Hidden) {
                    parameters[newParm] = this.parameters[i].DefaultValue;
                }
                else { //Select, Switch
                    parameters[newParm] = this.$data[this.parameters[i].Parameter];
                }
            }
            this.Reports_LogExecution({
                ReportId: this.selectedReportRow.ReportId,
                Parameters: JSON.stringify(parameters)
            });
            this.report = this.selectedReportRow.FileName;
            this.reportParameters = parameters;
            // if (this.openInNewTab) {
            //     console.log('ToDo routerlink');
            // }
            // else{
            //     this.showReport = true;
            // }
            var report = this.selectedReportRow.FileName;
            var reportDiv = document.querySelector("#ucReportViewer");
            if (this.openInNewTab) {
                const url = `/fs/smartControl/ReportViewer/ReportViewer?params=${encodeURIComponent(JSON.stringify({
                    HideParameters: true,
                    ParametersAreURLEncoded: false,
                    Report: report,
                    Parameters: parameters
                }))}`;
                this.$setSystemStatus("Opened Report in new window");
                var win = window.open(url, "_blank");
                win.focus();
            }
            else {
                this.LoadReport(reportDiv, report, parameters);
            }
        },
        async LoadReport(reportDiv, report, parameters) {
            this.activeNames = ["report"];
            var div = $("#" + reportDiv.id);
            div.parent().css("height", getAvailableScreenHeight(121));
            div.css("height", "100%");
            div.css("width", "100%");
            this.$setSystemStatus("Loading Report Viewer");
            await div.fetchSmartControl({
                controlUrl: "ReportViewer/ReportViewer.html",
                controlName: "ReportViewer",
                params: {
                    Parameters: parameters,
                    Report: report,
                    HideParameters: true
                },
                height: getAvailableScreenHeightInPixels() - 170
                //, maxHeight: '95%'
                ,
                width: "100%",
                loadAsync: true
                //, maxWidth: '95%'
                ,
                onLoad: function () {
                    div.find("#divReportViewer").css("height", "100%");
                }
            });
            this.$setSystemStatus("");
        },
        filterMethodOwners: function (query, item) {
            return item.ShortName.toLowerCase().indexOf(query.toLowerCase()) > -1;
        },
        filterMethodCoA: function (query, item) {
            return item.NamePath.toLowerCase().indexOf(query.toLowerCase()) > -1;
        },
        filterMethodDepartment: function (query, item) {
            return item.Name.toLowerCase().indexOf(query.toLowerCase()) > -1;
        },
        filterMethodVendor: function (query, item) {
            return item.Name.toLowerCase().indexOf(query.toLowerCase()) > -1;
        },
        filterMethodFund: function (query, item) {
            return item.Name.toLowerCase().indexOf(query.toLowerCase()) > -1;
        },
        filterMethodLayoutGroup: function (query, item) {
            return item.NamePath.toLowerCase().indexOf(query.toLowerCase()) > -1;
        },
        filterMethodAgreementGroup: function (query, item) {
            return item.Label.toLowerCase().indexOf(query.toLowerCase()) > -1;
        },
        renderOwnerItem(h, option) {
            return h("span", {
                style: {
                    fontSize: "7pt"
                }
            }, option.ShortName);
        },
        renderCoAItem(h, option) {
            return h("span", {
                style: {
                    fontSize: "7pt"
                }
            }, option.NamePath);
        },
        renderDepartmentItem(h, option) {
            return h("span", {
                style: {
                    fontSize: "7pt"
                }
            }, option.Name);
        },
        renderVendorItem(h, option) {
            return h("span", {
                style: {
                    fontSize: "7pt"
                }
            }, option.Name);
        },
        renderFundItem(h, option) {
            return h("span", {
                style: {
                    fontSize: "7pt"
                }
            }, option.Name);
        },
        renderLayoutGroupItem(h, option) {
            return h("span", {
                style: {
                    fontSize: "7pt"
                }
            }, option.NamePath);
        },
        renderAgreementGroupItem(h, option) {
            return h("span", {
                style: {
                    fontSize: "7pt"
                }
            }, option.Label);
        },
        fetchVendors: async function () {
            this.gettingVendors = true;
            this.$setSystemStatus("Getting Vendors");
            this.vendors = await this.vam_GetVendors({
                ClientId: this.clientId
            });
            this.gettingVendors = false;
            this.$setSystemStatus("");
        },
        async fetchInvestmentTypes() {
            this.gettingInvestmentTypes = true;
            this.$setSystemStatus("Getting Investment Types");
            this.investmentTypes = await this.asset_GetInvestmentTypes();
            this.gettingInvestmentTypes = false;
            this.$setSystemStatus("");
        },
        async fetchValuableCategories() {
            this.valuablesCategories = await this.asset_GetValuablesCategories();
        },
        async fetchInvoiceStatusForPaymentDashboard() {
            this.invoiceStatusForPaymentDashboardList = await this.liq_GetInvoiceStatusForPaymentDashboard();
        },
        clientSingleOptionAvailable(selectedClientId: number){
            this.clientIsSingleOption = true;
        }, 

        getAdditionalSettings(parameterObject, setting): number | string {
            if (parameterObject && parameterObject.AdditionalSettingsJSON){
                const settings = JSON.parse(parameterObject.AdditionalSettingsJSON);
                return settings[setting];
            }
            
        },
        getParameterObject(controlName): iParameterObject {
            var ret = this.parameters.find(function (parm) {
                return parm.ControlName == controlName;
            });
            if (ret == undefined || ret.length == 0) {
                return {
                    Prompt: null,
                    Placeholder: null,
                    Hidden: true,
                    Required: false,
                    Explanation: null
                };
            }
            else {
                //for certain fields, fill data sets if not yet filled.
                if (ret.ControlName == "LiquidityLogId" && !ret.Hidden && this.liquidityLogIds.length == 0 && !this.noLiquidityLogIdsFound && this.liquidityDate != null && !this.gettingLiquidityLogIds) {
                    this.fetchLiquidityLogIds();
                }
                if (ret.Field == "BalanceSheetLogId" && !ret.Hidden && this.balanceSheetLogIds.length == 0 && !this.noBalanceSheetsLogIdsFound && this.balanceSheetAsOf != null && !this.gettingBalanceSheetLogIds) {
                    this.fetchBalanceSheetLogIds();
                }
                if (ret.Field == "AccountTypeId" && !ret.Hidden && this.accountTypes.length == 0 && !this.gettingAccountTypes && !this.noAccountTypesFound) {
                    this.fetchAccountTypes();
                }
                if (ret.Field == "InvestmentParentId" && !ret.Hidden && this.investmentParents.length == 0 && !this.gettingInvestmentParents) {
                    this.fetchInvestmentParents();
                }
                if ((ret.Field == "VendorId" || ret.Field == "VendorIdList") && !ret.Hidden && this.vendors.length == 0 && !this.gettingVendors) {
                    this.fetchVendors();
                }
                if ((ret.Field == "InvestmentTypeIdList") && !ret.Hidden && this.investmentTypes.length == 0 && !this.gettingInvestmentTypes) {
                    this.fetchInvestmentTypes();
                }
                if (ret.Field == "ValuablesCategoryId" && !ret.Hidden && this.valuablesCategories.length == 0) {
                    this.fetchValuableCategories();
                }
                if (ret.Field == "ActivitySources" && !ret.Hidden && this.activitySourcesOptions.length == 0 && !this.gettingActivitySources) {
                    this.$setSystemStatus("Getting Activity Sources");
                    this.gettingActivitySources = true;
                    const el = this;
                    this.activitySourcesOptions = this.reports_GetActivitySources({}, null, null, false);
                    el.gettingActivitySources = false;
                    el.$setSystemStatus("");
                }
                //set default value, if present:
                if (ret.DefaultValue != undefined && ret.DefaultValue != null) {
                    if (!this.$data[ret.Parameter + "DefaultSet"]) {
                        if (ret.Type == "Switch") {
                            this.$data[ret.Parameter] = tryParseBool(ret.DefaultValue);
                        }
                        else {
                            this.$data[ret.Parameter] = ret.DefaultValue;
                        }
                        this.$data[ret.Parameter + "DefaultSet"] = true;
                    }
                }
                //finally, return the parameter object:
                return ret;
            }
        }
    },
})
