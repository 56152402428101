
    import { Component, Vue, Prop, Watch } from "vue-property-decorator";
    import * as TransactionsService from '@/services/DAL/transactionsService';
    import * as AssetService from '@/services/DAL/assetService';
    import * as LiqService from '@/services/DAL/liq_Service';
    import ClientSelector from "@/components/form/ClientSelector.vue";
    import OwnerSelector from "@/components/form/OwnerSelector.vue";
    import CharitableOrganizationSelector from "@/components/form/CharitableOrganizationSelector.vue";
    import InvestmentTransactionTypeSelector from '@/components/form/InvestmentTransactionTypeV2Selector.vue';
    import TransactionSubTypeSelector from '@/components/form/TransactionSubTypeSelector.vue'
    import formatters from '@/utils/formatters';
    import FormattedInput from '@/components/form/FormattedInput.vue';
    import ElementUI from 'element-ui';
    import cloneDeep from 'lodash/cloneDeep';
    import * as SystemService from '@/services/DAL/systemService';
    import Common from '@/utils/common';
    import VueMarkdown from '@adapttive/vue-markdown'
import { number } from "mathjs";
import { Direction } from "element-ui/types/drawer";

    declare function getStoredSecurityLevel(Id: number): number;
    declare function tryParseInt(input: any, defaultValue: number): number;
    declare var SmartObject: any;

    declare class IquickContribution{
        message: string;
        cashType: string;
        ok: boolean;
        type: string;
    }

    @Component({
        components: {
            InvestmentTransactionTypeSelector,
            ClientSelector,
            OwnerSelector,
            FormattedInput,
            TransactionSubTypeSelector,
            CharitableOrganizationSelector,
            //@ts-ignore
            VueMarkdown
        }
    })
    export default class TransactionHeader extends Vue {
    $refs: {
        frmTransactionHeader: ElementUI.Form
    }
    //#region Private declarations for Services
    private _transactionsService: TransactionsService.TransactionsService;
    private _assetService: AssetService.AssetService;
    private _liqService: LiqService.LiqService;
    private common: Common;
    public formatters: formatters;
    //#endregion Private declarations for Services

    //#region Props
    @Prop({ type: Number, default: null, required: true }) securityLevel: number;
    @Prop({ type: Object }) header: TransactionsService.Header;
    @Prop({ type: String, default: 'abc' }) pinnedDocumentPath: string;
    @Prop( {type: Number }) readonly newTransactionInvestmentId: number;

    //#endregion Props

    //#region Data
    header_ = {} as TransactionsService.Header;
    loading = false;
    isNew = false;
    wasNew = false; // keeps Transaction Type available after selecting Legacy Associated Transaction and saving
    securityLevel_: number = null;
    transactionType = {} as TransactionsService.Types;
    subTypes = [] as TransactionsService.SubType[];
    legacyAssociated = [] as TransactionsService.LegacyAssociated[];
    showPreviewDeleteTransaction = false;
    previewHeaderDelete = [] as TransactionsService.PreviewHeaderDelete[];
    legacyTransactionType: string = null;
    investment = {} as AssetService.InvestmentList;
    showMarkdown = false;
    doquickContribution: boolean = false;
    bankAccountStatistics = {} as LiqService.BankAccountStatistics;
    isCapitalCall = false;
    investmentsForHeader = [] as TransactionsService.InvestmentsForHeader[];
    localIsCharitable = false;
    
    //#endregion Data

    //#region Lifecycle
    async created() {
        this._transactionsService = new TransactionsService.TransactionsService();
        this._assetService = new AssetService.AssetService();
        this._liqService = new LiqService.LiqService();

        this.formatters = new formatters();
        this.common = new Common();
        if (!this.securityLevel_) {
            this.securityLevel_ = tryParseInt(
                getStoredSecurityLevel(this.$namedKey.SecurityView.ManageAssets),
                0
            );
        }
        if (this.header && Object.keys(this.header).length){
            this.header_ = cloneDeep(this.header);
            if (this.header_.TargetCharitableEIN && this.header_.TargetCharitableEIN.length) this.localIsCharitable = true;
            this.showMarkdown = true;
        }
        else {
            this.header_ = new TransactionsService.Header();
            this.header_.TypeId = null;
            this.header_.ClientId = null;
            this.isNew = true;
        }
        await this.checkNewInvestment();
        this.fetchLegacyAssociated();
        this.refreshAssociatedInvestments();
    }
    //#endregion Lifecycle

    //#region Watches
    @Watch('header')
    async headerChanged(val: object, oldVal: object) {
        this.header_ = cloneDeep(this.header);
        this.isNew = !(this.header_.Id > 0);
        if (this.header_.TargetCharitableEIN && this.header_.TargetCharitableEIN.length) this.localIsCharitable = true;
        this.showMarkdown = !this.isNew;
        this.fetchLegacyAssociated();
    }
    @Watch('header_.BaseOwnerId')
    async baseOwnerIdChanged(val: number, oldVal: number) {
        if ((this.transactionType.IsStakeTransaction || this.transactionType.IsShareTransaction) && (this.selectedSubType && this.selectedSubType.Scope == 'Investment' && this.selectedSubType.SubOperation != 'InterOwner')){
            this.header_.TargetOwnerId = val;
        }
    }
    @Watch('header_.SubTypeId')
    async subTypeIdChanged(val: number, oldVal: number) {
        if ((this.transactionType.IsStakeTransaction || this.transactionType.IsShareTransaction) && (this.selectedSubType && this.selectedSubType.Scope == 'Investment' && this.selectedSubType.SubOperation != 'InterOwner')){
            this.header_.TargetOwnerId = this.header_.BaseOwnerId;
        }
    }

    @Watch('showquickContribution')
    async showquickContributionChanged(val: boolean, oldVal: boolean) {
        if (val && !(this.quickContribution && this.quickContribution.ok)) return; // don't change doquickContribution to true if it's not "Ok"
        this.doquickContribution = val;
    }
    @Watch('quickContribution.ok')
    async quickContributionChanged(val: boolean, oldVal: boolean) {
        if (!val) this.doquickContribution = val;
        else this.doquickContribution = this.showquickContribution;
    }
    @Watch('localIsCharitable')
    async localIsCharitableChanged(val: boolean, oldVal: boolean) {
        if (oldVal && val == false){ //from true to false
            this.header_.TargetCharitableEIN = null;
        }
    }
    
    //#endregion Watches

    //#region Computed
    get showquickContribution(): boolean {
        return ((this.isNew || this.wasNew) && !!this.transactionType && !!this.transactionType.IsContribution && !!this.investment && !!this.investment.BankAccountId && !!this.header_ && !!this.header_.NetAmount);
    }
    get quickContribution(): IquickContribution {
        let type: string;
        if (this.investment && this.investment.HasCommitment){
            type = 'Capital Call';
            this.isCapitalCall = true;
        }
        else{
            type = 'Contribution';
        }
        if (this.bankAccountStatistics && this.bankAccountStatistics.BankAccountId && this.header_ && this.header_.Date){
            if(this.$dayjs(this.header_.Date).isBetween(this.bankAccountStatistics.Earliest, this.bankAccountStatistics.Latest)){
                return {
                    message: `Quick Contribution cannot be used.  Transaction Date ${this.$dayjs(this.header_.Date).format("MM/DD/YY")} is within the date range of the default bank account, 
                        ${this.$dayjs(this.bankAccountStatistics.Earliest).format("MM/DD/YY")} and ${this.$dayjs(this.bankAccountStatistics.Latest).format("MM/DD/YY")}, so the bank transaction must be matched.`
                    , cashType: 'Match'
                    , ok: false
                    , type: type
                }
            }
            else if (this.$dayjs(this.header_.Date).isBefore(this.bankAccountStatistics.Earliest)){
                return {
                    message: `Transaction Date ${this.$dayjs(this.header_.Date).format("MM/DD/YY")} is prior to the date range of the default bank account, 
                        ${this.$dayjs(this.bankAccountStatistics.Earliest).format("MM/DD/YY")} and ${this.$dayjs(this.bankAccountStatistics.Latest).format("MM/DD/YY")}.`
                    , cashType: 'Historical'
                    , ok: true
                    , type: type
                }
            }
            else if (this.$dayjs(this.header_.Date).isAfter(this.bankAccountStatistics.Latest)){
                return {
                    message: `Transaction Date ${this.$dayjs(this.header_.Date).format("MM/DD/YY")} is after the date range of the default bank account, 
                        ${this.$dayjs(this.bankAccountStatistics.Earliest).format("MM/DD/YY")} and ${this.$dayjs(this.bankAccountStatistics.Latest).format("MM/DD/YY")}.`
                    , cashType: 'Expected'
                    , ok: true
                    , type: type
                }
            }
        }
    }
    get isFormDirty(): boolean {
        return Object.keys((this as any).veeFields).some(
            key => (this as any).veeFields[key].dirty
        );
    }
    get isFormValid(): boolean{
        return !(Object.keys((this as any).veeFields).some(
            key => (this as any).veeFields[key].invalid
        ));
    }
    get preventDelete(): boolean {
        if (this.previewHeaderDelete && this.previewHeaderDelete.length){
            return this.previewHeaderDelete.some(record => record.PreventDelete)
        }
        else return false;
    }
    get showNetAmount(): boolean { 
        //if (this.selectedSubType && this.selectedSubType.AdditionalData2 == 'Sell') return true;
        return this.transactionType.ShowNetAmount; 
    }
    get showSubTypeSelector(): boolean { return !!this.transactionType && this.transactionType.HasSubTypes; }

    get selectedSubType(): TransactionsService.SubType{
        if (this.header_.SubTypeId && this.subTypes.length){
            return this.common.getSelectedArrayItem(
                        this.subTypes,
                        this.header_.SubTypeId.toString(),
                        'Id'
                    );
        }
    }

    get showToOwner(): boolean {
        return (
            (this.transactionType.IsStakeTransaction || this.transactionType.IsShareTransaction) 
            && (
                    (this.selectedSubType && this.selectedSubType.Scope == 'Owner') //scope
                    ||
                    (this.selectedSubType && this.selectedSubType.SubOperation == 'InterOwner' && this.selectedSubType.Scope != 'Self') // operation (exclude Interowner - Self [split/exchange])
            )
        )
        || (this.transactionType && this.selectedSubType && this.transactionType.IsDebtTransaction && this.selectedSubType.Operation == 'Transfer')
    }
    //#endregion Computed


    //#region Methods
    async checkNewInvestment(): Promise<void> {
        if (this.newTransactionInvestmentId && (this.isNew || this.wasNew)){
            const params = {} as AssetService.GetInvestmentListParameters;
            params.InvestmentId = this.newTransactionInvestmentId;
            const investments = await this._assetService.GetInvestmentList(params);
            if (investments && investments.length == 1){
                this.investment = investments[0];
                if (!this.header_.BaseOwnerId) this.header_.BaseOwnerId = this.investment.OwnerId;
                if (!this.header_.BaseInvestmentId) this.header_.BaseInvestmentId = this.investment.Id;
                if (!this.header_.ClientId) this.header_.ClientId = this.investment.ClientId;
                if (this.transactionType.IsCapitalCall){ //probably don't know at this point
                    await this.fetchBankAccountStatistics();
                }
            }
        }

    }
    async fetchBankAccountStatistics(): Promise<void> {
        this.bankAccountStatistics = {} as LiqService.BankAccountStatistics;
        if (this.investment && this.investment.BankAccountId){
            const params = {} as LiqService.GetBankAccountStatisticsParameters;
            params.BankAccountId = this.investment.BankAccountId;
            const stats = await this._liqService.GetBankAccountStatistics(params);
            if (stats.length == 1){
                this.bankAccountStatistics = stats[0];
            }
        }
    }
    async fetchLegacyAssociated(): Promise<void> {
        if (this.header_.Id){
            const parms = {} as TransactionsService.GetLegacyAssociatedParameters;
            parms.TransactionHeaderId = this.header_.Id
            this.legacyAssociated = await this._transactionsService.GetLegacyAssociated(parms);
        }
    }
    async createHeader(): Promise<void> {
        this.loading = true;
        const id = await new SmartObject('InvestmentTransactionHeader').createObject(
                    this.header_
                );
                this.header_.Id = id;
                this.loading = false;
                this.$notify.success('New Transaction Header Added');
    }
    async getHeader(id: number): Promise<void> {
        const params = {} as TransactionsService.GetHeaderParameters;
        params.HeaderId = id;
        const headers = await this._transactionsService.GetHeader(params);
        if (headers && headers.length == 1){
            this.header_ = headers[0];
            if (this.header_.TargetCharitableEIN && this.header_.TargetCharitableEIN.length) this.localIsCharitable = true;
        }
    }
    async continueFromHeader(emitSteps: boolean = true): Promise<void> {
        const showMarkdown = this.showMarkdown;
        if (showMarkdown){
            this.showMarkdown = false;
            await this.$nextTick();
        }
        if (this.isFormValid && this.isFormDirty) {
            if (!this.showNetAmount) this.header_.NetAmount = null; // it may have been set initially by the LegacyAssociated, before we knew if showNetAmount. Clear it if set.
            if (!this.showSubTypeSelector) this.header_.SubTypeId = null; // when Type is changed to a type without subtypes, clear the subtype

            if (this.isNew){
                this.wasNew = true;
                await this.createHeader();
                this.isNew = false;
            }
            else{
                this.loading = true;
                await new SmartObject('InvestmentTransactionHeader', this.header_.Id).updateObject(
                    this.header_
                );
                this.loading = false;
                this.$notify.success('Transaction Header Updated');
            }
            let quickSuccess: boolean = false;
            if (this.doquickContribution){                
                this.loading = true;
                const params = {} as TransactionsService.QuickCapitalContributionParameters;
                params.TransactionHeaderId = this.header_.Id;
                params.IsCapitalCall = this.isCapitalCall;
                const resps = await this._transactionsService.QuickCapitalContribution(params);
                this.loading = false;
                if (resps && resps.length == 1){
                    const resp = resps[0];
                    quickSuccess = resp.Succcess;
                    this.$notify.success(resp.Message);
                }
                if (!quickSuccess){
                    this.$message.warning('Unable to create cash and capital records automatically. Try creating them with the regular process.');
                }

            }
            await this.getHeader(this.header_.Id); // get it fresh from db so it has all columns
            this.$validator.reset(); //notes field was remaining dirty, probably because it was hidden
            this.$emit('saved', this.header_, this.transactionType, this.selectedSubType);
            if (emitSteps) this.$emit('increment-step', quickSuccess);
        } else if (!this.isFormValid) {
            this.$message.error('Not Saved.  See Validation Issues.');
            //return false;
        } else { // Valid but not dirty, so continue
            if (emitSteps) this.$emit('increment-step');
        }
        this.showMarkdown = showMarkdown;
    }
    async saveHeader(): Promise<void> {
        await this.continueFromHeader(false);
    }
    async transactionTypeSet(transactionType: TransactionsService.Types): Promise<void> {
        this.transactionType = transactionType;
        await this.$nextTick();
        await this.fetchBankAccountStatistics()
    }
    async refreshAssociatedInvestments(): Promise<void> {
        if (this.header_ && this.header_.Id){
            const parms = {} as TransactionsService.GetInvestmentsForHeaderParameters;
            parms.TransactionHeaderId = this.header_.Id;
            this.investmentsForHeader = await this._transactionsService.GetInvestmentsForHeader(parms);
        }
        else {
            this.investmentsForHeader = [] as TransactionsService.InvestmentsForHeader[];
        }
    }
    //#endregion Methods
    async deleteLegacyAssociated(legacyTransactionId: number): Promise<void> {
              try {
        await this.$confirm(
          `This will permanently delete Association for Legacy Transaction ${legacyTransactionId}. Continue?`,
          'Warning',
          {
            confirmButtonText: 'OK',
            cancelButtonText: 'Cancel',
            type: 'warning'
          }
        );

        try {
          await new SmartObject('TransactionsLegacyAssociation').deleteFromCompositeKey({
              TransactionHeaderId: this.header_.Id
              , LegacyTransactionId: legacyTransactionId
          });
          this.fetchLegacyAssociated();
          this.$notify.success('Association deleted.');
        } catch {
          this.$notify.error('Something went wrong processing your request, please try again.');
        }
      } catch {} // canceled
    }
    async processLegacyAssociated(legacyTransactionId: number, initialNumberOfItems: number): Promise<void> {
        let simpleType: string;
        if (!legacyTransactionId) {
            console.log('invalid LegacyTransactionId', legacyTransactionId);
            return;
        }
        let la = number;
        try {
            la = await new SmartObject('TransactionsLegacyAssociation').createObject({
                TransactionHeaderId: this.header_.Id
                , LegacyTransactionId: legacyTransactionId
            });
        }
        catch(e) {
            const errJson = JSON.parse(e.responseText);
            if (errJson.ExceptionMessage.toLowerCase().indexOf('foreign key') > -1) {
                this.$message.error(`${legacyTransactionId} is not a valid Legacy Transaction Id.`);
            }
            if (errJson.ExceptionMessage.toLowerCase().indexOf('primary key') > -1) {
                this.$message.error(`${legacyTransactionId} has already been added.`);
            }
            else {
                this.$message.error(errJson.ExceptionMessage);
            }
        }
        if (la && this.wasNew && (this.header_.Date == null || this.header_.ClientId == null || this.header_.TypeId == null || this.header_.NetAmount == null || this.header_.Description == null)){
            const soTransaction = new SmartObject('Transaction', legacyTransactionId);
            const viewProperties = await soTransaction.invokeMethod('GetViewData') || {};
            console.log(viewProperties);
            if (this.header_.TypeId == null) {
                if (viewProperties.TransactionTypeId == 1 || viewProperties.TransactionTypeId == 2){ // Cap Commitment, Call
                    simpleType = (viewProperties.TransactionType as string).replace('PE: ', '');
                    this.header_.TypeId = viewProperties.TransactionTypeId;
                    this.$validator.fields.find({name: 'TransactionType'}).flags.dirty = true;
                }
                else if (viewProperties.TransactionTypeId == 3 && (this.investment.InvestmentType == 'PIP' || this.investment.InvestmentType == 'Private Equity' || this.investment.InvestmentType == 'Hedge Fund' || this.investment.InvestmentType == 'Hedge Fund w/ Committment') ){ // Purch
                    simpleType = 'Capital Call';
                    this.header_.TypeId = 2; //Contribution/Call
                    this.$validator.fields.find({name: 'TransactionType'}).flags.dirty = true;

                }
                else if (viewProperties.TransactionTypeId == 10 // In Kind Dist
                        || (viewProperties.TransactionTypeId == 6 //Return
                                && this.investment && (this.investment.InvestmentType == 'PIP' || this.investment.InvestmentType == 'Private Equity' || this.investment.InvestmentType == 'Hedge Fund' || this.investment.InvestmentType == 'Hedge Fund w/ Committment'))
                        ){
                    simpleType = 'Distribution';
                    this.header_.TypeId = 12; //Dist
                    this.$validator.fields.find({name: 'TransactionType'}).flags.dirty = true;
                }
                else if (
                            (viewProperties.TransactionTypeId == 4 || viewProperties.TransactionTypeId == 7 || viewProperties.TransactionTypeId == 6) // Split/Exchange, Transfer/IPO, Return
                            && this.investment && (this.investment.InvestmentType == 'Single Company Investment' || this.investment.InvestmentType == 'Commodity' || this.investment.InvestmentType == 'Exchange Traded Fund')
                        ){
                    this.header_.TypeId = 14; //EquityTransaction
                    this.$validator.fields.find({name: 'TransactionType'}).flags.dirty = true;
                    await this.$nextTick();
                    if (viewProperties.TransactionTypeId == 4) {
                        simpleType = 'Split/Exchange';
                        this.header_.SubTypeId = 60;
                        this.$validator.fields.find({name: 'SubType'}).flags.dirty = true;
                    }
                    else if (viewProperties.TransactionTypeId == 6 && viewProperties.IncomeType == 'Dividend') {
                        simpleType = 'Dividend';
                        this.header_.TypeId = 16; // change from above; no Sub Type
                    }
                    else if (viewProperties.TransactionTypeId == 6 && viewProperties.IncomeType == 'Escrow') {
                        simpleType = 'Escrow';
                        this.header_.TypeId = 17; // change from above; no Sub Type
                    }
                    else if (viewProperties.TransactionTypeId == 6) {
                        simpleType = 'Share Sale';
                        this.header_.SubTypeId = 40;
                        this.$validator.fields.find({name: 'SubType'}).flags.dirty = true;
                    }
                }
                else if (
                            (viewProperties.TransactionTypeId == 4 || viewProperties.TransactionTypeId == 7) // Split/Exchange, Transfer/IPO
                            && this.investment && (this.investment.InvestmentType == 'PIP' || this.investment.InvestmentType == 'Private Equity' || this.investment.InvestmentType == 'Hedge Fund' || this.investment.InvestmentType == 'Hedge Fund w/ Committment')
                        ){
                    this.header_.TypeId = 13; //StakeTransaction
                    this.header_.SubTypeId = 30; //Transfer between Owners
                    simpleType = 'Stake Transfer between owners';
                    this.$validator.fields.find({name: 'TransactionType'}).flags.dirty = true;
                    this.$validator.fields.find({name: 'SubType'}).flags.dirty = true;
                }
                else {
                    this.legacyTransactionType = viewProperties.TransactionType;
                }
            }
            await this.$nextTick();
            if (this.header_.ClientId == null) {
                this.header_.ClientId = viewProperties.ClientId;
                if (this.$validator.fields.find({name: 'Client'})){
                    this.$validator.fields.find({name: 'Client'}).flags.dirty = true;
                }
            }
            await this.$nextTick();
            if (this.header_.Date == null) {
                this.header_.Date = viewProperties.TransactionDate;
                if (this.$validator.fields.find({name: 'TransactionDate'})){
                    this.$validator.fields.find({name: 'TransactionDate'}).flags.dirty = true;
                }
            }
            await this.$nextTick();
            if (this.header_.NetAmount == null && !(this.header_.TypeId == 12 && initialNumberOfItems > 1)) { // don't set Net Amount when Distribution and more than 1 legacy item because the Net Amount should be the sum of the items
                this.header_.NetAmount = viewProperties.Amount;
                if (this.$validator.fields.find({name: 'NetAmount'})){
                    this.$validator.fields.find({name: 'NetAmount'}).flags.dirty = true;
                }
            }
            await this.$nextTick();
            if (simpleType == 'Share Sale' && viewProperties.Amount && viewProperties.Amount && viewProperties.OwnerShortestName && viewProperties.Shares && viewProperties.ShareValue && (viewProperties.Ticker || viewProperties.InvestmentName)){
                this.header_.Description = `Sale - ${viewProperties.OwnerShortestName} - ${(viewProperties.Ticker && viewProperties.Ticker.length > 1) ? viewProperties.Ticker : viewProperties.InvestmentName}  ${this.$accounting.formatNumber(viewProperties.Shares)} @ ${this.$accounting.formatMoney(viewProperties.ShareValue)} (${this.$accounting.formatMoney(viewProperties.Amount)})`
                if (this.$validator.fields.find({name: 'Description'})){
                    this.$validator.fields.find({name: 'Description'}).flags.dirty = true;
                }
            }
            else if (this.header_.Description == null && viewProperties.Notes != null && viewProperties.Notes != '') {
                this.header_.Description = viewProperties.Notes;
                if (this.$validator.fields.find({name: 'Description'})){
                    this.$validator.fields.find({name: 'Description'}).flags.dirty = true;
                }
            }
            if (simpleType == 'Capital Call' || simpleType == 'Share Sale' || simpleType == 'Dividend' || simpleType == 'Escrow'){ //check for single match
                this.loading = true;
                try {
                    const params = {} as LiqService.GetMatchedTransactionParameters;
                    params.EntityType = 'InvestmentTransaction';
                    params.EntityId = viewProperties.Id;
                    const matches = await this._liqService.GetMatchedTransaction(params);
                    if (matches && matches.length == 1){
                        const match = matches[0];
                        if (Math.abs(match.Amount) == Math.abs(viewProperties.Amount)){ //if amount matches transaction exactly, go ahead and add it. (sign of viewProperties.Amount is not meaningful, so use ABS on both)
                            const newCash = new TransactionsService.Cash();
                            this.$nullifyObjectProps(newCash);
                            newCash.BankAccountId = match.BankAccountId;
                            newCash.Amount = match.Amount;
                            newCash.Comment = 'Automatically added from Legacy Transaction';
                            newCash.BankTransactionId = match.BankTransactionId;
                            newCash.MatchSource = 'Posted';
                            newCash.Direction = match.Amount < 0 ? 'Source' : 'Result';
                            newCash.EntityType = 'InvestmentTransactionHeader';
                            newCash.EntityId = this.header_.Id;
                            const id = await new SmartObject('InvestmentTransactionCash').createObject(
                                newCash
                            );
                            newCash.CashId = id;
                            newCash.Id = id;
                            this.$notify.success('Matched Cash Item Automatically Added');
                        }
                    }
                }
                catch(e){
                    console.log(e);
                    this.$alert('There was a problem attempting automatic match');
                    this.loading = false;
                }
                this.loading = false;
            }
        }
    }
    async addLegacyAssociated(): Promise<void> {
        try{
            const conf: any = await this.$prompt('Enter a legacy Transaction Id (or multiple Ids separated by a comma)', 'Legacy Transaction', { // MessageBoxData Type must be incorrectly defined, so declaring it as any
                    confirmButtonText: 'OK',
                    inputType: 'string',
                    showCancelButton: true,
                    });
            const legacyTransactionIds_: string[] = conf.value.split(',');
            const legacyTransactionIds: number[] = legacyTransactionIds_.map( str => parseInt(str) );
            try {
                if (this.isNew){
                    this.wasNew = true;
                    await this.createHeader();
                    this.isNew = false;
                }
                const initialNumberOfItems = legacyTransactionIds.length; // don't set Net Amount when Distribution and more than 1 legacy item because the Net Amount should be the sum of the items
                if (legacyTransactionIds.length > 1){ // execute the first item first, then the others.  asyncIterations does not execute in a certain order.
                    await this.processLegacyAssociated(legacyTransactionIds[0], initialNumberOfItems);
                    legacyTransactionIds.splice(0, 1); //remove the first item, which we just processed
                }
                await this.$asyncIterations.forEach(legacyTransactionIds, async (legacyTransactionId: number) => { 
                    await this.processLegacyAssociated(legacyTransactionId, initialNumberOfItems);
                }); // end of asyncIterations.forEach

                this.$notify.success('Association created.');
            } catch(e) {
                console.error();
                this.$message('There was a problem processing your Legacy Transaction.  See console for more information.')
            }            
            finally {
                this.fetchLegacyAssociated();
            }
        }
        catch{} // cancelled prompt
    }
    async previewDeleteTransaction(): Promise<void> {
        this.loading = true;
        const params = {} as TransactionsService.PreviewHeaderDeleteParameters;
        params.TransactionHeaderId = this.header_.Id;
        this.previewHeaderDelete = await this._transactionsService.PreviewHeaderDelete(params);
        this.loading = false;
        this.showPreviewDeleteTransaction = true;
    }
    async deleteTransaction(): Promise<void> {
        this.loading = true;
        const params = {} as TransactionsService.DeleteHeaderAndComponentsParameters;
        params.TransactionHeaderId = this.header_.Id;
        await this._transactionsService.DeleteHeaderAndComponents(params);
        this.showPreviewDeleteTransaction = false;
        this.$emit('delete', this.header_.Id);
        this.loading = false;
        await this.$nextTick();
        this.header_ = undefined; // {} as TransactionsService.Header;
        this.transactionType = undefined; //{} as TransactionsService.Types;
        this.legacyAssociated = undefined; //[] as TransactionsService.LegacyAssociated[];
        this.previewHeaderDelete = undefined; //[] as TransactionsService.PreviewHeaderDelete[];
        this.legacyTransactionType = undefined;
    }
    deleteRowStyle(row, rowIndex): object{
        if (row.row.PreventDelete){
                return {'background-color': 'yellow'};
            }
    } 
}
