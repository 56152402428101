
    import { Component, Vue, Prop, Watch, Ref, VModel } from "vue-property-decorator";
    import * as TransactionsService from '@/services/DAL/transactionsService';
    import * as AssetService from '@/services/DAL/assetService';
    import formatters from "@/utils/formatters";
    import uniqBy from 'lodash/uniqBy';
    import Common from '@/utils/common';
    import { Field } from "node_modules/vee-validate/types";
    import ElementUI from "element-ui";
    import EntityJournalVouchers from '@/views/GeneralLedger/EntityJournalVouchers.vue'
    import EquityList from '@/views/InvestmentTransaction/Equity/EquityList.vue'
    import HoldingEntitySelector from "@/components/form/HoldingEntitySelector.vue";
    import TrancheHistory from '@/views/InvestmentTransaction/Equity/TrancheHistory.vue'
import { CurrencyInputOptions, NumberRange } from "vue-currency-input";

    declare function getStoredSecurityLevel(Id: number): number;
    declare function tryParseInt(input: any, defaultValue: number): number;
    declare var SmartObject: any;

    interface Ifmv {
        fmv: number
        fmvAsOf: string,
        fmvShares: number,
        fmvShareCountAsOf: string,
        fmvPerShare: number,
        priorYearMessage: string
    }

    @Component({
        components: {
            EntityJournalVouchers,
            EquityList,
            HoldingEntitySelector,
            TrancheHistory
        }
    })
    export default class EquityTrancheConsumer extends Vue {
    @Ref() readonly inputSelectedEquityAmount!: ElementUI.Input;
    @Ref() readonly inputSelectedEquityAmountPerQuantity!: ElementUI.Input;
    @Ref() readonly frmSelectedEquity!: ElementUI.Form;
    @Ref() readonly refEquityTransactionTranches!: ElementUI.Table;

    //#region Private declarations for Services
    private _transactionsService: TransactionsService.TransactionsService;
    private _assetService: AssetService.AssetService;
    public formatters: formatters;
    public common: Common;

    //#endregion Private declarations for Services

    //#region Props
    //@ModelSync('tranches', 'change', { type: Array }) equityTransactionTranches!: TransactionsService.EquityTransactionTranches[]
    @VModel({ type: Array }) equityTransactionTranches!: TransactionsService.EquityTransactionTranches[];
    @Prop( { type: Object, required: true }) readonly header: TransactionsService.Header;
    @Prop( { type: Object, required: true }) readonly baseInvestment: AssetService.InvestmentList;
    @Prop( { type: Object }) readonly counterInvestment: AssetService.InvestmentList;
    @Prop( { type: Boolean, default: false }) readonly requireCounterInvestment: boolean;
    //#endregion Props

    //#region Data
    loading = false;
    securityLevel_: number = null;
    
    selectedTranche = {} as TransactionsService.EquityTransactionTranches;
    selectedEquity = {} as TransactionsService.Equity;
    showEquityEdit: boolean = false;
    selectedEquityUsePerShare: boolean = false;
    showTranchesTable: boolean = true;
    showInvestmentValuationPopover: boolean = false;
    showSharesPopover: boolean = false;
    optionalColumns =  ['Tranche', 'Holding Entity', 'Purchased Shares'];
    checkedColumns = ['Tranche', 'Holding Entity'];
    splitEnabled: boolean = false;
    exchangeHoldingEntityEnabled: boolean = false;
    exchangeTrancheEnabled: boolean = false;
    requiredShares: number = null;
    showEnterRequiredShares = false;
    splitPrecision: number = 0;
    hoveredAcquisitionSourceId: number = 0;

    //#endregion Data

    //#region Lifecycle
    async created() {
        this._assetService = new AssetService.AssetService();
        this._transactionsService = new TransactionsService.TransactionsService();
        this.common = new Common();
    
        this.formatters = new formatters();
        this.securityLevel_ = tryParseInt(
            getStoredSecurityLevel(this.$namedKey.SecurityView.ManageAssets),
            0
        );
        this.getEquityTransactionTranches();
    }
    //#endregion Lifecycle

    //#region Watches
    @Watch('baseInvestment.InvestmentId')
    async baseInvestmentIdChanged(val: number, oldVal: number) {
        this.getEquityTransactionTranches();
    }
    @Watch('selectedEquity.Amount')
    async selectedEquityAmountChanged(val: number, oldVal: number) {
        if (this.selectedEquity.Quantity == 0){
            this.selectedEquity.AmountPerQuantity = 0;
        }
        else {
            this.selectedEquity.AmountPerQuantity = val / this.selectedEquity.Quantity;
        }
    }
    @Watch('selectedEquity.AmountPerQuantity')
    async selectedEquityAmountPerQuantityChanged(val: number, oldVal: number) {
        this.selectedEquity.Amount = val * this.selectedEquity.Quantity;
    }
    @Watch('selectedEquity.Quantity')
    async selectedEquityQuantityChanged(val: number, oldVal: number) {
        if (this.selectedEquity.Amount && this.selectedEquity.AmountMethod == 'Amount'){ // Amount stays constant because that is what was entered; update AmountPerQuantity.
            this.selectedEquity.AmountPerQuantity = val == 0 ? 0 : this.selectedEquity.Amount / val;
        }
        else if (this.selectedEquity) { // AmountPerQuantity stays constant and Amount gets updated (implied: else if AmountMethod in (PerShares, HighLow))
            this.selectedEquity.Amount = val * this.selectedEquity.AmountPerQuantity;
        }
    }
    @Watch('selectedEquity.PerQuantityHigh') 
    async selectedEquityHighChanged(val: number, oldVal: number){
        await this.setAmountPerShareFromHighLow();
    }
    @Watch('selectedEquity.PerQuantityLow') 
    async selectedEquityLowChanged(val: number, oldVal: number){
        await this.setAmountPerShareFromHighLow();
    }
    async setAmountPerShareFromHighLow() {
        if (this.selectedEquity && this.selectedEquity.PerQuantityHigh && this.selectedEquity.PerQuantityLow){
            this.selectedEquity.AmountPerQuantity = (this.selectedEquity.PerQuantityHigh + this.selectedEquity.PerQuantityLow)/2;
        }
    }
    @Watch('splitEnabled')
    async splitEnabledChanged(val: boolean, oldVal: boolean) {
        if (!val && !!oldVal){
            this.selectedEquity.SplitFrom = null;
            this.selectedEquity.SplitTo = null;
        }
    }
    //#endregion Watches

    //#region Computed
    get equityFormFields(): Object{
        return Object.keys(((this as any).veeFields as Array<Field>))
        .filter(key => key.startsWith('selectedEquity.'))
        .reduce((obj, key) => {
        obj[key] = ((this as any).veeFields as Array<Field>)[key];
        return obj;
        }, {});
    }
    get isEquityFormValid(): boolean{
        const quantityOk = !!this.selectedEquity.Quantity; 
        if (quantityOk){
            return !(Object.keys(this.equityFormFields).some(
                key => this.equityFormFields[key].invalid
            ));
        }
        else return quantityOk;
    }

    get operation(): string{
        if (this.header){
            return this.header.SubTypeShort;
        }
    }
    get operationDirection(): string{
        if (this.header){
            return this.header.SubTypeBaseDirection;
        }
    }
    get isGift(): boolean{ // Assignment, no Consideration
        return (this.header && this.header.SubOperation == 'Gift');
    }
    get noConsideration(): boolean {
        return (this.header && this.header.SubTypeNoConsideration);
    }
    get useConsideration(): boolean {
        return !this.noConsideration; 
    }

    get considerationDirection(): string{
        return this.oppositeDirection(this.operationDirection);
    }
    
    get selectedEquityQuantityDisplay(): number {
        if (this.operationDirection == 'Source'){
            return 0 - this.selectedEquity.Quantity;
        }
        else {
            return this.selectedEquity.Quantity;
        }
    }
    set selectedEquityQuantityDisplay(value: number){
        if (this.selectedEquity.Quantity == 0){
            this.selectedEquity.Quantity = null;
        }
        else if (this.operationDirection =='Source'){
            this.selectedEquity.Quantity = 0 - value;
        }
        else {
            this.selectedEquity.Quantity = value;
        }
    }
    
    get selectedEquityAmountDisplay(): number {
        if (this.operationDirection =='Source'){
            return 0 - this.selectedEquity.Amount;
        }
        else {
            return this.selectedEquity.Amount;
        }
    }
    set selectedEquityAmountDisplay(value: number){
        if (this.operationDirection =='Source'){
            this.selectedEquity.Amount = 0 - value;
        }
        else {
            this.selectedEquity.Amount = value;
        }
    }

    get isTransferOrSwap(): boolean{
        return (this.operation == 'Transfer' || this.operation == 'Swap') && !(this.header && this.header.SubTypeScope == '3rd Party');
    }
    get isSplitExchange(): boolean{
        return (this.operation == 'Split Exchange');
    }

    get fmvIsPriorYear(): boolean{
        if (this.equityTransactionTranches && this.equityTransactionTranches.length){
            return this.$dayjs(this.equityTransactionTranches[0].FMVAsOf).isBefore(this.$dayjs(this.header.Date).startOf('year'));
        }
    }

    get fmv(): Ifmv {
        if (this.equityTransactionTranches && this.equityTransactionTranches.length){
            return {
                fmv: this.equityTransactionTranches[0].FMV,
                fmvAsOf: this.equityTransactionTranches[0].FMVAsOf,
                fmvShares: this.equityTransactionTranches[0].FMVShares,
                fmvShareCountAsOf: this.equityTransactionTranches[0].FMVShareCountAsOf,
                fmvPerShare: this.equityTransactionTranches[0].FMVPerQuantity,
                priorYearMessage: this.fmvIsPriorYear ? ' (Prior Year)' : ''
            }
        }
        else return undefined
    }
    get showHoldingEntity(): Boolean {
        return this.checkedColumns.some(column => column == 'Holding Entity');
    }
    get showTranche(): Boolean {
        return this.checkedColumns.some(column => column == 'Tranche');
    }
    get showPurchaseShares(): Boolean {
        return this.checkedColumns.some(column => column == 'Purchased Shares');
    }

    get consumptionQuantity(): number{
        let result: number;
        if (this.equityTransactionTranches && this.equityTransactionTranches.length){
            result =  this.equityTransactionTranches.reduce(function(a, b) {
                return (a || 0) + b.CurrentConsumptionQuantity;
            }, 0)
        }
        else result = 0;
        if (this.operationDirection == 'Source'){ // so far, all Equity SubTypes are source (only Capital Stake Purchase is a Result); i.e. everything is getting flipped
            return 0-result;
        }
        else return result;
        
    }
    get outstandingRequiredShares(): number{
        if (!this.requiredShares) return undefined;
        return this.requiredShares - (this.consumptionQuantity | 0)
    }

    get splitInputOptions(): CurrencyInputOptions {
        const ret = {} as CurrencyInputOptions;
        const valueRange = {} as NumberRange;
        valueRange.min = 1;
        ret.currency = null;
        ret.valueRange = valueRange;
        ret.precision = this.splitPrecision;
        ret.valueAsInteger = (this.splitPrecision == 0);
        ret.allowNegative = false;
        ret.distractionFree = false;
        return ret;        
    }


    //#endregion Computed

    //#region Methods
    oppositeDirection (direction: string): string{
        if (direction == 'Result') return 'Source';
        if (direction == 'Source') return 'Result';
    }

    async getEquityTransactionTranches(){
        if (this.baseInvestment && this.baseInvestment.InvestmentId){
            const params = {} as TransactionsService.GetEquityTransactionTranchesParameters;
            params.TransactionHeaderId = this.header.Id;
            params.InvestmentId = this.baseInvestment.InvestmentId;
            this.loading = true;
            this.equityTransactionTranches = await this._transactionsService.GetEquityTransactionTranches(params);
            this.showTranchesTable = false; //redraw so that the header for FMV will show
            await this.$nextTick();
            this.showTranchesTable = true;
            this.loading = false;
        }
    }
    shortDate(date) {
        if (date === undefined || date === '' || date === null) return '';
        else return this.$dayjs(date).format('M/D/YY');
    }
    filterHandler(value, row, column, prop, formatter?) {
        const property = prop ? prop : column['property'];
        return (formatter ? formatter(row[property]) : row[property]) === value;
    }
    
    filters(column, formatter?){ //formatter only supports shortDate currently
        const filters = this.equityTransactionTranches.map(function(list) {
            return {
                text: formatter ? formatter(list[column]) : list[column]
                , value: formatter ? formatter(list[column]) : list[column]
                };
        });
        const uniq = uniqBy(filters, 'value');
        return uniq;
    }

    getSummary(param) {
        const { columns, data } = param;
        const columnList = [];
        const formats = new Map();
        columns.forEach((column, index) => {
            if (column.align == 'is-right' && column.formatter) {
                columnList.push(index);
                switch(column.formatter.name){
                    case 'formatterCurrency':
                        formats.set(index, "currency");
                        break;
                    case 'formatterNumber2':
                        formats.set(index, "number2");
                        break;
                    case 'formatterNumber3':
                        formats.set(index, "number3");
                        break;
                }                
            }
        });
        const totalLabel = 'Total';
        const totalLabelIndex = 0;
        return this.common.getSummaryArray(param, columnList, totalLabel, totalLabelIndex, formats);
    }
    async editEquityItem(
        tranche: TransactionsService.EquityTransactionTranches ,
        event,
        column
    ) {
        this.hoveredAcquisitionSourceId = null; // hide the pop-up

        if (tranche.AvailableQuantity == 0 && !tranche.CurrentConsumptionEquityId){ //shouldn't be possible, but just in case
            return;
            }
        if (this.requireCounterInvestment && (!this.counterInvestment || !this.counterInvestment.InvestmentId)){
            this.$alert('"To" Investment must be selected before selecting shares.');
            return;
        }
        if (tranche.CurrentConsumptionEquityId){
            const params = {} as TransactionsService.GetEquityParameters;
            params.EquityId = tranche.CurrentConsumptionEquityId;
            const equities = await this._transactionsService.GetEquity(params);
            if (equities && equities.length ==1) this.selectedEquity = equities[0];
        }
        else {
            this.selectedEquity = {} as TransactionsService.Equity;
            this.$set(this.selectedEquity, 'TransactionHeaderId', this.header.Id);
            this.$set(this.selectedEquity, 'InvestmentId', tranche.InvestmentId);
            this.$set(this.selectedEquity, 'IsCarryShares', tranche.IsCarryShares);
            this.$set(this.selectedEquity, 'Tranche', tranche.Tranche);
            this.$set(this.selectedEquity, 'HoldingEntityId', tranche.HoldingEntityId);
            this.$set(this.selectedEquity, 'SourceId', tranche.DescendantEquityId); 
            this.$set(this.selectedEquity, 'AcquisitionSourceId', tranche.AcquisitionSourceId || tranche.DescendantEquityId);  //previous to 8/5/22, this was just tranche.EquityId.
            this.$set(this.selectedEquity, 'SaleCostBasisPerQuantity', tranche.CostPerQuantity); //this will be used for SaleCostBasis in the base-Equity and AcquisitionCost in the counter-Equity;
            this.$set(this.selectedEquity, 'FMVPerQuantity', tranche.FMVPerQuantity); 
            this.$set(this.selectedEquity, 'FMVAsOf', tranche.FMVAsOf); 
            this.$set(this.selectedEquity, 'ConsiderationDirection', this.operationDirection);
            this.$set(this.selectedEquity, 'Direction', this.operationDirection);
            this.$set(this.selectedEquity, 'ConsiderationType', 'Equity');
            this.$set(this.selectedEquity, 'Amount', 0);
            this.$set(this.selectedEquity, 'AmountMethod', this.isGift ? 'HighLow' : 'Amount');
            this.$set(this.selectedEquity, 'PerQuantityHigh', null);
            this.$set(this.selectedEquity, 'PerQuantityLow', null);
            this.$set(this.selectedEquity, 'AmountPerQuantity', 0);
            this.$set(this.selectedEquity, 'Quantity', null);
            this.$set(this.selectedEquity, 'SplitFrom', tranche.SplitFrom);
            this.$set(this.selectedEquity, 'SplitTo', tranche.SplitTo);
            this.$set(this.selectedEquity, 'ExchangeToHoldingEntityId', tranche.ExchangeToHoldingEntityId);
            this.$set(this.selectedEquity, 'ExchangeToTranche', tranche.ExchangeToTranche);
            this.$set(this.selectedEquity, 'Deletable', tranche.Deletable)
            this.$set(this.selectedEquity, 'DownstreamRecords', tranche.DownstreamRecords)
        }
        if (this.selectedEquity.SplitFrom) {
            this.splitEnabled = true;
            if (this.selectedEquity.SplitFrom % 1 != 0 || this.selectedEquity.SplitTo % 1 != 0) { // number 
                this.splitPrecision = 10;
            }
        }
        else this.splitEnabled = false;
        
        if (this.selectedEquity.ExchangeToHoldingEntityId) this.exchangeHoldingEntityEnabled = true;
        else this.exchangeHoldingEntityEnabled = false;

        if (this.selectedEquity.ExchangeToTranche) this.exchangeTrancheEnabled = true;
        else this.exchangeTrancheEnabled = false;

        this.selectedTranche = tranche;
        this.showEquityEdit = true;
    }
    async refreshAll(){
        const promises = [
            this.getEquityTransactionTranches(),
        ];
        await Promise.all(promises);
    }
    async saveSelectedEquity(){
        this.loading = true;

        //Make adjustment to selectedEquity whether new or updating:
        if (this.selectedEquity && this.selectedTranche){ //should always happend, whether new or update
            if (this.header && (this.header.SubTypeScope == 'Self' || this.header.SubTypeScope == 'Investment')){
                this.selectedEquity.Amount = null; //may have been converted from null to 0; ensure it is always null (otherwise it will generate gain/loss)
            }
            this.selectedEquity.FMVPerQuantity = this.selectedTranche.FMVPerQuantity; // should be the same anyway, unless it has changed due to updates to prior transactions/valuations.  UI will show a warning in that case.
            this.selectedEquity.FMVAsOf = this.selectedTranche.FMVAsOf;
            this.selectedEquity.FMV = this.selectedEquity.FMVPerQuantity * this.selectedEquity.Quantity; 
            this.selectedEquity.SaleCostBasisPerQuantity = this.selectedTranche.CostPerQuantity; // same as above
            this.selectedEquity.SaleCostBasis = this.selectedEquity.SaleCostBasisPerQuantity * this.selectedEquity.Quantity; 
            this.selectedEquity.Tranche = this.selectedTranche.Tranche; // same as above
            this.selectedEquity.HoldingEntity = this.selectedTranche.HoldingEntity; // same as above
            if (this.header && this.header.SubTypeScope == 'Self'){// need Acquisition Cost and Sale Cost Basis on both sides for "Self" so they wash out.
                this.selectedEquity.AcquisitionCost = this.selectedEquity.SaleCostBasis;
            }
            if (this.isSplitExchange && !this.splitEnabled){
                this.selectedEquity.SplitFrom = null;
                this.selectedEquity.SplitTo = null;
            }
            if (this.isSplitExchange && !this.exchangeHoldingEntityEnabled){
                this.selectedEquity.ExchangeToHoldingEntityId = null;
            }
            if (this.isSplitExchange && !this.exchangeTrancheEnabled){
                this.selectedEquity.ExchangeToTranche = null;
            }
        }

        // New:
        // first, create "source"
        if (this.selectedEquity && !this.selectedEquity.Id){
            this.loading = true;
            const id = await new SmartObject('InvestmentTransactionEquity').createObject(
                this.selectedEquity
            );
            this.loading = false;
            this.selectedEquity.EquityId = id;
            this.selectedEquity.Id = id;
            this.$notify.success('New Equity Item Added');
            // then create "counter," if required:
            if (this.isTransferOrSwap || this.isSplitExchange){
                const counterEquity = {} as TransactionsService.Equity;
                counterEquity.TransactionHeaderId = this.header.Id;
                counterEquity.IsCarryShares = this.selectedEquity.IsCarryShares;
                if (this.isSplitExchange && this.exchangeHoldingEntityEnabled && this.selectedEquity.ExchangeToHoldingEntityId){
                    counterEquity.HoldingEntityId = this.selectedEquity.ExchangeToHoldingEntityId;
                }
                else {
                    counterEquity.HoldingEntityId = this.selectedEquity.HoldingEntityId; // this assumes the receiving investment/tranche has the same Holding Entity and Tranche, which may not be a sound assumption.
                }
                if (this.isSplitExchange && this.exchangeTrancheEnabled && this.selectedEquity.ExchangeToTranche){
                    counterEquity.Tranche = this.selectedEquity.ExchangeToTranche;
                }
                else {
                    counterEquity.Tranche = this.selectedEquity.Tranche;
                }
                counterEquity.SourceId = this.selectedEquity.EquityId; // this is the key to the CounterEquity relationship; contrary to comment above, we still need SourceId for the CounterEquity.
                counterEquity.AcquisitionSourceId = this.selectedEquity.AcquisitionSourceId ; //prior to 8/5/22, this would default selectedEquity.SourceId if AcquisitionSourceId was empty.  But we never want anything but real AcquisitionSoureId
                counterEquity.ConsiderationDirection = this.operationDirection; //3/11/22 Note: these are opposite of the quantity, so opposite of the actual direction. But they are not real columns, so they are not written and probably not used for anything
                counterEquity.Direction = this.operationDirection; // see above
                counterEquity.ConsiderationType = 'Equity'
                if (this.isSplitExchange){
                    counterEquity.InvestmentId = this.selectedEquity.InvestmentId;
                }
                else {
                    counterEquity.InvestmentId = this.counterInvestment.InvestmentId;
                }
                if (this.isSplitExchange && this.splitEnabled && this.selectedEquity.SplitFrom && this.selectedEquity.SplitTo){
                    counterEquity.Quantity = 0 - ((this.selectedEquity.Quantity /  this.selectedEquity.SplitFrom) * this.selectedEquity.SplitTo);
                }
                else {
                    counterEquity.Quantity = 0 - this.selectedEquity.Quantity;
                }
                
                counterEquity.AcquisitionCost = this.selectedEquity.SaleCostBasis == null ? null : 0 - this.selectedEquity.SaleCostBasis; // prior to 3/11/22: counterEquity.AcquisitionCostPerQuantity * counterEquity.Quantity; use SaleCostBasis because counterEquity.Quantity may have been split.
                //counterEquity.AcquisitionCostPerQuantity = counterEquity.AcquisitionCost / counterEquity.Quantity; // this.selectedEquity.SaleCostBasisPerQuantity; //don't need this, will refresh
                if (this.header && this.header.SubTypeScope == 'Self'){ //need Acquisition Cost and Sale Cost Basis on both sides for "Self" so they wash out.
                    counterEquity.SaleCostBasis = this.selectedEquity.SaleCostBasis == null ? null : 0 - this.selectedEquity.SaleCostBasis;
                }
                counterEquity.Amount = this.selectedEquity.Amount == null ? null : 0 - this.selectedEquity.Amount;
                counterEquity.FMV = this.selectedEquity.FMV == null ? null : 0 - this.selectedEquity.FMV;
                //counterEquity.FMVPerQuantity = counterEquity.FMV / counterEquity.Quantity; // this.selectedEquity.FMVPerQuantity;//don't need this, will refresh
                counterEquity.FMVAsOf = this.selectedEquity.FMV == null ? null : this.selectedEquity.FMVAsOf;
                this.loading = true;
                const counterId = await new SmartObject('InvestmentTransactionEquity').createObject(
                    counterEquity
                );
                this.loading = false;
                counterEquity.EquityId = counterId;
                counterEquity.Id = counterId;
                this.$notify.success('New Counter Equity Item Added');
            }
        }
        // update existing:
        else if (this.selectedEquity.Id){
            // update "source":
            this.loading = true;
            await new SmartObject('InvestmentTransactionEquity', this.selectedEquity.EquityId).updateObject(
                this.selectedEquity
            );
            this.loading = false;
            this.$notify.success('Equity Item Updated');
            // then set values of counter, if required:
            if ((this.isTransferOrSwap || this.isSplitExchange) && this.selectedTranche.CounterEquityId){
                const params = {} as TransactionsService.GetEquityParameters;
                params.EquityId = this.selectedTranche.CounterEquityId;
                const counterEquities = await this._transactionsService.GetEquity(params);
                if (counterEquities && counterEquities.length == 1){
                    const counterEquity = counterEquities[0];
                    
                    if (this.isSplitExchange && this.exchangeHoldingEntityEnabled && this.selectedEquity.ExchangeToHoldingEntityId){
                        counterEquity.HoldingEntityId = this.selectedEquity.ExchangeToHoldingEntityId;
                    }
                    else {
                        counterEquity.HoldingEntityId = this.selectedEquity.HoldingEntityId; // this assumes the receiving investment/tranche has the same Holding Entity and Tranche, which may not be a sound assumption.
                    }

                    if (this.isSplitExchange && this.exchangeTrancheEnabled && this.selectedEquity.ExchangeToTranche){
                        counterEquity.Tranche = this.selectedEquity.ExchangeToTranche;
                    }
                    else {
                        counterEquity.Tranche = this.selectedEquity.Tranche;
                    }
                    
                    if (this.isSplitExchange && this.splitEnabled && this.selectedEquity.SplitFrom && this.selectedEquity.SplitTo){
                        counterEquity.Quantity = 0 - ((this.selectedEquity.Quantity /  this.selectedEquity.SplitFrom) * this.selectedEquity.SplitTo);
                    }
                    else {
                        counterEquity.Quantity = 0 - this.selectedEquity.Quantity;
                    }
                    
                    counterEquity.AcquisitionCost = this.selectedEquity.SaleCostBasis == null ? null : 0 - this.selectedEquity.SaleCostBasis; // prior to 3/11/22: counterEquity.AcquisitionCostPerQuantity * counterEquity.Quantity; use SaleCostBasis because counterEquity.Quantity may have been split.
                    if (this.header && this.header.SubTypeScope == 'Self'){ //need Acquisition Cost and Sale Cost Basis on both sides for "Self" so they wash out.
                        counterEquity.SaleCostBasis = this.selectedEquity.SaleCostBasis == null ? null : 0 - this.selectedEquity.SaleCostBasis;
                    }
                    //counterEquity.AcquisitionCostPerQuantity = counterEquity.AcquisitionCost / counterEquity.Quantity; // this.selectedEquity.SaleCostBasisPerQuantity;
                    counterEquity.Amount = this.selectedEquity.Amount == null ? null : 0 - this.selectedEquity.Amount;
                    // Might have changed:
                    counterEquity.FMV = this.selectedEquity.FMV == null ? null : 0 - this.selectedEquity.FMV;
                    //counterEquity.FMVPerQuantity = counterEquity.FMV / counterEquity.Quantity; // this.selectedEquity.FMVPerQuantity;
                    counterEquity.FMVAsOf = this.selectedEquity.FMVAsOf;
    
                    this.loading = true;
                    await new SmartObject('InvestmentTransactionEquity', counterEquity.EquityId).updateObject(
                        counterEquity
                    );
                    this.loading = false;
                    this.$notify.success('Counter Equity Item Updated');

                }
            else console.error('Getting Counter Equity did not return exactly 1 record');
            }
        }
        this.selectedEquity = {} as TransactionsService.Equity;
        await this.refreshAll();
        this.showEquityEdit = false;
        this.loading = false;
    }
    async deleteSelectedEquity() {
        if (this.selectedEquity.Id) {
            try {
                await this.$confirm(
                    'Are you sure you want to delete this Equity item?  Doing so will will return the shares to the Available Quantity. Continue?',
                    'Warning',
                    {
                        confirmButtonText: 'OK',
                        cancelButtonText: 'Cancel',
                        type: 'warning'
                    }
                );

                try {
                    if ((this.isTransferOrSwap || this.isSplitExchange) && this.selectedTranche.CounterEquityId){
                        // must do the counter-item first because there's a constraint between it and the primary item.
                        this.loading = true;
                        await new SmartObject(
                            'InvestmentTransactionEquity',
                            this.selectedTranche.CounterEquityId
                        ).deleteObject();
                        this.loading = false;
                        this.$notify.success('Counter Equity item deleted.');
                    }
                    this.loading = true;
                    await new SmartObject(
                        'InvestmentTransactionEquity',
                        this.selectedEquity.EquityId
                    ).deleteObject();
                    this.loading = false;

                    this.$notify.success('Equity item deleted.');

                } catch (err) {
                const errJson = JSON.parse(err.responseText);
                if (
                    errJson.ExceptionMessage.toLowerCase().indexOf("same table reference") > -1
                ) {
                    this.$alert(
                    'This item cannot be deleted because it is the source of subsquent transactions.  Those transactions must be deleted first.'
                    ,'Error'
                    , {type: 'error'}
                    );
                } else {
                    this.$alert(
                    'There was an error processing your request.  Please contact support with details from the console'
                    ,'Error'
                    , {type: 'error'}
                    );
                    console.error(errJson);
                }
                }
                this.refreshAll();
                this.selectedEquity = {} as TransactionsService.Equity;
                this.showEquityEdit = false;
            } catch {}
        }
    }

    async onClickInputSelectedEquityAmount(){
        this.selectedEquityUsePerShare ? this.selectedEquityUsePerShare = false : undefined; 
        await this.$nextTick();
        this.inputSelectedEquityAmount.focus();
    }
    async onClickInputSelectedEquityAmountPerQuantity(){
        !this.selectedEquityUsePerShare ? this.selectedEquityUsePerShare = true : undefined;
        await this.$nextTick();
        this.inputSelectedEquityAmountPerQuantity.focus();
    }
    rowStyle(row, rowIndex){
        if (row.row.AvailableQuantity == 0){
                return {'background-color': 'gainsboro'};
            }
    } 
    setRequiredShares(shares){
        console.log(shares);
        this.requiredShares = shares; 
        this.showEnterRequiredShares = false;
    }


//#endregion Methods

    }
    
