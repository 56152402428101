import Vue from 'vue';
import VueRouter from 'vue-router';
import { AuthService } from '../services/AuthService';
import store from './../store';
import cloneDeep from 'lodash/cloneDeep';
import Dashboard from '@/views/Dashboard.vue';
import Admin from '@/views/Admin.vue';
import Trips from '@/views/Aviation/Trips.vue';
import TripLegEditList from '@/views/Aviation/TripLegEditList.vue';
import TripLegEdit from '@/views/Aviation/TripLegEdit.vue';
import TripInvoiceView from '@/views/Aviation/TripInvoiceView.vue';
import SLSDaysInNYForecast from '@/views/Aviation/SLSDaysInNYForecast.vue';
import EntityList from '@/views/Entity/EntityList.vue';
import EntityEdit from '@/views/Entity/EntityEdit.vue';
import AddressList from '@/views/Address/AddressList.vue';
import KeyPersonList from '@/views/KeyPerson/KeyPersonList.vue';
import FundFamilyList from '@/views/FundFamily/FundFamilyList.vue';
import FundHoldingsListView from '@/views/FundFamily/FundHoldingsListView.vue';
import OwnerCategoryList from '@/views/OwnerCategory/OwnerCategoryList.vue';
import TaxClassList from '@/views/TaxClass/TaxClassList.vue';
import RegisteredAgentList from '@/views/RegisteredAgent/RegisteredAgentList.vue';
import AgreementGroupList from '@/views/Agreements/AgreementGroupList.vue';
import BalanceSheetConfigView from '@/views/BalanceSheet/BalanceSheetConfigView.vue';
import ReportList from '@/js/Reports/ReportList.vue';
import ValuablesList from '@/views/Valuables/ValuablesList.vue';
import ValuablesEdit from '@/views/Valuables/ValuablesEdit.vue';
import AttachmentList from '@/js/Attachments/AttachmentList.vue';
import IngestAttachment from '@/js/Attachments/IngestAttachment.vue';
import DocumentUseList from '@/views/Attachments/DocumentUseList.vue';
import FileMove from '@/views/Attachments/FileMove.vue';
import BulkCopy from '@/views/Attachments/BulkCopy.vue';
import WorkflowList from '@/views/Workflow/WorkflowList.vue';
import ReportPermissionList from '@/views/Report/ReportPermissionList.vue';
import ProcessBalanceSheetHistory from '@/views/BalanceSheet/ProcessBalanceSheetHistory.vue';
import ProcessBalanceSheetHistoryProforma from '@/views/BalanceSheet/ProcessBalanceSheetHistoryProforma.vue';
import ProcessLiquidityHistory from '@/js/Treasury/ProcessLiquidityHistory.vue';
import ProcessCashFlow from '@/views/Treasury/ProcessCashFlow.vue';
import ProcessNonShareValuationStatistics from '@/views/Investment/ProcessNonShareValuationStatistics.vue';
import JetDepreciationImport from '@/js/Tax/JetDepreciationImport.vue';
import ExcelCellImportConfigurationMangement from '@/js/Excel/ExcelCellImportConfigurationMangement.vue';
import AgreementListView from '@/js/AP/AgreementListView.vue';
import AgreementEditView from '@/js/AP/AgreementEditView.vue';
import CharitableDonationEdit from '@/views/Agreements/CharitableDonationEdit.vue';
import PaymentList from '@/js/AP/PaymentList.vue';
import IngestInvoices from '@/views/Invoice/IngestInvoices.vue';
import InvoiceListView from '@/views/Invoice/InvoiceListView.vue';
import SmartControlLoader from '@/components/layout/SmartControlLoader.vue';
import ExchangeRateImport from '@/views/Valuation/ExchangeRateImport.vue';
import ManualTickerList from '@/views/Valuation/ManualTickerList.vue';
import OHLCRateImport from '@/views/Valuation/OHLCImport.vue';
import JPMDailyImport from '@/views/Valuation/JPMDailyImport.vue';
import EmailList from '@/views/Email/EmailList.vue';
import EventLogList from '@/views/Event/EventLogList.vue';
import EventLogDetailView from '@/views/Event/EventLogDetailView.vue';
import DatabaseScaleView from '@/views/Database/DatabaseScaleView.vue';
import VendorDetailsEdit from '@/js/Vendor/VendorDetailsEdit.vue';
import InvestmentList from '@/views/Investment/InvestmentList.vue';
import FixedIncomeList from '@/views/FixedIncome/FixedIncomeList.vue';
import FixedIncomeDashboard from '@/views/FixedIncome/FixedIncomeDashboard.vue';
import FixedIncomeEditView from '@/views/FixedIncome/FixedIncomeEditView.vue';
import InvestmentTransactionTabView from '@/views/InvestmentTransaction/InvestmentTransactionTabView.vue';
import InvestmentTransactionImport from '@/views/InvestmentTransaction/InvestmentTransactionImport.vue';
import SpecificLotEditView from '@/views/InvestmentTransaction/SpecificLotEditView.vue';
import BankTransactionEdit from '@/js/BankTransactions/BankTransactionEdit.vue';
import BankImport from '@/views/BankAccount/BankImport.vue';
import BankAccountReconciliationList from '@/views/BankAccount/BankAccountReconciliationList.vue';
import AmexTransactionImport from '@/views/BankTransaction/AmexTransactionImport.vue';
import ChaseCreditCardImport from '@/views/BankTransaction/ChaseCreditCardImport.vue';
import AviationImport from '@/views/Aviation/AviationImport.vue';
import TaxImport from '@/js/Tax/TaxModelImport.vue';
import TaxConfigurationList from '@/views/TaxConfiguration/TaxConfigurationList.vue';
import LayoutEdit from '@/js/Reports/LayoutEdit.vue';
import Full from '@/containers/ElFull.vue';
import Fullscreen from '@/containers/Fullscreen.vue';
import Login from '@/containers/Login.vue';
import EntityDocuments from '@/views/Entity/EntityDocuments.vue';
import CashTransferList from '@/views/CashTransfers/CashTransferList.vue';
import CashTransferEdit from '@/views/CashTransfers/CashTransferEdit.vue';
import ReserveImport from '@/views/Treasury/ReserveImport.vue';
import Investment from '@/views/Investment/Investment.vue';
import PageNotFound from '@/components/layout/PageNotFound.vue';
import ProcessLookThrough from '@/views/LookThrough/ProcessLookThrough.vue';
import ProcessInvestmentStatistics from '@/views/Investment/ProcessInvestmentStatistics.vue'
import NewInvoiceView from '@/js/AP/NewInvoiceView.vue';
import NewInvestmentTransaction from '@/views/InvestmentTransaction/InvestmentTransactionNew/NewInvestmentTransaction.vue';
import GeneralLedgerTView from '@/views/GeneralLedger/GeneralLedgerTView.vue';
import CapitalList from '@/views/InvestmentTransaction/Capital/CapitalList.vue';
import StakeTransaction from '@/views/InvestmentTransaction/Funds/StakeTransaction.vue';
import TransactionTabView from '@/views/InvestmentTransaction/TransactionTabView.vue';
import ApproverSelectionList from '@/views/Workflow/ApproverSelectionList.vue';
import ApprovalItemList from '@/views/Approval/ApprovalItemList.vue';
import ApprovalBatchView from '@/views/Approval/ApprovalBatchView.vue';
import VendorEditTabs from '@/views/Vendor/VendorEditTabs.vue';
import VendorList from '@/views/Vendor/VendorList.vue';
import CommitmentAnalysis from '@/views/InvestmentTransaction/Capital/CommitmentAnalysis.vue';
import InvestmentEdit from '@/views/Investment/InvestmentEdit.vue';
import MarketableSecuritiesTabsView from '@/views/InvestmentTransaction/MarketableSecuritiesSale/MarketableSecuritiesTabsView.vue';
import Instrument from '@/views/DebtInstrument/Instrument.vue';
import InvestmentParentList from '@/views/InvestmentParent/InvestmentParentList.vue';
import InstrumentTransactionEdit from '@/views/DebtInstrument/InstrumentTransactionEdit.vue';
import ReceivedEmailsAndInvoiceProcessingList from '@/views/Invoice/ReceivedEmailsAndInvoiceProcessingList.vue'
import TrancheHistory from '@/views/InvestmentTransaction/Equity/TrancheHistory.vue';
import BankAccountEdit from '@/js/BankAccount/BankAccountEdit.vue';
import DividendSummaryList from '@/views/Dividend/DividendSummaryList.vue';
import EntityJournalVouchers from '@/views/GeneralLedger/EntityJournalVouchers.vue';
import PromissoryNoteListView from '@/views/Debt/PromissoryNoteListView.vue';
import AdvancedTermPromissoryNoteList from '@/views/Debt/AdvancedTermPromissoryNoteList.vue';
import ViewDocument from '@/views/Attachments/ViewDocument.vue';
import K1List from '@/views/K1/K1List.vue';


const _auth: AuthService = new AuthService();

Vue.use(VueRouter);

const componentChildren = [
  {
    path: 'welcome',
    name: 'Welcome',
    beforeEnter: requireAuth,
    component: Dashboard
  },
  {
    path: 'dashboards',
    name: 'Dashboards',
    component: {
      render(c) {
        return c('router-view',  {on: this.$listeners});
      }
    },
    children: [
      {
        beforeEnter: requireAuth,
        path: 'reportlist/:reportId?',
        name: 'Report List',
        component: ReportList,
        props: true
      }
    ]
  },
  {
    path: 'accounting',
    name: 'Accounting',
    component: {
      render(c) {
        return c('router-view',  {on: this.$listeners});
      }
    },
    children: [
      {
        beforeEnter: requireAuth,
        path: 'GeneralLedgerForTransactionHeader/:transactionHeaderId?',
        name: 'General Ledger View',
        component: GeneralLedgerTView,
        props:
          (function (route) {
            return {
              transactionHeaderId: Number(route.params.transactionHeaderId),
            };
          })
      },
      {
        beforeEnter: requireAuth,
        path: 'EntityJournalVouchers/:entityType?/:entityId?',
        name: 'General Ledger Entity View',
        component: EntityJournalVouchers,
        props:
          (function (route) {
            return {
              entityId: Number(route.params.entityId),
              entityType: route.params.entityType,
            };
          })
      }
    ]
  },
  {
    path: 'treasury',
    name: 'Treasury',
    component: {
      render(c) {
        return c('router-view',  {on: this.$listeners});
      }
    },
    children: [
      {
        beforeEnter: requireAuth,
        path: 'IngestInvoices',
        name: 'Ingest Invoices',
        component: IngestInvoices
      },
      {
        beforeEnter: requireAuth,
        path: 'ReserveImport',
        name: 'Reserve Import',
        component: ReserveImport,
      },
      {
        beforeEnter: requireAuth,
        path: 'PaymentList/:approvalId?',
        name: 'Payment Dashboard',
        component: PaymentList,
        props:
          (function (route) {
            return {
              approvalId: Number(route.params.approvalId),
            };
          })
      },
      {
        beforeEnter: requireAuth,
        path: 'ApprovalItemList/:approvalId',
        name: 'ApprovalList',
        component: ApprovalItemList,
        props:
          (function (route) {
            return {
              approvalId: Number(route.params.approvalId),
            };
          })
      },
      {
        beforeEnter: requireAuth,
        path: 'ApprovalBatchView/:workflowId?', // use /ApprovalBatchView/4,5 to send both
        name: 'Approval Batches',
        component: ApprovalBatchView,
        props:
          (function (route) {
            const wf = route.params.workflowId || '';
            if (wf.indexOf(',')> -1){
              return {
                workflowId: wf === '' ? [] : wf.split(',')
              };
            }
            else if (wf.length>0){
              return {
                workflowId: [(wf)]
              }
            }
          })
      },
      {
        beforeEnter: requireAuth,
        path: 'CashTransferList',
        name: 'Cash Transfers',
        component: CashTransferList,
      },
      {
        beforeEnter: requireAuth,
        path: 'CashTransferEdit/:cashTransferId?',
        name: 'Cash Transfer',
        component: CashTransferEdit,
        props:
          (function (route) {
            return {
              cashTransferId: Number(route.params.cashTransferId),
            };
          })
      },
      {
        beforeEnter: requireAuth,
        path:
          'AgreementList/:agreementTypeId?/:initialClientId?/:initialAgreementGroupId?/:initialAgreementId?/:hideList?',
        name: 'Agreements',
        component: AgreementListView,
        props: true
      },
      {
        beforeEnter: requireAuth,
        path: 'AddAgreement',
        name: 'Add Agreement',
        component: AgreementEditView,
        props: {
          onSave: agreement => {
            location.href =
              '/Treasury/AgreementList/null/null/null/' +
              agreement.AgreementId;
          }
        }
      },
      {
        beforeEnter: requireAuth,
        path: 'AddCharitableDonation',
        name: 'Add Charitable Donation',
        component: CharitableDonationEdit,
        props: {
          onSave: agreement => {
            location.href =
              '/Treasury/AgreementList/5/null/null/' + agreement.AgreementId;
          }
        }
      },
      {
        beforeEnter: requireAuth,
        path: 'AgreementEditView/:agreementId?',
        name: 'AgreementEditView',
        component: AgreementEditView,
        props: true
      },
      {
        beforeEnter: requireAuth,
        path: 'AddVendor',
        name: 'Add A Vendor/Payee',
        component: VendorDetailsEdit,
        props: {
          onSave: vendor => {
            router.push({ name: 'Vendor Edit', params: {  vendorId: vendor.Id.toString() } }).catch(() => {});
          }
        }
      },
      {
        beforeEnter: requireAuth,
        path: 'VendorEdit/:vendorId?',
        name: 'Vendor Edit',
        component: VendorEditTabs,
        props:
          (function (route) {
            return {
              vendorId: Number(route.params.vendorId),
            };
          })
      },

      {
        beforeEnter: requireAuth,
        path: 'VendorList/:clientId?',
        name: 'Vendor List',
        component: VendorList,
        props:
          (function (route) {
            return {
              clientId: Number(route.params.clientId),
            };
          })
      },

      {
        beforeEnter: requireAuth,
        path: 'ProcessLiquidityHistory',
        name: 'Run Liquidity Process',
        component: ProcessLiquidityHistory
      },
      {
        beforeEnter: requireAuth,
        path: 'ProcessCashFlow',
        name: 'Run Cashflow Process',
        component: ProcessCashFlow
      },
      {
        beforeEnter: requireAuth,
        path: 'BankTransactionEdit/:BankTransactionId?',
        name: 'Bank Transaction Matching',
        component: BankTransactionEdit,
        props:
          (function (route) {
            return {
              BankTransactionId: Number(route.params.BankTransactionId),
            };
          })
      },
      {
        beforeEnter: requireAuth,
        path: 'BankImport',
        name: 'Bank Import',
        component: BankImport
      },
      {
        beforeEnter: requireAuth,
        path: 'BankAccountReconciliationList/:clientId?/:yearMonth?',
        name: 'Bank Account Reconciliation',
        component: BankAccountReconciliationList,
        props:
          (function (route) {
            return {
              clientId: Number(route.params.clientId),
              yearMonth: Number(route.params.yearMonth),
            };
          })
      },
      {
        beforeEnter: requireAuth,
        path: 'BankAccountEdit/:bankAccountId?',
        name: 'Bank Account Edit',
        component: BankAccountEdit,
        props:
          (function (route) {
            return {
              bankAccountId: Number(route.params.bankAccountId),
            };
          })
      },
      {
        beforeEnter: requireAuth,
        path: 'AmexImport',
        name: 'Amex Import',
        component: AmexTransactionImport
      },
      {
        beforeEnter: requireAuth,
        path: 'ChaseCreditCard',
        name: 'Chase Credit Card Import',
        component: ChaseCreditCardImport
      },
      {
        beforeEnter: requireAuth,
        path: 'InvoiceListView/:invoiceId?',
        name: 'Invoices',
        component: InvoiceListView,
        props:
          (function (route) {
            return {
              invoiceId: Number(route.params.invoiceId),
            };
          })
      },
      {
        beforeEnter: requireAuth,
        path: 'NewInvoiceView',
        name: 'New Invoice',
        component: NewInvoiceView,
        props:
          (function (route) {
            return {
              invoice: route.params.invoice || {},
            };
          })
      },
      {
        beforeEnter: requireAuth,
        path: 'ReceivedEmailsAndInvoiceProcessingList',
        name: 'Received Email and Invoice Processing',
        component: ReceivedEmailsAndInvoiceProcessingList,
      },

    ]
  },
  {
    path: 'debt',
    name: 'Debt',
    component: {
      render(c) {
        return c('router-view',  {on: this.$listeners});
      }
    },
    children: [
      {
        beforeEnter: requireAuth,
        path: 'Instruments/:instrumentId?',
        name: 'Instrument',
        component: Instrument,
        props:
        (function (route) {
          return {
            instrumentId: Number(route.params.instrumentId),
          };
        })
      },
      {
        beforeEnter: requireAuth,
        path: 'InstrumentTransactionEdit/:transactionHeaderId?',
        name: 'InstrumentTransactionEdit',
        component: InstrumentTransactionEdit,
        props:
        (function (route) {
          return {
            transactionHeaderId: Number(route.params.transactionHeaderId),
          };
        })
      },
      {
        beforeEnter: requireAuth,
        path: 'PromissoryNoteListView',
        name: 'Promissory Notes',
        component: PromissoryNoteListView
      },
      {
        beforeEnter: requireAuth,
        path: 'AdvancedTermPromissoryNoteList',
        name: 'Advanced Term Promissory Notes',
        component: AdvancedTermPromissoryNoteList
      },
    ]
  },
  {
    path: 'assets',
    name: 'Assets',
    component: {
      render(c) {
        return c('router-view',  {on: this.$listeners});
      }
    },
    children: [
      {
        beforeEnter: requireAuth,
        path: 'Valuables',
        name: 'Valuables',
        component: ValuablesList
      },
      {
        beforeEnter: requireAuth,
        path: 'ValuablesEdit/:valuablesId?',
        name: 'Valuables Edit',
        component: ValuablesEdit,
        props:
        (function (route) {
          return {
            valuablesId: Number(route.params.valuablesId),
          };
        })
      },
      {
        beforeEnter: requireAuth,
        path: 'InvestmentTransactionImport',
        name: 'InvestmentTransactionImport',
        component: InvestmentTransactionImport
      },
      {
        beforeEnter: requireAuth,
        path: 'DividendSummaryList',
        name: 'Dividend Summary',
        component: DividendSummaryList
      },
      {
        beforeEnter: requireAuth,
        path: 'SpecificLotEditView/:transactionId',
        name: 'Specific Lot Edit',
        component: SpecificLotEditView,
        props:
        (function (route) {
          return {
            transactionId: Number(route.params.transactionId),
          };
        })
      },
      {
        beforeEnter: requireAuth,
        path: 'exchangeRateImport',
        name: 'Import FX Rates',
        component: ExchangeRateImport
      },
      {
        beforeEnter: requireAuth,
        path: 'ManualOHLC',
        name: 'Manual OHLC',
        component: ManualTickerList
      },
      {
        beforeEnter: requireAuth,
        path: 'OHLCRateImport',
        name: 'Public Equities Import',
        component: OHLCRateImport
      },
      {
        beforeEnter: requireAuth,
        path: 'JPMDailyImport',
        name: 'JPM Daily Import',
        component: JPMDailyImport
      },
      {
        beforeEnter: requireAuth,
        path: 'InvestmentTransactionTabView/:transactionId?',
        name: 'Investment Transaction View',
        component: InvestmentTransactionTabView,
        props: true
      },
      {
        beforeEnter: requireAuth,
        path: 'NewInvestmentTransaction/:newTransactionInvestmentId?',
        name: 'New Investment Transaction',
        component: NewInvestmentTransaction,
        props:
        (function (route) {
          return {
            newTransactionInvestmentId: Number(route.params.newTransactionInvestmentId)
          };
        })

      },
      {
        beforeEnter: requireAuth,
        path: 'TrancheHistory/:acquisitionSourceId?',
        name: 'Tranche History',
        component: TrancheHistory,
        props:
        (function (route) {
          return {
            acquisitionSourceId: Number(route.params.acquisitionSourceId)
          };
        })

      },      
      {
        beforeEnter: requireAuth,
        path: 'CapitalList/:investmentId?/:capitalTypeId?',
        name: 'Capital List',
        component: CapitalList,
        props:
          (function (route) {
            return {
              investmentId: Number(route.params.investmentId),
              capitalTypeId: Number(route.params.capitalTypeId)
            };
          })
      },
      {
        beforeEnter: requireAuth,
        path: 'CommitmentAnalysis/:investmentId?/:asOf?',
        name: 'Commitment Analysis',
        component: CommitmentAnalysis,
        props:
          (function (route) {
            return {
              investmentId: Number(route.params.investmentId),
              asOf: route.params.asOf,
            };
          })
      },
      {
        beforeEnter: requireAuth,
        path: 'TransactionTabView/:transactionHeaderId?',
        name: 'Investment Transaction',
        component: TransactionTabView,
        props:
          (function (route) {
            let th: number;
            if (route.params.transactionHeaderId){
              th = route.params.transactionHeaderId;
            }
            else if (route.query.transactionHeaderId){
              th = route.query.transactionHeaderId;
            }
            return {
              //transactionHeaderId: Number(route.params.transactionHeaderId)
              transactionHeaderId: Number(th)
            };
          })
      },
      {
        beforeEnter: requireAuth,
        path: 'StakeTransaction/:transactionHeaderId?',
        name: 'Stake Transaction',
        component: StakeTransaction,
        props:
          (function (route) {
            return {
              transactionHeaderId: Number(route.params.transactionHeaderId)
            };
          })
      },
      {
        beforeEnter: requireAuth,
        path: 'Investment/:investmentId?/:loadTransactionsTab?/:loadTransactionId?',
        name: 'Investment',
        component: Investment,
        props:
          (function (route) {
            return {
              investmentId: Number(route.params.investmentId),
              loadTransactionsTab: Boolean(route.params.loadTransactionsTab),
              loadTransactionId: Number(route.params.loadTransactionId)
            };
          })
      },
      {
        beforeEnter: requireAuth,
        path: 'InvestmentEdit/:investmentId?',
        name: 'InvestmentEdit',
        component: InvestmentEdit,
        props:
          (function (route) {
            return {
              investmentId: Number(route.params.investmentId)
            };
          })
      },
      {
        beforeEnter: requireAuth,
        path: 'InvestmentParentList/:investmentParentId?',
        name: 'Investment Parents',
        component: InvestmentParentList,
        props:
          (function (route) {
            return {
              investmentParentId: Number(route.params.investmentParentId)
            };
          })
      },
      {
        beforeEnter: requireAuth,
        path: 'InvestmentList/:clientId?/:investmentParentId?/:loadInvestmentId?/:loadTransactionsTab?',
        name: 'Investments',
        component: InvestmentList,
        props: true
      },
      {
        beforeEnter: requireAuth,
        path: 'FixedIncomeList/:clientId?/:ownerId?/:bankAccountId?',
        name: 'Fixed Income List',
        component: FixedIncomeList,
        props:
          (function (route) {
            return {
              clientId: Number(route.params.clientId)
              , ownerId: Number(route.params.ownerId)
              , bankAccountId: Number(route.params.bankAccountId)
            };
          })
      },
      {
        beforeEnter: requireAuth,
        path: 'FixedIncomeDashboard',
        name: 'Fixed Income Dashboard',
        component: FixedIncomeDashboard
      },
      {
        beforeEnter: requireAuth,
        path: 'FixedIncomeEdit/:fixedIncomeId?',
        name: 'Fixed Income',
        component: FixedIncomeEditView,
        props:
          (function (route) {
            return {
              fixedIncomeId: Number(route.params.fixedIncomeId)
            };
          })
      },
      {
        beforeEnter: requireAuth,
        path: 'ProcessLookThrough',
        name: 'Process Look Through',
        component: ProcessLookThrough
      },
      {
        beforeEnter: requireAuth,
        path: 'ProcessInvestmentStatistics',
        name: 'Process Investment Statistics',
        component: ProcessInvestmentStatistics
      },
      {
        beforeEnter: requireAuth,
        path: 'ProcessNonShareValuationStatistics',
        name: 'Process Non-Share Valuation/BS Analysis',
        component: ProcessNonShareValuationStatistics
      },
      {
        beforeEnter: requireAuth,
        path: 'MarketableSecuritiesTabsView/:marketableSecuritySaleId?',
        name: 'Sell Marketable Securities',
        component: MarketableSecuritiesTabsView,
        props:
          (function (route) {
            return {
              marketableSecuritySaleId: Number(route.params.marketableSecuritySaleId)
            };
          })
      },
      {
        beforeEnter: requireAuth,
        path: 'K1List',
        name: 'K1s',
        component: K1List,
      },
    ]
  },
  {
    path: 'aviation',
    name: 'Aviation',
    component: {
      render(c) {
        return c('router-view',  {on: this.$listeners});
      }
    },
    children: [
      {
        beforeEnter: requireAuth,
        path: 'trips',
        name: 'Trips',
        component: Trips
      },
      {
        beforeEnter: requireAuth,
        path: 'TripLog',
        name: 'Trip Log',
        component: TripLegEditList
      },
      {
        beforeEnter: requireAuth,
        path: 'TripInvoice/:invoiceId',
        name: 'Trip Invoice',
        component: TripInvoiceView,
        props:
          (function (route) {
            return {
              invoiceId: Number(route.params.invoiceId),
            };
          })
      },
      {
        beforeEnter: requireAuth,
        path: 'TripLeg/:tripLegId',
        name: 'Trip Leg',
        component: TripLegEdit,
        props:
          (function (route) {
            return {
              tripLegId: Number(route.params.tripLegId),
            };
          })
      },
      {
        beforeEnter: requireAuth,
        path: 'AviationImport',
        name: 'Aviation Import',
        component: AviationImport
      },
      {
        beforeEnter: requireAuth,
        path: 'JetDepreciationImport',
        name: 'Import Depreciation',
        component: JetDepreciationImport
      },
      {
        beforeEnter: requireAuth,
        path: 'SLSDaysInNYForecast',
        name: 'SLS Days In NY Forecast',
        component: SLSDaysInNYForecast
      }
    ]
  },
  {
    path: 'documents',
    name: 'Documents',
    component: {
      render(c) {
        return c('router-view',  {on: this.$listeners});
      }
    },
    children: [
      {
        beforeEnter: requireAuth,
        path: 'ingest',
        name: 'Ingest',
        component: IngestAttachment
      },
      {
        beforeEnter: requireAuth,
        path: 'list/:paramString?',
        name: 'List',
        component: AttachmentList,
        props: true
      },
      {
        beforeEnter: requireAuth,
        path: 'DocumentUseList',
        name: 'Document Use List',
        component: DocumentUseList
      },
      {
        beforeEnter: requireAuth,
        path: 'ViewDocument/:attachmentId?',
        name: 'View Document',
        component: ViewDocument,
        props:
          (function (route) {
            return {
              attachmentId: Number(route.params.attachmentId)
            };
          })
      }
    ]
  },
  {
    path: 'tax',
    name: 'Tax',
    component: {
      render(c) {
        return c('router-view',  {on: this.$listeners});
      }
    },
    children: [
      {
        beforeEnter: requireAuth,
        path: 'taxImport',
        name: 'Tax Import',
        component: TaxImport
      },
      {
        beforeEnter: requireAuth,
        path: 'taxConfiguration',
        name: 'Tax Configuration',
        component: TaxConfigurationList
      }
    ]
  },
  {
    path: 'masterData',
    name: 'Master Data',
    component: {
      render(c) {
        return c('router-view',  {on: this.$listeners});
      }
    },
    children: [
      {
        beforeEnter: requireAuth,
        path: 'entities/:initialEntityId?',
        name: 'Entities',
        component: EntityList,
        props: true
      },
      {
        beforeEnter: requireAuth,
        path: 'createEntity',
        name: 'Create Entity',
        component: EntityEdit,
        props: {
          onSave: entity => {
            location.href =
              '/masterData/entities/' + entity.Id;
          }
        }
      },
      {
        beforeEnter: requireAuth,
        path: 'EntityDocuments/:ownerId',
        name: 'Entity Agreement Preview',
        component: EntityDocuments,
        props: true
      },
      {
        beforeEnter: requireAuth,
        path: 'addresses',
        name: 'Addresses',
        component: AddressList
      },
      {
        beforeEnter: requireAuth,
        path: 'keyPeople',
        name: 'Key People',
        component: KeyPersonList
      },
      {
        beforeEnter: requireAuth,
        path: 'ownerCategories',
        name: 'Owner Categories',
        component: OwnerCategoryList
      },
      {
        beforeEnter: requireAuth,
        path: 'taxClasses',
        name: 'Tax Classes',
        component: TaxClassList
      },
      {
        beforeEnter: requireAuth,
        path: 'registeredAgents',
        name: 'Registered Agents',
        component: RegisteredAgentList
      },
      {
        beforeEnter: requireAuth,
        path: 'agreementGroups',
        name: 'Agreement Groups',
        component: AgreementGroupList
      },
      {
        beforeEnter: requireAuth,
        path: 'fundFamilies',
        name: 'Fund Families',
        component: FundFamilyList
      },
      {
        beforeEnter: requireAuth,
        path: 'FundHoldingsListView/:clientId?/:fundId?',
        name: 'Fund Holdings',
        component: FundHoldingsListView,
        props:
          (function (route) {
            return {
              clientId: Number(route.params.clientId),
              fundId: Number(route.params.fundId),
            };
          })
      },
      {
        beforeEnter: requireAuth,
        path: 'balanceSheetConfiguration/:configurationId?',
        name: 'Balance Sheet Configuration',
        component: BalanceSheetConfigView,
        props:
        (function (route) {
          return {
            configurationId: Number(route.params.configurationId),
          };
        })
      },
      {
        beforeEnter: requireAuth,
        path: 'ApproverSelectionList/:workflowId?/:clientId?/:vendorId?',
        name: 'Approver Selections',
        component: ApproverSelectionList,
        props:
          (function (route) {
            return {
              clientId: Number(route.params.clientId),
              workflowId: Number(route.params.workflowId),
              vendorId: Number(route.params.vendorId),
            };
          })
      },

    ]
  },
  {
    path: '/admin',
    name: 'Admin',
    component: Admin
  },
  {
    beforeEnter: requireAuth,
    path: 'Administration',
    name: 'Administration',
    component: {
      render(c) {
        return c('router-view',  {on: this.$listeners});
      }
    },
    children: [
      {
        beforeEnter: requireAuth,
        path: 'WorkflowApprovers',
        name: 'Manage Approvers',
        component: WorkflowList
      },
      {
        beforeEnter: requireAuth,
        path: 'ReportPermissions',
        name: 'Report Permissions',
        component: ReportPermissionList
      },
      {
        beforeEnter: requireAuth,
        path: 'ProcessBalanceSheetHistory',
        name: 'Run Balance Sheet Process',
        component: ProcessBalanceSheetHistory
      },
      {
        beforeEnter: requireAuth,
        path: 'ProcessBalanceSheetHistoryProforma',
        name: 'Run Balance Sheet Process PRO FORMA',
        component: ProcessBalanceSheetHistoryProforma
      },
      {
        beforeEnter: requireAuth,
        path: 'ExcelCellImportConfigurationMangement',
        name: 'Excel Cell Import Mgt',
        component: ExcelCellImportConfigurationMangement
      },
      {
        beforeEnter: requireAuth,
        path: 'EmailList',
        name: 'Email Log',
        component: EmailList
      },
      {
        beforeEnter: requireAuth,
        path: 'EventLog',
        name: 'Event Log',
        component: EventLogList
      },
      {
        beforeEnter: requireAuth,
        path: 'EventLogDetailView/:logId?',
        name: 'Event Log Detail' ,
        component: EventLogDetailView,
        props:
        (function (route) {
          return {
            logId: Number(route.params.logId),
          };
        })
      },
      {
        beforeEnter: requireAuth,
        path: 'ScaleDatabase',
        name: 'Scale Database',
        component: DatabaseScaleView
      },
      {
        beforeEnter: requireAuth,
        path: 'LayoutEdit',
        name: 'Layout Edit',
        component: LayoutEdit
      },
      {
        beforeEnter: requireAuth,
        path: 'FileMove',
        name: 'FileMove',
        component: FileMove
      },
      {
        beforeEnter: requireAuth,
        path: 'BulkCopy',
        name: 'BulkCopy',
        component: BulkCopy
      }
    ]
  },
  {
    beforeEnter: requireAuth,
    path: 'smartControl/:path/:controlName',
    name: ' ', // manually set in Sidebar.vue
    component: SmartControlLoader,
    props: true
  }
];

const appendToRouteNames = (arr: Array<any>, appendStr: string): Array<any> => {
  for (let i = 0; i < arr.length; i++) {
    const obj = arr[i];
    if (obj.children) {
      appendToRouteNames(obj.children, appendStr);
    }
    if (obj.name) {
      obj.name = obj.name + ' ' + appendStr;
    }
  }
  return arr;
};

const router = new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '/',
      redirect: '/welcome',
      name: 'Home',
      component: Full,
      beforeEnter: requireAuth,
      children: componentChildren
    },
    {
      path: '/fs',
      component: Fullscreen,
      beforeEnter: requireAuth,
      children: appendToRouteNames(cloneDeep(componentChildren), '(Fullscreen)')
    },
    {
      path: '/login',
      component: Login
    },
    {
      path: '/fs/login',
      component: Login
    },
    {
      path: '/public/static/authlanding.html',
    },
    {
      path: '*', // default here when none of the above matches
      component: PageNotFound
    }
  ]
});
router.beforeEach((to, from, next) => {
  // redirect to full screen if pushed from full screen.  
  // Note:  when you depend on this happening from a push, attach an empty catch to suppress the expected error returned by the redirect
  // see https://stackoverflow.com/questions/62223195/vue-router-uncaught-in-promise-error-redirected-from-login-to-via-a
  // example: this.$router.push({ name: 'Investment Transaction', params: { transactionHeaderId } }).catch(() => {});
  if (from.path.substr(0, 4) == '/fs/' && to.path.substr(0, 4) != '/fs/'){
    next({path: '/fs' + to.path});
  }
  else next();
})
export default router;

let entryUrl = undefined;

async function requireAuth(to, from, next) {
  // let isAuthenticated = await store.dispatch('isAuthenticatedTryRefresh');
  // console.log(store);
  if (!_auth.isLoggedIn() || !store.getters.isAuthenticated) {
    console.trace('router redirecting to login');
    entryUrl = to.path;
    if (to.query && Object.keys(to.query).length) {
        entryUrl += '?';
        for (const key in to.query) {
            if (to.query.hasOwnProperty(key)) {
                entryUrl += key + '=' + to.query[key] + '&';
            }
        }
        entryUrl = entryUrl.substring(0, entryUrl.length - 1); // remove trailing '&'
    }
    next({ path: '/login' });
  } else {
    document.title = to.meta.title || (to.name.indexOf('(Fullscreen)') > 1 && !!to.params.controlName) ? to.params.controlName : to.name || 'VAM';
    if (entryUrl) {
      const url = entryUrl;
      entryUrl = undefined;
      return next(url); // goto stored url
    } else {
      return next(); // go whereever it was going to go
    }
  }
}
