
import { Component, Vue, Prop, Watch, Ref } from "vue-property-decorator";
import * as AssetService from "@/services/DAL/assetService";
import * as AcctService from "@/services/DAL/acctService";
import cloneDeep from "lodash/cloneDeep";
import formatters from '../../utils/formatters';
import Common from "@/utils/common";
import ElementUI from 'element-ui';


declare function getStoredSecurityLevel(Id: number): number;
declare function tryParseInt(input: any, defaultValue: number): number;

@Component
export default class InvestmentGeneralLedgerStatistics extends Vue {
    @Ref() readonly refInvestmentGeneralLedgerStatistics!: ElementUI.Table;
    //#region Private declarations for Services
    private _acctService: AcctService.AcctService;
    private formatters: formatters = new formatters();
    private common: Common;
    //#endregion Private declarations for Services

    //#region Props
    @Prop({required: true, type: Object}) readonly investment: AssetService.InvestmentList;
    @Prop({ type: Number, default: null }) readonly securityLevel: number;

    //#endregion Props

    //#region Data

    securityLevel_: number = null;
    investment_ = {} as AssetService.InvestmentList;
    loading: boolean = false;
    investmentGeneralLedgerStatistics = [] as AcctService.InvestmentGeneralLedgerStatistics[];

//#endregion Data

  //#region Lifecycle
  async created() {
    this._acctService = new AcctService.AcctService();
    this.common = new Common();
    this.securityLevel_ = tryParseInt(
      getStoredSecurityLevel(this.$namedKey.SecurityView.ManageAssets),
      0
    );
    if (this.investment && Object.keys(this.investment).length) {
      this.investment_ = cloneDeep(this.investment);
    }
    else {
        this.$notify.error('Investment object required');
    }
    if (this.investment_){
      this.fetchInvestmentGeneralLedgerStatistics()
    }
  }
  async mounted() {
  }
//#endregion Lifecycle

//#region watches

//#endregion

//#region computed
get statistics(): AcctService.InvestmentGeneralLedgerStatistics {
  if (this.investmentGeneralLedgerStatistics && this.investmentGeneralLedgerStatistics.length == 1){
    return this.investmentGeneralLedgerStatistics[0];
  }
  else return {} as AcctService.InvestmentGeneralLedgerStatistics;
}

//#endregion

  async fetchInvestmentGeneralLedgerStatistics(){
    this.loading = true;
    const params = {} as AcctService.GetInvestmentGeneralLedgerStatisticsParameters;
    params.InvestmentId = this.investment_.InvestmentId;
    this.investmentGeneralLedgerStatistics = await this._acctService.GetInvestmentGeneralLedgerStatistics(params);
    this.loading = false;
  }



}

