
    import { Component, Vue, Prop, Watch, Ref } from "vue-property-decorator";
    import * as TransactionsService from '@/services/DAL/transactionsService';
    import * as DebtService from '@/services/DAL/debtService';
    import ConsiderationList from '@/views/InvestmentTransaction/Consideration/ConsiderationList.vue';
    import InvestmentSelector from '@/components/form/InvestmentSelector.vue';
    import cloneDeep from 'lodash/cloneDeep';
    import Common from '@/utils/common';
    import { Field } from "node_modules/vee-validate/types";
    import VueMarkdown from '@adapttive/vue-markdown'
    import EquityTransInvestment from '@/views/InvestmentTransaction/Equity/EquityTransactionInvestment.vue';

import ElementUI from "element-ui";

    declare function getStoredSecurityLevel(Id: number): number;
    declare function tryParseInt(input: any, defaultValue: number): number;
    declare var SmartObject: any;
    interface iValuePremiumDiscount{
            baseConsumption: number
            , consideration: number
            , amount: number
    }

    type ConsiderationsType = DebtService.InstrumentTransactions | TransactionsService.Equity | TransactionsService.Cash | TransactionsService.Capital | TransactionsService.CreditNote | TransactionsService.Expense;
    interface EquityTransactionInvestment extends TransactionsService.TransactionInvestments {
        isNew: boolean,
        equityTransactionTranches: TransactionsService.EquityTransactionTranches[],
    }

    @Component({
        components: {
            ConsiderationList,
            InvestmentSelector,
            EquityTransInvestment,
            //@ts-ignore
            VueMarkdown
        }
    })
    export default class EquityTransaction extends Vue {
    @Ref() readonly refConsiderationList!: ConsiderationList;

    //#region Private declarations for Services
    private _transactionsService: TransactionsService.TransactionsService;
    private common: Common;

    //#endregion Private declarations for Services

    //#region Props
    @Prop( { type: Object }) header: TransactionsService.Header;
    @Prop( { type: Number }) transactionHeaderId: number;
    //#endregion Props

    //#region Data
    loading = false;
    securityLevel_: number = null;
    showHeaderNotes = false;
    
    considerations: Array<ConsiderationsType > = [];
    counterCash: Array<TransactionsService.Cash> = [];
    checkingCounterCash: boolean = false;

    header_ = {} as TransactionsService.Header;
    equityTransactionInvestments = [] as EquityTransactionInvestment[];
    
    showAttachmentList: boolean = true;
    showChangeLogList: boolean = true;
    selectedTab: string = '1';
    
    //#endregion Data

    //#region Lifecycle
    async created() {
        this._transactionsService = new TransactionsService.TransactionsService();
        this.common = new Common();

        this.securityLevel_ = tryParseInt(
            getStoredSecurityLevel(this.$namedKey.SecurityView.ManageAssets),
            0
        );
        if (!this.header || Object.keys(this.header).length == 0 || this.header.SubTypeShort == undefined){
            await this.getTransactionHeader();
        }
        else {
            this.header_ = cloneDeep(this.header)
        }
        await this.getTransactionInvestments();
        this.checkingCounterCash = true;
        await this.$nextTick();

    }
    //#endregion Lifecycle

    //#region Watches
    @Watch('header')
    async headerChanged(val: TransactionsService.Header, oldVal: TransactionsService.Header) {
        const idChanged = (oldVal.Id != val.Id);
        this.header_ = cloneDeep(this.header);
        // if (idChanged) this.getEquity();
    }
    
    //#endregion Watches

    //#region Computed
    get isFormValid(): boolean{
        const formValid = !(Object.keys((this as any).veeFields).some(
            key => (this as any).veeFields[key].invalid
        ));
        if (!formValid) return formValid;

        if (this.outOfBalance) return false;
        if (!this.baseTranches || !this.baseTranches.length) return false;
        if (this.operation == 'Swap' && (!this.swapTranches || !this.swapTranches.length)) return false;

        if (!this.baseConsumptionValue || this.baseConsumptionValue == 0) return false;
        if (!this.totalConsideration || this.totalConsideration == 0 ) return false;

        return true;
    }

    get baseInvestmentRecords(): EquityTransactionInvestment[]{
        return this.equityTransactionInvestments.filter(eti => !eti.IsSwap);
    }
    get swapInvestmentRecords(): EquityTransactionInvestment[]{
        return  this.equityTransactionInvestments.filter(eti => eti.IsSwap);
    }
    get baseTranches(): TransactionsService.EquityTransactionTranches[]{
        const mapRecords = this.baseInvestmentRecords.map(eti => eti.equityTransactionTranches)
        return mapRecords.flat();
    }
    get swapTranches(): TransactionsService.EquityTransactionTranches[]{
        const mapRecords = this.swapInvestmentRecords.map(eti => eti.equityTransactionTranches)
        return mapRecords.flat();
    }
    get operation(): string{
        if (this.header_){
            return this.header_.SubTypeShort;
        }
    }
    get operationDirection(): string{
        if (this.header_){
            return this.header_.SubTypeBaseDirection;
        }
    }
    get noConsideration(): boolean {
        return (this.header && this.header.SubTypeNoConsideration);
    }
    get useConsideration(): boolean {
        return !this.noConsideration; 
    }

    get isTransferOrSwap(): boolean{
        return (this.operation == 'Transfer' || this.operation == 'Swap') && !(this.header && this.header.SubTypeScope == '3rd Party');
    }
    get isSplitExchange(): boolean{
        return (this.operation == 'Split Exchange');
    }


    get considerationDirection(): string{
        return this.oppositeDirection(this.operationDirection);
    }
    get totalConsideration(): number{
        let otherConsideration: number = 0;
        if (this.considerations && this.considerations.length){
            otherConsideration = this.considerations.reduce(function(a, b) {
                return a + b.Amount;
            }, 0)
        }
        return otherConsideration + this.swapConsiderationValue;
    }

    get swapConsiderationValue(): number{
        let result: number;
        if (this.operation == 'Swap' && this.swapTranches && this.swapTranches.length){
            result =  this.swapTranches.reduce(function(a, b) {
                return (a || 0) + b.CurrentConsumptionValue;
            }, 0)
        }
        else result = 0;
        return 0-result; //flip sign because the Swap is always in the "Source" direction

    }
    get baseConsumptionValue(): number{
        let result: number;
        if (this.baseTranches && this.baseTranches.length){
            result =  this.baseTranches.reduce(function(a, b) {
                return (a || 0) + b.CurrentConsumptionValue;
            }, 0)
        }
        else result = 0;
        if (this.operationDirection == 'Source'){ // so far, all Equity SubTypes are source (only Capital Stake Purchase is a Result); i.e. everything is getting flipped
            return 0-result;
        }
        else return result;
        
    }
    get baseConsumptionCost(): number{
        let result: number;
        if (this.baseTranches && this.baseTranches.length){
            result =  this.baseTranches.reduce(function(a, b) {
                return (a || 0) + b.CurrentConsumptionCost;
            }, 0)
        }
        else result = 0;
        if (this.operationDirection == 'Source'){ // so far, all Equity SubTypes are source (only Capital Stake Purchase is a Result); i.e. everything is getting flipped
            return 0-result;
        }
        else return result;
        
    }
    get equityTransferredAmount(): number {
        // note: this would seem like it just double flips from above.  But everything (see note above) is flipped there, and here only flipped back for Selling (return) shares.
        // also note: as of 9/15/21, selling shares is not yet tested in this method.
        // 10/12/21: testing for selling shares.  Add additional caveat to only flip when isTransferOrSwap
        return this.operation == 'Sell' && this.isTransferOrSwap ? 0 - this.baseConsumptionValue : this.baseConsumptionValue; // could be Buy or Transfer to not  flip the sign.
    }
    get baseConsumptionFMV(): number{
        let result: number;
        if (this.baseTranches && this.baseTranches.length){
            result =  this.baseTranches.reduce(function(a, b) {
                return (a || 0) + (b.CurrentConsumptionQuantity * b.FMVPerQuantity) ;
            }, 0)
        }
        else result = 0;
        if (this.operationDirection == 'Source'){
            return 0-result;
        }
        else return result;
    }
    get valuePremiumDiscountTransferSwap(): iValuePremiumDiscount{
        const consideration = this.operation == 'Buy' ? 0 - this.totalConsideration : this.totalConsideration;
        const amount = this.operation == 'Sell' ? 0 - (this.baseConsumptionFMV - consideration) : this.baseConsumptionFMV - consideration
        return{
            baseConsumption: this.baseConsumptionFMV
            , consideration: consideration
            , amount: amount
        }
    }
    get valuePremiumDiscount(): iValuePremiumDiscount{ // i.e. not Transfer/Swap
        // 10/12/21: this has only been tested for non-Swap/Trans Sell.  There shouldn't explicitly be "Buy" types outside Trans/Swap, but maybe something else could fall into that.
        const consideration = this.totalConsideration;
        const amount = consideration -  this.baseConsumptionCost;
        return{
            baseConsumption: this.baseConsumptionCost
            , consideration: consideration
            , amount: amount
        }
    }
    get ownerIdList(): Array<number>{
        const ret = [];
        if (this.header_ && this.header_.BaseOwnerId) ret.push(this.header_.BaseOwnerId);
        if (this.header_ && this.header_.TargetOwnerId) ret.push(this.header_.TargetOwnerId);
        return ret;
    }
    
    get outOfBalance(): boolean{
        if (this.noConsideration) return false; // no consideration, so cannot be out of balance
        if (this.header_ && this.header_.SubTypeScope == 'Investment') return false; // scope Investment can't be out of balance because there is no consideration.
        if (this.header_ && this.header_.SubTypeScope == 'Self') return false; // scope Self can't be out of balance because there is no consideration.
        if (!this.totalConsideration || !this.equityTransferredAmount || isNaN(this.equityTransferredAmount) || isNaN(this.totalConsideration)) return true;

        return (!!this.totalConsideration && !!this.equityTransferredAmount && Math.abs(this.totalConsideration - this.equityTransferredAmount) > 1)

    }
    get showCounterCash(): boolean{
        if (this.checkingCounterCash) return true;
        if (this.header_ && this.header_.SubTypeScope == 'Owner' && this.considerations && this.considerations.length && this.considerations.some(consideration => consideration.ConsiderationType == 'Cash')) return true;
        else return false;
    }
    //#endregion Computed


    //#region Methods
    oppositeDirection (direction: string): string{
        if (direction == 'Result') return 'Source';
        if (direction == 'Source') return 'Result';
    }
     
    async getTransactionHeader(){
        this.header_ = {} as TransactionsService.Header;
        if (this.transactionHeaderId || (this.header && this.header.Id)){
            const params = {} as TransactionsService.GetHeaderParameters;
            params.HeaderId = this.transactionHeaderId || this.header.Id; // might be refetching it to get the ful dataset if it was passed in from new
            this.header_ = (await this._transactionsService.GetHeader(params))[0];
        }
    }
    async getTransactionInvestments(){
        this.equityTransactionInvestments = [] as EquityTransactionInvestment[];
        let equityTransactionInvestments = [] as TransactionsService.TransactionInvestments[];
        this.loading = true;
        const params = {} as TransactionsService.GetTransactionInvestmentsParameters;
        params.TransactionHeaderId = this.header_.Id;
        equityTransactionInvestments = await this._transactionsService.GetTransactionInvestments(params);
        this.equityTransactionInvestments = equityTransactionInvestments.map(eti => ({...eti, isNew: true, equityTransactionTranches: [] as TransactionsService.EquityTransactionTranches[] }));
        if (!this.baseInvestmentRecords || !this.baseInvestmentRecords.length){
            this.addNewEquityTransactionInvestment(false, this.header_.BaseInvestmentId);
        }
        if (this.operation == 'Swap' && (!this.swapInvestmentRecords || !this.swapInvestmentRecords.length)){
            this.addNewEquityTransactionInvestment(true);
        }
        this.loading = false;
    }

    considerationListUpdated(considerations: Array<ConsiderationsType >){
        this.considerations = considerations;
    }
    counterCashUpdated(counterCash: Array<TransactionsService.Cash>){
        this.counterCash = counterCash;
        if (this.checkingCounterCash && !(this.counterCash && this.counterCash.length)){
            this.checkingCounterCash = false;
        }
        
    }
    addNewEquityTransactionInvestment(isSwap: boolean, defaultInvestmentId: number |  null = null){
        const newInvestments: EquityTransactionInvestment = {
            isNew: true,
            IsSwap: isSwap,
            SourceInvestmentId: defaultInvestmentId,
            TargetInvestmentId: null,
            equityTransactionTranches: [] as TransactionsService.EquityTransactionTranches[]
        }
        this.equityTransactionInvestments.push(newInvestments);
    }
    deleteInvestmentRecord(record: EquityTransactionInvestment){
        const isSwap = record.IsSwap;
        const index = this.equityTransactionInvestments.indexOf(record);
        this.equityTransactionInvestments.splice(index, 1);
        if ((isSwap && !this.swapInvestmentRecords.length) || (!isSwap && !this.baseInvestmentRecords.length)){
            this.addNewEquityTransactionInvestment(isSwap); //if just deleted the last record, add a new empty record
        }
    }
    showRemoveInvestment(record: EquityTransactionInvestment): boolean{
        if (!record || !record.equityTransactionTranches || !record.equityTransactionTranches.length){
            return true;
        }
        else { // if (record && record.equityTransactionTranches && record.equityTransactionTranches.length){
            const result =  record.equityTransactionTranches.reduce(function(a, b) {
                return (a || 0) + b.CurrentConsumptionQuantity;
            }, 0)
            return !result;
        }
    }
//#endregion Methods

    }
    
