
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import * as LiqService from './../../services/DAL/liq_Service';
import * as VamService from '../../services/DAL/vamService';
import Common from '../../utils/common';

declare var SmartObject: any;
declare function tryParseInt(input: number, deflt: number): number;
declare function getStoredSecurityLevel(Id: number): number;

@Component
export default class AgreementGroupEdit extends Vue {
  //#region Private declarations for Services
  private _vam_Service: VamService.VamService;
  private _liq_Service: LiqService.LiqService;
  private _common: Common;
  //#endregion Private declarations for Services

  //#region Props
  @Prop() agreementGroup: LiqService.AgreementGroups;
  @Prop() onClose: Function;
  @Prop() onSave: Function;
  @Prop() onDelete: Function;
  @Prop() viewType: string;
  @Prop({
    type: Number,
    default: null
  })
  securityLevel: number;
  //#endregion Props

  //#region Data
  clients = [] as VamService.UserClients[];
  vendors = [] as VamService.Vendors[];
  clientPreset: boolean = false;
  securityLevel_: number = this.securityLevel;

  agreementGroup_: LiqService.AgreementGroups = {
    AgreementGroupId: null,
    Name: null,
    VendorId: null,
    VendorSpecific: null,
    VendorName: null,
    ClientId: null,
    ClientName: null,
    Label: null,
    AggregateUnits: null,
    UnitsLabel: null,
    NotifyThresholdMinimum: null,
    NotifyEmail: null,
    NumAgreements: null,
    Deletable: null
  };

  get isNew(): boolean {
    return !this.agreementGroup || !Object.keys(this.agreementGroup).length;
  }
  get isFormDirty(): boolean {
    return Object.keys((this as any).veeFields).some(
      key => (this as any).veeFields[key].dirty
    );
  }
  //#endregion Data

  //#region Lifecycle
  created() {
    if (this.agreementGroup && Object.keys(this.agreementGroup).length) {
      this.agreementGroup_ = this.agreementGroup;
    }
    if (this.securityLevel_ === null) {
      this.securityLevel_ = tryParseInt(
        getStoredSecurityLevel(this.$namedKey.SecurityView.ManageAP),
        0
      );
    }
    this._liq_Service = new LiqService.LiqService();
    this._vam_Service = new VamService.VamService();
    this._common = new Common();
    this.fetchClients();
  }
  mounted() {
    this.clientPreset = !!this.agreementGroup_.ClientId;
    this.fetchVendors();
  }
  //#endregion Lifecycle

  //#region Watches
  @Watch('agreementGroup_.ClientId')
  onSelectedClientChange(val: object, oldVal: object) {
    this.fetchVendors();

    if (this.clients.length) {
      let selectedClient = null;
      if (this.agreementGroup_.ClientId > 0) {
        selectedClient = this._common.getSelectedArrayItem(
          this.clients,
          this.agreementGroup_.ClientId.toString(),
          'Id'
        );
      }

      if (selectedClient) {
        this.agreementGroup_.ClientName = selectedClient.Name;
      } else {
        this.agreementGroup_.ClientName = null;
      }
    }
  }
  //#endregion Watches

  //#region Methods
  async fetchClients() {
    this.clients = await this._vam_Service.GetUserClients();
  }
  async fetchVendors() {
    this.vendors = [];
    const getVendorsParameters = {} as VamService.GetVendorsParameters;
    getVendorsParameters.ClientId = this.agreementGroup_.ClientId;
    this.vendors = await this._vam_Service.GetVendors(getVendorsParameters);
  }
  async saveForm() {
    const form: any = this.$refs.frmAgreementGroupEdit;
    form.validate(async valid => {
      if (valid) {
        let message: string;

        if (this.isNew) {
          this.agreementGroup_.AgreementGroupId = await new SmartObject(
            'AgreementGroup'
          ).createObject(this.agreementGroup_);
          message = 'Agreement group added.';
        } else {
          await new SmartObject(
            'AgreementGroup',
            this.agreementGroup_.AgreementGroupId
          ).updateObject(this.agreementGroup_);
          message = 'Changes saved.';
        }

        this.$notify.success(message);
        this.$emit('saved', this.agreementGroup_);

        if (typeof this.onSave === 'function') {
          await this.onSave(this.agreementGroup_.AgreementGroupId);
        }

        if (this.isNew) {
          this.closeDialog();
        }
      } else {
        this.$message.error('Not Saved.  See Validation Errors.');
        return false;
      }
    });
  }

  async deleteAgreementGroup() {
    if (!this.isNew) {
      try {
        await this.$confirm(
          'This will permanently delete this agreement gruop. Continue?',
          'Warning',
          {
            confirmButtonText: 'OK',
            cancelButtonText: 'Cancel',
            type: 'warning'
          }
        );

        try {
          await new SmartObject(
            'AgreementGroup',
            this.agreementGroup_.AgreementGroupId
          ).deleteObject();

          this.$notify.success('Agreement group deleted.');
          this.$emit('deleted', this.agreementGroup_);

          if (typeof this.onDelete === 'function') {
            await this.onDelete(this.agreementGroup_.AgreementGroupId);
            this.onClose_();
          }
        } catch (err) {
            try {
                const errJson = JSON.parse(err.responseText);
                if (errJson.ExceptionMessage.indexOf('cannot be deleted') > -1) {
                    this.$notify.error(errJson.ExceptionMessage);
                }
                else {
                    this.$notify.error('Something went wrong processing your request, please try again.');
                }
            }
            catch {
                this.$notify.error('Something went wrong processing your request, please try again.');
            }
        }
      } catch {}
    }
  }

  onClose_() {
    if (typeof this.onClose === 'function') {
      this.onClose();
    }
  }

  closeDialog() {
    this.$emit('close-dialog');
    this.onClose_();
  }
  //#endregion Methods
}
