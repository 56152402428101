
import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch, Prop, Ref } from 'vue-property-decorator';
import * as AssetService from '../../services/DAL/assetService';
import FixedIncomeEdit from '@/views/FixedIncome/FixedIncomeEdit.vue';
import FixedIncomeBankTransactionList from '@/views/FixedIncome/FixedIncomeBankTransactionList.vue';
import AttachmentList from '@/js/Attachments/AttachmentList.vue';
import ChangeLogList from '@/components/other/tsChangeLogList.vue';
import formatters from '../../utils/formatters';
import ElementUI from 'element-ui';
import EntityJournalVouchers from '@/views/GeneralLedger/EntityJournalVouchers.vue'

declare var $: any;
declare function getStoredSecurityLevel(Id: number): number;
declare function tryParseInt(value: any, defaultValue: any): any;

@Component({
    components: {
        FixedIncomeEdit,
        FixedIncomeBankTransactionList,
        AttachmentList,
        ChangeLogList,
        EntityJournalVouchers
    }
})
export default class FixedIncomeEditView extends Vue {
    @Ref() readonly divFixedIncomeEditView!: ElementUI.Card;
    @Ref() readonly refGeneralLedger!: EntityJournalVouchers;
    //#region Private Declarations
    private _assetService: AssetService.AssetService;
    private formatters: formatters;
    //#endregion Private Declarations

    //#region Props
    @Prop() fixedIncome: AssetService.FixedIncome;
    @Prop() fixedIncomeId: number;
    @Prop() viewType: string;
    //#endregion Props

    //#region Data
    fixedIncome_ =  {} as AssetService.FixedIncome;
    fixedIncomeId_: number = null;
    hasLoaded_parents: boolean = false;
    showTransactionList: boolean = true;
    showAttachmentList: boolean = false;
    showChangeLogList: boolean = false;
    securityLevel_: number = getStoredSecurityLevel(this.$namedKey.SecurityView.ManageAssets);
    fullscreen = false;
    includeCashGeneralLedger = false;
    //#endregion Data

    //#region Lifecycle
    async created() {
        this._assetService = new AssetService.AssetService();
        this.formatters = new formatters();

        if (this.fixedIncome && Object.keys(this.fixedIncome).length && this.fixedIncome.Id > 0) {
            this.fixedIncome_ = this.fixedIncome;
            this.fixedIncomeId_ = this.fixedIncome.Id;
        }
        else if (this.fixedIncomeId) {
            this.fixedIncomeId_ = this.fixedIncomeId;
            await this.fetchFixedIncome();
        }
    }
    //#endregion Lifecycle

    //#region Methods
    onTabClick(tab) {
        switch (tab.label) {
            case 'Valuation':
                var $ucValuationList = $(this.divFixedIncomeEditView.$el).find('#ucValuationList');
                if (!$ucValuationList.hasClass('loaded')) {
                    $ucValuationList.fetchSmartControl({
                        params: {
                            EntityType: 'FixedIncome'
                            , EntityId: this.fixedIncomeId_
                            , CanEdit: tryParseInt(getStoredSecurityLevel(this.$namedKey.SecurityView.ManageAssets), 0) >= this.$namedKey.SecurityLevel.Admin
                        }
                        , collapsible: false
                        , showSpinner: true
                    });
                    $ucValuationList.addClass('loaded');
                }
                break;
            case 'Documents':
                this.showAttachmentList = true;
                break;
            case 'Change Log':
                this.showChangeLogList = true;
                break;
            case 'Accounting':
                this.refGeneralLedger.fetchJVs();
                break;
        }
    }

    onSave(fixedIncome) {
        this.refreshTransactionList();
        this.refreshChangeLogList();
        this.$emit('save', fixedIncome);
    }

    async fetchFixedIncome() {
        const params = {} as AssetService.GetFixedIncomeParameters;
        params.FixedIncomeId = this.fixedIncomeId_;
        const fixedIncomes = await this._assetService.GetFixedIncome(params);
        if (fixedIncomes.length) {
            this.fixedIncome_ = fixedIncomes[0];
        }
    }

    async refreshTransactionList() {
        this.showTransactionList = false;
        await this.$nextTick();
        this.showTransactionList = true;
    }

    async refreshChangeLogList() {
        this.showChangeLogList = false;
        await this.$nextTick();
        this.showChangeLogList = true;
    }
    //#endregion
}
