// TS API for Schema debt
// Auto-generated 06/25/24 15:06


declare var smartData: any;
// output classes:
export class AdvancedTermPromissoryNotes {
    Id: number=undefined;
    Name: string=undefined;
    LenderOwnerEntityId: number=undefined;
    LenderDescription: string=undefined;
    LenderClientId: number=undefined;
    DebtorOwnerEntityId: number=undefined;
    DebtorDescription: string=undefined;
    DebtorClientId: number=undefined;
    RateInformation: string=undefined;
    PaymentFrequency: string=undefined;
    CompoundingFrequency: string=undefined;
    MaturityDate: string=undefined;
    MaturityDescription: string=undefined;
    Amount: number=undefined;
    CreateUser: string=undefined;
    CreateDate: string=undefined;
    ModifyUser: string=undefined;
    ModifyDate: string=undefined;
    SysStartTime: string=undefined;
    SysEndTime: string=undefined;
    InceptionDate: string=undefined;
    Deletable: boolean=undefined;
    Notes: number=undefined;
    Created: string=undefined;
    Modified: string = undefined;
}
export class Instruments {
    Id: number=undefined;
    Name: string=undefined;
    Description: string=undefined;
    StatusId: number=undefined;
    TypeId: number=undefined;
    Type: string=undefined;
    ClientId: number=undefined;
    OwnerEntityId: number=undefined;
    OwnerEntity: string=undefined;
    GrantorType: string=undefined;
    GrantorEntityId: number=undefined;
    CounterParty: string=undefined;
    AccountNumber: string=undefined;
    GeneralAssetId: number=undefined;
    InvestmentId: number=undefined;
    Amount: number=undefined;
    InceptionDate: string=undefined;
    RateSpread: number=undefined;
    RateBasis: string=undefined;
    Transactions: number=undefined;
    Deletable: boolean=undefined;
    PayFromBankAccountId: number=undefined;
    DrawIntoBankAccountId: number=undefined;
    GrantorBankAccountId: number = undefined;
}
export class InstrumentTransactions {
    Id: number=undefined;
    InstrumentId: number=undefined;
    Instrument: string=undefined;
    TypeId: number=undefined;
    TransactionType: string=undefined;
    OwnerEntityId: number=undefined;
    CalculatedOwnerEntityId: number=undefined;
    Date: string=undefined;
    Amount: number=undefined;
    ConsiderationValue: number=undefined;
    Principal: number=undefined;
    Interest: number=undefined;
    Comment: string=undefined;
    Source: string=undefined;
    SourceInstrumentTransactionId: number=undefined;
    TransactionHeaderId: number=undefined;
    HeaderUpdated: string=undefined;
    Description: string=undefined;
    BaseDirection: string=undefined;
    ConsiderationDirection: string=undefined;
    ConsiderationType: string=undefined;
    ConsiderationTypeId: number=undefined;
    ConsiderationDescription: string=undefined;
    LastModified: string=undefined;
    Created: string=undefined;
    Modified: string=undefined;
    InvestmentId: number = undefined;
}
export class InstrumentTransactionType {
    Id: number=undefined;
    Name: string=undefined;
    PaymentOrDraw: string=undefined;
    PrincipalOrInterest: string=undefined;
    AllowNegative: boolean=undefined;
    ConsiderationOption: string=undefined;
    CoAId: number=undefined;
    AutomaticTransactionTypeId: number=undefined;
    BaseDirection: string=undefined;
    RequireNegativeAndDisplayPositive: boolean = undefined;
}
export class InstrumentTypes {
    Id: number=undefined;
    Name: string=undefined;
    IsInvestment: boolean=undefined;
    IsSAFE: boolean=undefined;
    IsMortgage: boolean=undefined;
    IsRevolver: boolean=undefined;
    IsAnnuity: boolean=undefined;
    IsPromissoryNote: boolean=undefined;
    IsIntraClient: boolean=undefined;
    GrantorType: string = undefined;
}
export class PromissoryNotes {
    PromissoryNoteId: number=undefined;
    AdvancedTermPromissoryNoteId: number=undefined;
    AdvanceTermPromissoryNoteName: string=undefined;
    LenderClientId: number=undefined;
    LenderOwnerEntityId: number=undefined;
    DebtorClientId: number=undefined;
    DebtorOwnerEntityId: number=undefined;
    Name: string=undefined;
    Description: string=undefined;
    InterestRate: number=undefined;
    RateInformation: string=undefined;
    Amount: number=undefined;
    AmountIsEstimate: boolean=undefined;
    PaymentFrequency: string=undefined;
    CompoundingFrequency: string=undefined;
    CompoundingPerPeriod: number=undefined;
    EffectiveInterestRate: number=undefined;
    InceptionDate: string=undefined;
    InceptionDateForPaymentDateCalculation: string=undefined;
    MaturityDate: string=undefined;
    MaturityDescription: string=undefined;
    Periods: number=undefined;
    CompoundingPeriods: number=undefined;
    CompoundingFrequencyCount: number=undefined;
    CompoundingFrequencyCountType: string=undefined;
    LenderReportingCategory: string=undefined;
    DebtorReportingCategory: string=undefined;
    CoAIdAsset: number=undefined;
    CoAIdLiability: number=undefined;
    CoAIdInterestIncome: number=undefined;
    CoAIdInterestExpense: number=undefined;
    CoAIdAccruedInterestPayable: number=undefined;
    CoAIdAccruedInterestReceivable: number=undefined;
    CreateUser: string=undefined;
    CreateDate: string=undefined;
    ModifyUser: string=undefined;
    ModifyDate: string=undefined;
    Created: string=undefined;
    Modified: string = undefined;
}
export class PromissoryNoteSchedule {
    Id: number=undefined;
    PromissoryNoteId: number=undefined;
    AsAt: string=undefined;
    Type: string=undefined;
    Comment: string=undefined;
    CompoundingPeriodNumber: number=undefined;
    PromissoryNoteTransactionId: number=undefined;
    PeriodStart: string=undefined;
    PeriodEnd: string=undefined;
    TransactionDate: string=undefined;
    AccruedInterest: number=undefined;
    Payment: number=undefined;
    BeginningBalance: number=undefined;
    EndingBalance: number=undefined;
    NumDays: number=undefined;
    InterestRate: number=undefined;
    JVInterestAccrued: number=undefined;
    JVInterestPaid: number=undefined;
    JVPrincipalPaid: number=undefined;
    Editable: boolean = undefined;
    SubequentTransactions: number = undefined;
    Amount: number = undefined;
    Source: string = undefined;
    CreateUser: string=undefined;
    CreateDate: string=undefined;
    ModifyUser: string=undefined;
    ModifyDate: string=undefined;
    Created: string=undefined;
    Modified: string=undefined;
}

// Parameter interfaces:

export interface GetAdvancedTermPromissoryNotesParameters {
    ClientId: number;
    LenderOwnerEntityId: number;
    DebtorOwnerEntityId: number;
    AdvancedTermPromissoryNoteId: number;
}

export interface GetInstrumentsParameters {
    InstrumentId: number;
    InvestmentId: number;
    TypeId: number;
    OwnerEntityId: number;
}

export interface GetInstrumentTransactionsParameters {
    InstrumentId: number;
    TransactionHeaderId: number;
    Direction: string;
}

export interface GetInstrumentTransactionTypeParameters {
    InstrumentId: number;
    Direction: string;
    TransactionHeaderId: number;
}

export interface GetInstrumentTypesParameters {
    IsInvestment: boolean;
}

export interface GetPromissoryNotesParameters {
    ClientId: number;
    LenderOwnerEntityId: number;
    DebtorOwnerEntityId: number;
    AdvancedTermPromissoryNoteId: number;
}

export interface GetPromissoryNoteScheduleParameters {
    PromissoryNoteId: number;
    AsOf: string;
}

export class DebtService {

  GetAdvancedTermPromissoryNotes (params: GetAdvancedTermPromissoryNotesParameters): Promise<AdvancedTermPromissoryNotes[]> {
    return smartData({
        storedProcName: 'debt.GetAdvancedTermPromissoryNotes',
        params: params
      });
    }

  GetInstruments (params: GetInstrumentsParameters): Promise<Instruments[]> {
    return smartData({
        storedProcName: 'debt.GetInstruments',
        params: params
      });
    }

  GetInstrumentTransactions (params: GetInstrumentTransactionsParameters): Promise<InstrumentTransactions[]> {
    return smartData({
        storedProcName: 'debt.GetInstrumentTransactions',
        params: params
      });
    }

  GetInstrumentTransactionType (params: GetInstrumentTransactionTypeParameters): Promise<InstrumentTransactionType[]> {
    return smartData({
        storedProcName: 'debt.GetInstrumentTransactionType',
        params: params
      });
    }

  GetInstrumentTypes (params: GetInstrumentTypesParameters): Promise<InstrumentTypes[]> {
    return smartData({
        storedProcName: 'debt.GetInstrumentTypes',
        params: params
      });
    }

  GetPromissoryNotes (params: GetPromissoryNotesParameters): Promise<PromissoryNotes[]> {
    return smartData({
        storedProcName: 'debt.GetPromissoryNotes',
        params: params
      });
    }

  GetPromissoryNoteSchedule (params: GetPromissoryNoteScheduleParameters): Promise<PromissoryNoteSchedule[]> {
    return smartData({
        storedProcName: 'debt.GetPromissoryNoteSchedule',
        params: params
      });
    }
}
