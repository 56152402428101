
import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch, Prop } from 'vue-property-decorator';
import CommentLogList from '@/js/CommentLog/CommentLogList.vue';
import FormattedInput from '@/components/form/FormattedInput.vue';
import * as LiqService from '../../services/DAL/liq_Service';
import * as SystemService from '../../services/DAL/systemService';
import cloneDeep from 'lodash/cloneDeep';
import BankAccountSelector from '@/components/form/BankAccountSelector.vue'
import OwnerSelector from '@/components/form/OwnerSelector.vue';
import ClientSelector from '@/components/form/ClientSelector.vue';
import ElementUI from 'element-ui';
import AttachmentList from '@/js/Attachments/AttachmentList.vue';
import ChangeLogList from '@/components/other/tsChangeLogList.vue';
import { AssetService } from '@/services/DAL/assetService';

declare var SmartObject: any;
declare function tryParseInt(input: any, defaultValue: number): number;
declare function getStoredSecurityLevel(Id: number): number;

@Component({
  components: {
    CommentLogList,
    BankAccountSelector,
    OwnerSelector,
    ClientSelector,
    FormattedInput,
    AttachmentList,
    ChangeLogList
  }
})
export default class CashTransferEdit extends Vue {
  $refs: {
    frmCashTransferEdit: ElementUI.Form
  }
  //#region Data
    private _system_Service: SystemService.SystemService;
    private _liq_service: LiqService.LiqService;

    @Prop() cashTransferId: number;
    @Prop() cashTransfer: LiqService.CashTransfers;
    @Prop() viewType: string;
    @Prop() toBankAccountId: number;
    @Prop() toBankAccountLabel: string;
    @Prop() amount: number;
    @Prop({default: false}) lockToAccount: boolean

    cashTransfer_: LiqService.CashTransfers = new LiqService.CashTransfers();
    notes = null;
    fromBankAccounts = [] as LiqService.TransferSourceBankAccounts[];
    toBankAccounts = [] as LiqService.Accounts[];
    statuses = [] as SystemService.NamedKeys[];
    transferTypes = [] as LiqService.CashTransferTypes[];
    securityLevel = tryParseInt(getStoredSecurityLevel(this.$namedKey.SecurityView.ManageOwners), 0);
    showAttachmentList: boolean = false;
    showChangeLogList: boolean = false;
    securityLevel_: number = null;


  //#endregion Data

  //#region Computed
  get isNew(): boolean {
    return (!(this.cashTransfer_ && this.cashTransfer_.Id)) ;
  }
  get isFormDirty(): boolean {
    return Object.keys((this as any).veeFields).some(
      key => (this as any).veeFields[key].dirty
    );
  }
  get lockToAccount_(): boolean {
    if (this.toBankAccountId) return true;
    if (this.lockToAccount) return true;
    return false;

  }

  //#endregion Computed

  //#region Lifecycle
  async created() {
    this._system_Service = new SystemService.SystemService();
    this._liq_service = new LiqService.LiqService();
    this.securityLevel_ = tryParseInt(
            getStoredSecurityLevel(this.$namedKey.SecurityView.ManageAssets),
            0
        );

    if (this.cashTransfer && Object.keys(this.cashTransfer).length) {
      this.cashTransfer_ = cloneDeep(this.cashTransfer); // make a copy, so changes aren't displayed in list until we emit the updated object
    }
    else if (this.cashTransferId){
      const params = {} as LiqService.GetCashTransfersParameters;
      params.TransferId = this.cashTransferId;
      const transfers = await this._liq_service.GetCashTransfers(params);
      if (transfers && transfers.length == 1)
      this.cashTransfer_ = transfers[0];
    }
    else {
        this.cashTransfer_.ToBankAccountId = this.toBankAccountId;
        this.cashTransfer_.ToBankAccount = this.toBankAccountLabel;
        this.cashTransfer_.Amount = this.amount;
        this.cashTransfer_.Date = this.$moment().format('MM/DD/YYYY');
        this.cashTransfer_.StatusId = 10; // proposed
        this.cashTransfer_.TypeId = 1; //Contribution
        this.cashTransfer_.Editable = true;
    }

    this.fetchStatuses();
    this.fetchTransferTypes();
    this.fetchFromBankAccounts();

  }
  //#endregion Lifecycle
    @Watch("cashTransfer_.ToBankAccountId")
    onChange_cashTransfer_ToBankAccountId(val: number, oldVal: number) {
        this.fetchFromBankAccounts();
    }

    @Watch("cashTransfer_.TypeId")
    onChange_cashTransfer_TypeId(val: number, oldVal: number) {
        this.fetchFromBankAccounts();
    }
    @Watch("cashTransfer_.ToClientId")
    onChange_cashTransfer_ToClientId(val: number, oldVal: number) {
        if (oldVal){
          this.cashTransfer_.ToOwnerId = null;
          this.cashTransfer_.ToBankAccountId = null;
          this.cashTransfer_.ToBankAccount = null;
          this.cashTransfer_.FromBankAccountId = null;
        }
    }
    @Watch("cashTransfer_.ToOwnerId")
    onChange_cashTransfer_ToOwnerId(val: number, oldVal: number) {
        if (oldVal){
          this.cashTransfer_.ToBankAccountId = null;
          this.cashTransfer_.ToBankAccount = null;
          this.cashTransfer_.FromBankAccountId = null;
        }
    }
  //#region Methods
  async fetchStatuses() {
      this.statuses = [];
      const getNamedKeysParameters = {} as SystemService.GetNamedKeysParameters;
      getNamedKeysParameters.KeyType = 'liquidity.TransferStatus';
      this.statuses = await this._system_Service.GetNamedKeys(getNamedKeysParameters);
  }
async fetchTransferTypes() {
      this.transferTypes = [];
      this.transferTypes = await this._liq_service.GetCashTransferTypes();
  }
  async fetchFromBankAccounts() {
    this.fromBankAccounts = [];
    if (!!this.cashTransfer_.ToBankAccountId && !!this.cashTransfer_.TypeId){
        const params = {} as LiqService.GetTransferSourceBankAccountsParameters;
        params.ToBankAccountId = this.cashTransfer_.ToBankAccountId;
        params.CashTransferTypeId = this.cashTransfer_.TypeId;
        this.fromBankAccounts = await this._liq_service.GetTransferSourceBankAccounts(params);
    }
}

  async saveForm() {
    this.$refs.frmCashTransferEdit.validate(async valid => {
      if (valid) {
        let message: string;

        const updateAttrs = cloneDeep(this.cashTransfer_);

        if (this.isNew) {
            try {
                this.cashTransfer_.Id = await new SmartObject('CashTransfers').createObject(updateAttrs);
            }
            catch (err) {
                try {
                    console.log(err);
                    const errJson = JSON.parse(err.responseText);
                    if (errJson.ExceptionMessage.toLowerCase().indexOf('cannot insert duplicate key') > -1) {
                        this.$notify.error('The transfer entered matches a transfer already in the system. Please edit the existing record, or change the details and try again.');
                    }
                    else {
                        this.$notify.error('Something went wrong processing your request, please try again.');
                    }
                }
                catch {
                    this.$notify.error('Something went wrong processing your request, please try again.');
                }
                return;
            }
          message = 'Transfer added.';

          if (this.notes) {
              await new SmartObject('CommentLog').createObject({
                  Comment: this.notes,
                  EntityType: 'CashTransfer',
                  EntityId: this.cashTransfer_.Id
              });
          }
        } else {
          await new SmartObject('CashTransfers', this.cashTransfer_.Id).updateObject(updateAttrs);
          message = 'Changes saved.';
        }

        this.$notify.success(message);

        this.$emit('saved', this.cashTransfer_.Id);
        
        // get the full view of the transfer, and send that back.
        const params = {} as LiqService.GetCashTransfersParameters;
        params.TransferId = this.cashTransfer_.Id
        const transfers = await this._liq_service.GetCashTransfers(params);
        if (transfers.length == 1){
            this.$emit('update:cashTransfer', transfers[0]);
        }
        else {
            this.$emit('update:cashTransfer', this.cashTransfer_);
        }

        if (this.isNew) {
          this.$emit('close');
        }
      }
    });
  }

  async deleteCashTransfer() {
    if (!this.isNew) {
      try {
        await this.$confirm(
          'This will permanently delete this Cash Transfer. Continue?',
          'Warning',
          {
            confirmButtonText: 'OK',
            cancelButtonText: 'Cancel',
            type: 'warning'
          }
        );

        try {
          await new SmartObject('CashTransfers', this.cashTransfer_.Id).deleteObject();

          this.$notify.success('Transfer deleted.');

          this.$emit('deleted', this.cashTransfer_.Id);
          this.$emit('close');
        } catch {
          this.$notify.error('Something went wrong processing your request, please try again.');
        }
      } catch {}
    }
  }
  async onTabClick(tab) {
    switch (tab.label) {
      case 'Documents':
        this.showAttachmentList = false;      
        await this.$nextTick();
        this.showAttachmentList = true;
        break;
      case 'Change Log':
        this.showChangeLogList = false;
        await this.$nextTick();
        this.showChangeLogList = true;
        break;
    }
  }

  //#endregion Methods
}
