<template>
    <!--Note: v-validate is used for Dirty/Clean, but not actual validation. Actual validation is handled by el-form, which doesn't track dirty/clean-->
    <el-form v-loading="loading" :model="editAllocation" :rules="allocationRules" ref="allocationForm" label-width="20%" v-if="!(Object.keys(editAllocation).length==0)">
        <el-form-item label="Account" prop="Name">
            <el-cascader placeholder="(searchable)"
                         :options="chartOfAccountsForCascader"
                         :filter-method="filterMethodCoA"
                         filterable clearable
                         size="small"
                         style="width: 100%; "
                         expand-trigger="hover"
                         name="ChartOfAccounts"
                         :change-on-select="true"
                         v-validate
                         v-model="chartOfAccountsArray">
            </el-cascader>
        </el-form-item>
        <el-form-item label="Department" prop="DepartmentId">
            <el-select filterable clearable size="small" default-first-option
                       placeholder="Optional"
                       name="department" v-validate
                       style="width: 100%; "
                       @change="departmentChange"
                       v-bind:disabled="departments>0"
                       v-model="editAllocation.DepartmentId">
                <el-option v-for="department in departments"
                           :key="department.DepartmentId"
                           :label="department.Name"
                           :value="department.DepartmentId">
                </el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="Amount" prop="Amount" v-if="valueType=='Amount'">
            <formatted-input v-model="editAllocation.Amount" format-type="currency" num-decimals="2" style="width: 100%; max-width:200px;" size="small" name="Amount" v-validate></formatted-input>
        </el-form-item>
        <el-form-item label="Percent" prop.number="Pct" v-if="valueType=='Percent'">
            <el-input v-model="editAllocation.Pct" name="Pct" v-validate size="small" style="width: 100%; max-width:200px;">
                <template slot="append">
                    <span style="font-size:smaller;">decimal value from 0 to 1</span>
                </template>
            </el-input>
        </el-form-item>
        <el-form-item label="Modified" prop="DisplayModified">
            <span>{{editAllocation.DisplayModified}}</span>
        </el-form-item>
        <el-form-item label=" ">
            <el-button type="primary" @click="saveAllocation" :disabled="!(Object.keys(veeFields).some(key => veeFields[key].dirty))" >Save</el-button>
            <el-button type="warning" @click="$emit('cancel');" >Cancel</el-button>
            <el-button type="danger" @click="deleteAllocation">Delete</el-button>
        </el-form-item>
    </el-form>
</template>
<script>
    import Vue from 'vue';
    import mixinUtility from './../VueCommon/Utility.js'
    //import mixinLiquidity from './../VueCommon/liquidity.vue.js'
    import mixinSchema_liq from './../DAL/mixinSchema_liq'
    import mixinSchema_vam from './../DAL/mixinSchema_vam'
    import FormattedInput from '@/components/form/FormattedInput.vue';

    export default Vue.extend({
        name: 'AllocationEdit'
        , components: {
            'formatted-input': FormattedInput
        }
        , mixins: [mixinUtility, mixinSchema_liq, mixinSchema_vam ]
        , props: {
            allocation: {
                type: Object
                , required: true
            }
            , clientId: {
                type: [Number, String]
            }
            , valueType: {
                type: String
                , default: 'Amount'
            }
        }
        ,  model: {
            prop: 'allocation',
            event: 'blur'
        }
        , data() {
            return {
                departments: []
                , chartOfAccounts: []
                , chartOfAccountsForCascader: []
                , allocationRules: {
                    Pct: [
                        { type: 'number', message: 'Percentage must be a decimal between 0 and 1', trigger: 'blur' }
                        , { min: 0, max: 1, message: 'Percentage must be a decimal between 0 and 1', trigger: 'blur' }
                    ],
                    //Date: [
                    //    { type: 'date', required: true, message: 'Please pick a date', trigger: 'change' }
                    //],
                }
                , sourceOnly: false
                , loading: false
                , editAllocation: this.allocation
            }
        }
        , created: async function () {
            await this.setSourceOnly();
            // this.chartOfAccounts = this.vam_GetChartOfAccountsForTree(this.clientId);
            this.loading = true;
            this.chartOfAccounts = await this.vam_GetChartOfAccountsForTree({
					ClientId: this.clientId
					, SourceOnly: this.sourceOnly
					}
					, true // async (optional)
				);
            this.chartOfAccountsForCascader = this.getCascaderHierarchy(this.chartOfAccounts, 'Id', 'Name', 'ParentId');
            this.departments = await this.vam_GetDepartments({
                ClientId: this.clientId
                });
            this.loading = false;
        }
        , computed: {
            showSave: function () {
                return this.veeFields.dirty;
            }
            , chartOfAccountsArray: {
                get: function() {
                    if (this.editAllocation.ChartOfAccountsArray) {
                        return JSON.parse(this.editAllocation.ChartOfAccountsArray);
                    }
                    else
                        return [];
                }
                , set: function (data) {
                    console.log(data); //move logic from handleAccountNodeClick
                    this.editAllocation.AccountId = data[data.length - 1];
                    var account = this.getSelectedArrayItem(this.chartOfAccounts, this.editAllocation.AccountId, 'Id');
                    this.editAllocation.AccountPath = (account) ? account.NamePath : null;
                    this.editAllocation.AccountName = (account) ? account.Name : null;
                }
            }
        }
        , watch: {
        }
        , methods: {
            saveAllocation: async function () {
                this.$refs.allocationForm.validate(async (valid) => {
                    if (valid) {
                        var success = false;
                        if (this.editAllocation.Id == -1 || this.editAllocation.Id == null) {//new
                            const so = new SmartObject('Allocation');
                            success = await so.createObject(this.editAllocation)
                            if (success > 0) {
                                this.editAllocation.Id = success;
                            }
                        }
                        else {
                            const so = new SmartObject('Allocation', this.editAllocation.Id);
                            success = await so.updateObject(this.editAllocation);
                        }
                        if (success) {
                            this.$notify.success('Allocation Updated');
                            //this.$emit('update:editAllocation', this.editAllocation); //goes with .sync
                            this.$emit('blur', this.editAllocation);
                            this.$emit('saved', this.editAllocation);
                        }
                    } else {
                        this.$alert('Not Saved.  See Validation Errors.', 'Error');
                        return false;
                    }
                });
            }
            , deleteAllocation: async function () {
                try {
                    await this.$confirm('Delete Allocation?', 'Delete', {
                        confirmButtonText: 'OK',
                        cancelButtonText: 'Cancel',
                        type: 'danger'
                    });
                    var so = new SmartObject('Allocation', this.editAllocation.Id);
                    await so.deleteObject();
                    this.$notify.success('Allocation Deleted');
                    this.$emit('deleted', this.editAllocation);
                }
                catch (err) {
                    console.log('canceled');
                }
            }
            , departmentChange: function (changedDepartment) {
                var department = this.getSelectedArrayItem(this.departments, this.editAllocation.DepartmentId, 'DepartmentId');
                this.editAllocation.DepartmentName = (department) ? department.Name : null;
            }
            , setSourceOnly: async function(){
                if (this.editAllocation.EntityType == 'Agreement') {
                    var el = this;
                    const agreement = await this.liq_GetAgreements_Object({
                        AgreementId: this.editAllocation.EntityId
                    });
                    const configuration = JSON.parse(agreement.Configuration);
                    if (!!configuration && configuration.AmountFactor == -1){
                        this.sourceOnly = true;
                    }
                    else {
                        this.sourceOnly = false;
                    }
                }
                else {
                    this.sourceOnly = false;
                }
            }
            , filterMethodCoA(item, query) {
                return item.label.toLowerCase().indexOf(query.toLowerCase()) > -1;
            }
        }
    })
</script>
<style>
    .el-form-item__error {
        position: relative;
    }
</style>