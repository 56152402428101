
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import FormattedInput from '@/components/form/FormattedInput.vue';
import * as TransactionsService from '@/services/DAL/transactionsService';
import Common from '@/utils/common';
import BankAccountSelector from '@/components/form/BankAccountSelector.vue'
import BankTransactionSelector from '@/components/form/BankTransactionSelector.vue'
import * as LiqService from "@/services/DAL/liq_Service";
import ElementUI from 'element-ui';
import ReturnIncomeTypeSelector from '@/components/form/ReturnIncomeTypeSelector.vue';

declare function tryParseInt(input: any, defaultValue: number): number;
declare function getStoredSecurityLevel(Id: number): number;
declare var SmartObject: any;
@Component({
    components: {
        FormattedInput,
        BankAccountSelector,
        ReturnIncomeTypeSelector,
        BankTransactionSelector
    }
})
export default class CashEdit extends Vue {
    $refs: {
        frmCashEdit: ElementUI.Form
    }
    //#region Private declarations for Services
    private _transactionsService: TransactionsService.TransactionsService;
    private common: Common;
    
    //#endregion Private declarations for Services

    //#region Props
    @Prop() header: TransactionsService.Header;
    
    @Prop({ required: true, type: String}) readonly direction: string;

    @Prop( {type: Object }) transactionType: TransactionsService.Types;
    @Prop() cash: TransactionsService.Cash;
    @Prop( {type: Boolean, default: false}) readonly hideButtons: boolean;
    @Prop( {type: Boolean, default: false}) readonly hideComment: boolean;
    @Prop( {type: Boolean, default: false}) readonly allowManualValue: boolean;
    
    @Prop({
        type: Boolean,
        default: false
    }) readonly allowSiblingAccountSelection: boolean
    @Prop({
        type: Number
    }) readonly ownerId: number
    //#endregion Props

    //#region Data
    securityLevel_: number = null;
    cash_ = {} as TransactionsService.Cash;
    loading = false;
    isNew = false;
    bankAccounts = [] as LiqService.BankAccountList[];
    transactionType_ = {} as TransactionsService.Types;
    isHistorical = false;
    showBankTransactionSelector = true;
    allowManualValue_ = false;
    selectedTransactionAmount: number = null;
    overrideSignRequirement = false;
   
    //#endregion Data

    //#region Lifecycle
    created() {
        this._transactionsService = new TransactionsService.TransactionsService();
        this.common = new Common();
        this.securityLevel_ = tryParseInt(
        getStoredSecurityLevel(this.$namedKey.SecurityView.ManageAssets),
            0
        );
        if (this.cash && Object.keys(this.cash).length){
            this.cash_ = this.cash;
            this.determineOverrideSignRequirement();
        }
        else {
            this.cash_ = new TransactionsService.Cash();
            this.$nullifyObjectProps(this.cash_);

            if (this.header && Object.keys(this.header).length){
                this.cash_.EntityType = 'InvestmentTransactionHeader';
                this.cash_.EntityId = this.header.Id;
            }
            this.cash_.ConsiderationDirection = this.direction;
            this.cash_.Direction = this.direction;
            this.isNew = true;
        }
        this.handleTransactionType();
    }
    async mounted() {
    }
    //#endregion Lifecycle
    
    //#region Watches
    @Watch('cash_.BankAccountId')
    async bankAccountIdChanged(val: number, oldVal: number) {
        if (oldVal !== undefined){
            this.cash_.BankTransactionId = null;
            if (this.cash_.MatchSource == 'Posted'){
                this.cash_.Amount = null;
            }
        }
    }

    //#endregion Watches

    get disableManualValue(){
        return (this.cash_.MatchedTransactionId || (this.cash_.MatchSource == 'Posted' && !this.allowManualValue_))
    }

    get selectedBankAccount(): LiqService.BankAccountList {
        if (this.bankAccounts && this.bankAccounts.length && this.cash_ && this.cash_.BankAccountId) {
            return this.common.getSelectedArrayItem(
                this.bankAccounts,
                this.cash_.BankAccountId.toString(),
                'BankAccountId'
                );
        }
    }
    get isTransactionDateInBankTransactionDateRange(): boolean {
        if (this.selectedBankAccount && this.header){
            return this.$moment(this.header.Date).isBetween(this.selectedBankAccount.Earliest, this.selectedBankAccount.Latest, undefined, '[]'); // '['= inclusive of the range
        }
        else return false;
    }
    get isFormDirty(): boolean {
        return Object.keys((this as any).veeFields).some(
        key => (this as any).veeFields[key].dirty
        );
    }
    get isFormValid(): boolean{
        return !(Object.keys((this as any).veeFields).some(
            key => (this as any).veeFields[key].invalid
        ));
    }
    get cashAmountValidateRule(): string {
        if (!this.overrideSignRequirement){
            return 'min_value: 0';
        }
        else if (this.overrideSignRequirement){
            return 'max_value: 0';
        }
        
    }
    get cashAmountDisplay(): number {
        if (this.direction == 'Source'){
            return 0 - this.cash_.Amount;
        }
        else {
            return this.cash_.Amount;
        }
    }
    set cashAmountDisplay(value: number){
        if (this.direction == 'Source'){
            this.cash_.Amount = 0 - value;
        }
        else {
            this.cash_.Amount = value;
        }
    }
    //#region Methods

    public async saveItem(): Promise<TransactionsService.Cash> {
        if (this.isFormValid) {
            this.cash_.ConsiderationDescription = this.selectedBankAccount.NameNumberMasked;
            if (this.isNew){
                const id = await new SmartObject('InvestmentTransactionCash').createObject(
                    this.cash_
                );
                this.cash_.CashId = id;
                this.cash_.Id = id;
                this.$notify.success('New Cash Item Added');
            }
            else{
                await new SmartObject('InvestmentTransactionCash', this.cash_.CashId).updateObject(
                    this.cash_
                );
                this.$notify.success('Cash Item Updated');
            }
            await this.refreshCash(); // get the item fresh from the DB so it has all the transformations (sign)
            this.$emit('saved', this.cash_);
            return this.cash_;
        }
    }
    async refreshCash(){
        const params = {} as TransactionsService.GetCashParameters;
        params.CashId = this.cash_.CashId;
        const cashArray = await this._transactionsService.GetCash(params);
        if (cashArray && cashArray.length ==1){
            this.cash_ = cashArray[0];
        }
    }
    clearItem(){
        this.cash_.Amount = null;
        this.cash_.BankAccountId = null;
        this.cash_.ConsiderationDescription = null;
        this.cash_.Comment = null;
        this.selectedTransactionAmount = null;
        this.$emit('clear');
    }

    async deleteItem() {
        if (!this.isNew) {
            try {
                await this.$confirm(
                    'This will permanently delete this Cash item. Continue?',
                    'Warning',
                    {
                        confirmButtonText: 'OK',
                        cancelButtonText: 'Cancel',
                        type: 'warning'
                    }
                );

                await this.doDeleteTransactionCash();
            } catch {}
        }
    }

    async doDeleteTransactionCash() {
        try {
            await new SmartObject(
                'InvestmentTransactionCash',
                this.cash_.CashId
            ).deleteObject();

            this.$notify.success('Cash item deleted.');

            await new SmartObject('CommentLog').createObject({
                EntityType: this.cash_.EntityType,
                EntityId: this.cash_.EntityId,
                Comment: `Cash ${this.cash_.CashId} Deleted.  (${this.direction} ${this.cash_.ConsiderationDescription} ${this.$accounting.formatMoney(this.cash_.Amount)})`,
                SystemGenerated: true
            });

            this.$notify.success('Investment Header Comment added.');

            this.$emit('deleted', this.cash_.CashId);
            this.cash_ = {} as TransactionsService.Cash;
        } catch {
            this.$notify.error('Something went wrong processing your request, please try again.');
        }
    }
    fetchedBankAccounts(accounts: LiqService.BankAccountList[]){
        this.bankAccounts = accounts;
        this.$validator.validate('BankAccount'); // for un-understood reasons, validation is not firing automatically after selecting the bank account (it's not due to having this event).  But this forces it.
    }
    async handleTransactionType(){
            this.transactionType_ = {} as TransactionsService.Types;
        if (this.transactionType && Object.keys(this.transactionType).length && this.transactionType.Id > 0){
            this.transactionType_ = this.transactionType;
        }
        else if (this.header && Object.keys(this.header).length && this.header.TypeId > 0){ 
            const params = {} as TransactionsService.GetTypesParameters;
            const types = await this._transactionsService.GetTypes(params);
            if (types && types.length){
                this.transactionType_ = this.common.getSelectedArrayItem(
                    types,
                    this.header.TypeId.toString(),
                    'Id'
                    );
            }
        }
    }
    async bankAccountSelected(){
        this.isHistorical = !this.isTransactionDateInBankTransactionDateRange;
        if (this.selectedBankAccount && this.header){
            if (this.isTransactionDateInBankTransactionDateRange) {
                //this.$moment(this.header.Date).isBetween(this.selectedBankAccount.Earliest, this.selectedBankAccount.Latest, undefined, '[)')) { // '['= inclusive of the range
                this.cash_.MatchSource = 'Posted';
            }        
            else if (this.$moment(this.header.Date).isSameOrBefore(this.selectedBankAccount.Earliest)) { 
                this.cash_.MatchSource = 'Historical';
            }
            else if (this.$moment(this.header.Date).isAfter(this.selectedBankAccount.Latest)) { // "latest" should be today. If the Transaction date is today, then it should be in the Range (Posted); after today, Expected.
                this.cash_.MatchSource = 'Expected';
            }
        
        // reset transactions:
        this.showBankTransactionSelector = false;
        await this.$nextTick();
        this.showBankTransactionSelector = true;
        }
    }
    bankTransactionSelected(bankTransaction: LiqService.TransactionsV2){
        this.selectedTransactionAmount = bankTransaction.Amount;
        this.cash_.Amount = this.selectedTransactionAmount;
    }
    bankTransactionInitial(bankTransaction: LiqService.TransactionsV2){
        this.selectedTransactionAmount = bankTransaction.Amount;
        if (this.selectedTransactionAmount != this.cash_.Amount) this.allowManualValue_ = true;
    }
    determineOverrideSignRequirement(){
        if (this.direction == 'Source' && this.cash_.Amount > 0){
            this.overrideSignRequirement = true;
        }
        else if (this.direction == 'Result' && this.cash_.Amount < 0){
            this.overrideSignRequirement = true;
        }
        else {
            this.overrideSignRequirement = false;
        }
    }
    //#endregion Methods
}
