
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import * as TransactionsService from '@/services/DAL/transactionsService';
import * as DebtService from '@/services/DAL/debtService';
import groupArray from 'group-array';
import Common from '@/utils/common';
import ConsiderationEdit from '@/views/InvestmentTransaction/Consideration/ConsiderationEdit.vue';
import ElementUI from 'element-ui';
declare var SmartObject: any;

type ConsiderationsType = DebtService.InstrumentTransactions | TransactionsService.Equity | TransactionsService.Cash | TransactionsService.Capital | TransactionsService.CreditNote | TransactionsService.Expense;

@Component({
    components: {
        ConsiderationEdit,
    }
})
export default class NewConsideration extends Vue {
    $refs: {
        frmConsiderationEdit: ElementUI.Form
    }
    //#region Private declarations for Services
    private _transactionsService: TransactionsService.TransactionsService;
    private common: Common;
    
    //#endregion Private declarations for Services

    //#region Props
    @Prop( {required: true, type: Object }) header: TransactionsService.Header;
    @Prop( {required: true, type: String }) direction: string;
    @Prop( {type: Object }) transactionType: TransactionsService.Types;
    @Prop( {type: Number }) readonly newTransactionInvestmentId: number;
    @Prop( {type: Boolean, default: false }) readonly autoSelectWhenSingleOption: boolean;
    @Prop( {required: false, type: String }) readonly singleConsiderationType: string; //used to force the consideration type to be a single type, such as 'Cash' or 'Capital'
    //#endregion Props

    //#region Data
    considerationTypes = [] as TransactionsService.ConsiderationTypes[];
    considerationTypeId: number = null
    considerationTypesGrouped = [] as any;
    loadingConsiderationTypes = false;
    showConsiderationEdit = false;
   
    //#endregion Data

    //#region Lifecycle
    async created() {
        this._transactionsService = new TransactionsService.TransactionsService();
        this.common = new Common();
        
    }
    async mounted() {
        await this.fetchConsiderationTypes();
    }
    //#endregion Lifecycle
    
    //#region Watches
    @Watch('considerationTypeId')
    async onChange_considerationTypeId(val: number, oldVal: number) {
        this.showConsiderationEdit = false;
        await this.$nextTick();
        if  (val) {
            this.showConsiderationEdit = true;
        }
    }
    @Watch('header.TypeId')
    async onChange_transactionTypeId(val: number, oldVal: number) {
        this.fetchConsiderationTypes();
    }

    //#endregion Watches

    get selectedConsiderationType(): TransactionsService.ConsiderationTypes {
        if (this.considerationTypeId){
            return this.common.getSelectedArrayItem(
                this.considerationTypes,
                this.considerationTypeId.toString(),
                'Id'
            );
        }
    }
    //#region Methods
    async fetchConsiderationTypes(){
        if (this.header.TypeId){
            this.loadingConsiderationTypes = true;
            const params = {} as TransactionsService.GetConsiderationTypesParameters;
            params.TransactionTypeId = this.header.TypeId;
            params.Direction = this.direction; //if not supplied, will be undefined and ignored
            this.considerationTypes = await this._transactionsService.GetConsiderationTypes(params);
            if (this.singleConsiderationType){
                this.considerationTypes = this.considerationTypes.filter(x => x.Name == this.singleConsiderationType);
            }
            const considerationTypesGroups = groupArray(this.considerationTypes, 'Direction');
            this.considerationTypesGrouped = Object.keys(considerationTypesGroups).map(key => {
                if (considerationTypesGroups.hasOwnProperty(key)) {
                    return {
                        label: key,
                        options: considerationTypesGroups[key]
                    };
                }
            });
            if (this.considerationTypes.length == 1 && this.autoSelectWhenSingleOption){
                this.considerationTypeId = this.considerationTypes[0].Id;
            }
            this.loadingConsiderationTypes = false;
        }
        else {
            console.log('NewConsideration: did not refresh ConsiderationTypes because TransactionTypeId is not set.')
        }
    }

    itemClose(){
        this.considerationTypeId = null;
    }

    itemSavedOnly(consideration: ConsiderationsType){
        this.$emit('saved', consideration);
    }
    itemSaved(consideration: ConsiderationsType){
        // deprecated.  Use itemSavedOnly + itemClose, like Equity does.
        this.$emit('saved', consideration);
        this.considerationTypeId = null;
    }
    itemCleared(){
        this.considerationTypeId = null;
    }
}
