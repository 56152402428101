
import { Component, Vue, Prop, Watch, Ref } from "vue-property-decorator";
import * as TransactionsService from '@/services/DAL/transactionsService';
import Common from '@/utils/common';
import NoteSelector from '@/components/form/NoteSelector.vue'
import NoteTransactionSelector from '@/components/form/NoteTransactionSelector.vue'
import * as LiabService from "@/services/DAL/liabService";
import ElementUI from 'element-ui';

declare var SmartObject: any;

@Component({
    components: {
        NoteSelector,
        NoteTransactionSelector
    }
})
export default class CreditNoteEdit extends Vue {
    @Ref() readonly frmCreditNoteEdit!: ElementUI.Form;
    //#region Private declarations for Services
    private _transactionsService: TransactionsService.TransactionsService;
    private common: Common;
    
    //#endregion Private declarations for Services

    //#region Props
    @Prop({required: true }) readonly header: TransactionsService.Header;
    @Prop({required: true }) readonly noteTypeId: number | Array<number>;
    @Prop({type: Object}) readonly considerationType: TransactionsService.ConsiderationTypes;
    @Prop( {type: Object }) readonly transactionType: TransactionsService.Types;
    @Prop() readonly creditNote: TransactionsService.CreditNote;
    @Prop( {type: Boolean, default: false}) readonly hideButtons: boolean;
    @Prop({type: Number}) readonly ownerId: number
    @Prop( {type: Array }) readonly ownerIdList: Array<number>;

    //#endregion Props

    //#region Data
    creditNote_ = {} as TransactionsService.CreditNote;
    loading = false;
    isNew = false;
    transactionType_ = {} as TransactionsService.Types;
    showNoteTransactionSelector = true;
    securityLevel_: number = null;

   
    //#endregion Data

    //#region Lifecycle
    created() {
        this._transactionsService = new TransactionsService.TransactionsService();
        this.securityLevel_ = tryParseInt(
            getStoredSecurityLevel(this.$namedKey.SecurityView.ManageAssets),
            0
        );
        this.common = new Common();
        if (this.creditNote && Object.keys(this.creditNote).length){
            this.creditNote_ = this.creditNote;
        }
        else {
            this.creditNote_ = new TransactionsService.CreditNote();
            this.creditNote_.TransactionHeaderId = this.header.Id;
            this.creditNote_.ConsiderationTypeId = this.considerationType.Id;
            this.creditNote_.ConsiderationType = this.considerationType.Name;
            this.creditNote_.ConsiderationDirection = this.considerationType.Direction;
            this.isNew = true;
        }
        this.handleTransactionType();
    }
    async mounted() {
    }
    //#endregion Lifecycle
    
    //#region Watches
    @Watch('creditNote_.NoteId')
    async bankAccountIdChanged(val: number, oldVal: number) {
        if (oldVal !== undefined){
            this.creditNote_.NoteTransactionId = null;
            this.creditNote_.Amount = null;
        }
    }

    //#endregion Watches

    get isFormDirty(): boolean {
        return Object.keys((this as any).veeFields).some(
        key => (this as any).veeFields[key].dirty
        );
    }
    get isFormValid(): boolean{
        return !(Object.keys((this as any).veeFields).some(
            key => (this as any).veeFields[key].invalid
        ));
    }

    //#region Methods

    async saveItem(){
        if (this.isFormValid) {
            // this.creditNote_.ConsiderationDescription = 
            if (this.isNew){
                const id = await new SmartObject('InvestmentTransactionCreditNote').createObject(
                    this.creditNote_
                );
                this.creditNote_.CreditNoteId = id;
                this.creditNote_.Id = id;
                this.$notify.success('New Credit Note Item Added');
            }
            else{
                await new SmartObject('InvestmentTransactionCreditNote', this.creditNote_.CreditNoteId).updateObject(
                    this.creditNote_
                );
                this.$notify.success('Credit Note Item Updated');
            }
            this.creditNote_.ConsiderationValue = (this.considerationType.Direction == 'Source') ? 0-this.creditNote_.Amount : this.creditNote_.Amount;
            this.$emit('saved', this.creditNote_);
        }
    }
        
    clearItem(){
        this.creditNote_.Amount = null;
        this.creditNote_.ConsiderationValue = null;
        this.creditNote_.NoteId = null;
        this.creditNote_.NoteTransactionId = null;
        this.creditNote_.ConsiderationDescription = null;
        this.$emit('clear');
    }

    async deleteItem() {
        if (!this.isNew) {
            try {
                await this.$confirm(
                    'This will permanently delete this Credit Note item. Continue?',
                    'Warning',
                    {
                        confirmButtonText: 'OK',
                        cancelButtonText: 'Cancel',
                        type: 'warning'
                    }
                );

                await this.doDeleteTransactionCreditNote();
            } catch {}
        }
    }

    async doDeleteTransactionCreditNote() {
        try {
            await new SmartObject(
                'InvestmentTransactionCreditNote',
                this.creditNote_.CreditNoteId
            ).deleteObject();

            this.$notify.success('Credit Note item deleted.');

            await new SmartObject('CommentLog').createObject({
                EntityType: 'InvestmentTransactionHeader',
                EntityId: this.creditNote_.TransactionHeaderId,
                Comment: `Credit Note ${this.creditNote_.CreditNoteId} Deleted.  (${this.considerationType.Direction} ${this.creditNote_.ConsiderationDescription} ${this.$accounting.formatMoney(this.creditNote_.Amount)})`,
                SystemGenerated: true
            });

            this.$notify.success('Investment Header Comment added.');

            this.$emit('deleted', this.creditNote_.CreditNoteId);
            this.creditNote_ = {} as TransactionsService.CreditNote;
        } catch {
            this.$notify.error('Something went wrong processing your request, please try again.');
        }
    }
    async handleTransactionType(){
            this.transactionType_ = {} as TransactionsService.Types;
        if (this.transactionType && Object.keys(this.transactionType).length && this.transactionType.Id > 0){
            this.transactionType_ = this.transactionType;
        }
        else if (this.header && Object.keys(this.header).length && this.header.TypeId > 0){ 
            const params = {} as TransactionsService.GetTypesParameters;
            const types = await this._transactionsService.GetTypes(params);
            if (types && types.length){
                this.transactionType_ = this.common.getSelectedArrayItem(
                    types,
                    this.header.TypeId.toString(),
                    'Id'
                    );
            }
        }
    }
    async noteSelected(){
        this.showNoteTransactionSelector = false;
        await this.$nextTick();
        this.showNoteTransactionSelector = true;
    }
    noteTransactionSelected(noteTransaction: LiabService.NoteTransactions){
        this.creditNote_.Amount = Math.abs(noteTransaction.Amount); //payments (negatives) are a source (positive). See 14.5% slice of PIP 10.  Augment this logic if other scenarios arise.
        this.creditNote_.ConsiderationDescription = noteTransaction.TransactionTypeLabel + ' ' + ((noteTransaction.Comment) ? noteTransaction.Comment : `Note Id ${noteTransaction.NoteId}`);
    }
    //#endregion Methods
}
