
import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch, Prop, Model } from 'vue-property-decorator';
import * as TransactionsService from '../../services/DAL/transactionsService';
import Common from '../../utils/common';

@Component({
    inheritAttrs: false
})
export default class TransactionSubTypeSelector extends Vue {
//#region Private declarations for Services
    private _transactions_Service: TransactionsService.TransactionsService;
    private _common: Common;

//#endregion Private declarations for Services

    //#region Props
    @Prop()
    value: number;
    @Prop()
    transactionTypeId: number;
    //#endregion Props

    //#region Data
    items = [] as TransactionsService.SubType[];
    selectedValue: number = null;
    selectedItem: TransactionsService.SubType = new TransactionsService.SubType();
    //#endregion Data

   //#region Lifecycle
    async created() {
        this._transactions_Service = new TransactionsService.TransactionsService();
        this._common = new Common();
    }
    async mounted(){
        this.selectedValue = this.value;
        this.fetchItems();
    }
    //#endregion Lifecycle

    //#region Watches
    @Watch('value')
    onChange_value(val: number, oldVal: number) {
        this.selectedValue = val;
        this.getSelectedItem();
    }
    
    @Watch('selectedTransactionType')
    onChange_selectedItem(val: number, oldVal: number) {
        this.$emit('value-set', this.selectedItem);
    }

    @Watch('transactionTypeId')
    onChange_transactionTypeId(val: number, oldVal: number) {
        this.fetchItems();
    }

    //#endregion Watches

    //#region Methods
    async fetchItems() {
        this.items = [];
        const params = {} as TransactionsService.GetSubTypeParameters;
        params.TransactionTypeId = this.transactionTypeId;
        this.items = await this._transactions_Service.GetSubType(params);
        this.$emit('options-fetched', this.items);
        this.getSelectedItem();
    }

    getSelectedItem() {
        if (this.items.length && this.selectedValue > 0) {
            this.selectedItem = this._common.getSelectedArrayItem(this.items, this.selectedValue.toString(), 'Id');
        }
    }
    //#endregion Methods
}
